import './style.css';
import './../style.css';
import './../sadmin/style.css';
import '../../../css/widgsystem.css';
//import './style.css';

import React from "react";
//import {UIlengID} from '../../../App'; // Потрібно файл де буде завантажуватися всі переміні
import {  LoadAvatarHis, AppCaption ,SetHistory} from "../../../redux/state";
import MdAbout from "./about";

// utilites
import Mdtrabajadores from "./trabajadores";
import Mdjornada from "./jornada";
import Mddocuments from "./documents";
import Mdmesenger from "./mesenger";

import Mdclientes from "./clientes";
import MdEqipos from "./eqipo";
import MdLugares from "./lugares";
import MdSubContract from "./subcontrato";


//import GostAva from '../../../img/guest_nano.png';  //sadmin.png

export default class IndexUserHome extends React.Component{

    constructor() {
      super()
      this.state = {
          work : [],
          openframe: '' // sel user id  7
        };
        this.updateIds = this.updateIds.bind(this);
      }
      
    updateIds = (ids) => {
      //MW1info(ids); // show modal
    }
  
    LoadCompanyInfo() {
      var data = { 
        "cmd": 'LoadCompanyInfo',
        "tbl": 'db_company',
        "id_work": this.props.miUser.work
      };
    
      this.GETJSON(data,"work")

      console.log('work:', this.state.work);
  }

  GETJSON (data,stname){
    fetch(
      'https://inupline.com/post.php',
      {
        method: 'POST',
        header : {
          Accept: 'application/json', 'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      }
    )
      .then (response => response.json()) 
      .then (response => {
          //console.log(response);
          this.setState({
            [stname]: response,
          }, () =>  { this.opage('com0')});
          
          return response;
      })
    
      .then((result) => {
          console.log('Success:', result);
      })
      .catch((error) => {
          console.error('Error:', error);
      });
  }
  
  componentDidMount() {  
    AppCaption('','MI EMPRESA','micompany', '');
    this.LoadCompanyInfo();
  }

  opage(val){
    this.OpenGrameURL (val, 'com0', <MdAbout work = {this.props.miCorp /*this.state.work*/} opage={this.opage.bind(this)}/>)
    this.OpenGrameURL (val, 'com1', <Mdtrabajadores opage={this.opage.bind(this)}/>) // Empleados
    this.OpenGrameURL (val, 'com2', <Mdjornada opage={this.opage.bind(this)}/>) // Jornada
    this.OpenGrameURL (val, 'com3', <Mdclientes opage={this.opage.bind(this)}/>) // Clientes
    this.OpenGrameURL (val, 'com4', <Mddocuments opage={this.opage.bind(this)}/>) // Documentos
    this.OpenGrameURL (val, 'com5', <Mdmesenger opage={this.opage.bind(this)}/>) //Mensajes
    this.OpenGrameURL (val, 'com6', <MdEqipos opage={this.opage.bind(this)}/>) // Eqipos
    this.OpenGrameURL (val, 'com7', <MdLugares opage={this.opage.bind(this)}/>) // Lugares
    this.OpenGrameURL (val, 'com8', <MdSubContract opage={this.opage.bind(this)}/>) //Subcontractos

    //AppCaption('Mi Empresa');
  }

  OpenGrameURL(val, index, jsx_page){
    if (val === index){
      this.setState({
        openframe: jsx_page //<MdAbout work = {this.state.work} opage={this.opage.bind(this)}/>
      });
      //AppCaption('Mi Empresa | ' + index);
      console.log('open url', val, index)
    } 
    
  }
  
    render() {
      return (
        <div className = "saback-box">
          {this.state.openframe}
        </div>
      );
    }
  }    
