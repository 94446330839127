import '../style.css';
import Cookies from 'universal-cookie';
import React , { useState,useEffect } from 'react';
import LoginKey from '../LoginKey';
import { Link, useNavigate } from "react-router-dom";
//import { incrementUser } from './../../../redux/action'
//import { useDispatch } from 'react-redux'


function LoginPg(props) {
    //LOGIN PAGE
    const navigate = useNavigate();
    //const dispatch = useDispatch();
    //const cookies = new Cookies(); 

    function redraw (val){
        if (val == 1 ) {
            navigate("/");
            //dispatch(incrementUser(cookies.get('token')));
            //props.usrout('1');
        }
    }

    return (
        <div>
            <div className="polygonlogo"></div>
            
            <div className="glform">
                <p className="modalcap"><i className="fa fa-user-o" aria-hidden="true"></i> {props.pal.id6}</p> 
                <LoginKey inuser = {redraw} red/>
            </div>
        </div>
    );
  }


export default LoginPg;