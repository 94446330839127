import './../style.css';
import '../../../css/widgsystem.css';

import React from "react";

export default function WdgBox(props) {
    const { title = 'title', infolabel = props.clabel, psh = 's1x' , psw = 'h1x' } = props;
    
    const keyview = (e) => {
      e.preventDefault();
      //console.log("title")
    }

    return (
    <div className= {'back-box ' + psh + ' ' + psw + ' thema'}>

    <div className="title_box"><div className="title">{title}</div></div>
          
    <div id="usercv">
          {props.children}

          <InfoLabel clabel = {infolabel}/>

      {/* 
        <div className="set_link">
          <button className="set_key" onClick={keyview}><i className="fa fa-eye" aria-hidden="true"></i></button>
          <button className="set_key" onClick={keyview}><i className="fa fa-pencil-square-o" aria-hidden="true"></i></button>
          <button className="set_key" onClick={keyview}><i className="fa fa-floppy-o" aria-hidden="true"></i></button>
        </div> 

          <InfoLabel clabel = {infolabel}/>
          */}
    </div>
    </div>
   )
  }

  function InfoLabel(props) {
    return (
      <div className="info">
        {!props.clabel
        ?<h4></h4>
        :<h4 className="infotext"><i className="fa fa-calendar" aria-hidden="true"></i>&nbsp; {props.clabel}</h4>
        }
      </div>
    )
  }