import './../style.css';
import '../../../css/widgsystem.css';

import React from "react";
//import {UIlengID} from '../../../App'; // Потрібно файл де буде завантажуватися всі переміні
import { myUsers, AppCaption} from "../../../redux/state";
//import GostAva from '../../../img/guest_nano.png';  //sadmin.png

import $ from 'jquery';
import 'round-slider/src/roundslider.js';
import 'round-slider/src/roundslider.css';


export default class IndexUserHome extends React.Component{

    constructor() {
      super()
      this.state = {
          userids : [] // sel user id  
        };
        this.updateIds = this.updateIds.bind(this)
      }
      
    updatecaption(){
      //tcapt ('USER EDITOR');v
      //AppCaption('WEB EDITOR');
      //myUsers.callUser();
    }
  
    useropenid(){
      //console.log('work');
    }
  
    updateIds = (ids) => {
      //MW1info(ids); // show modal
    }
  
    componentDidMount() {
      // https://roundsliderui.com/demos.html
      //window.$ = window.jQuery = require("round-slider/src/roundslider.js")
/*
      $("#slider").roundSlider({
        sliderType: "min-range",
        handleShape: "round",
        width: 22,
        radius: 100,
        value: 60,
        change: function(event) {
            var value = event.value;
            $('#slider2').roundSlider('setValue', value + 20);
        }
      });*/
      $(function() {
        var taeb = $(".taeb-switch");
        taeb.find(".taeb").on("click", function() {
          var $this = $(this);
      
          if ($this.hasClass("active")) return;
      
          var direction = $this.attr("taeb-direction");
      
          taeb.removeClass("left center right").addClass(direction);
          taeb.find(".taeb.active").removeClass("active");
          $this.addClass("active");
          console.log('SEL ' + direction);
        });
      });

      $("#slider3").roundSlider({
        radius: 120,
        handleSize: 45,
        sliderType: "range",
        handleShape: "dot",
        width: 30,
        value: "8,18",
        startAngle: -90,
        endAngle: "+325",
        min: 5,
        max: 23
      });
    }

    download(){
      $('#slider3').roundSlider('setValue', "7,17");
      var Val = $('#slider3').roundSlider('getValue');
      console.log(Val);
    }

    switchsatatus(){
      $('#slider3').roundSlider('setValue', "7,17");
      var Val = $('#slider3').roundSlider('getValue');
      console.log(Val);
    }
  
    render() {
      return (
        <div>

        <h2>roundSlider</h2> 

        <div id="slider3"></div>

        <button className="taeb" onClick={this.download}>X</button>

          <div className="wrapper">
            <div className="taeb-switch left text-center">
              <div className="taeb active" taeb-direction="left">Falta</div>
              <div className="taeb" taeb-direction="center">Todas</div>
              <div className="taeb" taeb-direction="right">Firmados</div>
            </div>
          </div>

      </div>
      );
    }
  }    
