import '../style.css';
//import Cookies from 'universal-cookie';
import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'
import FFinish from './finish'
import FRun from './run'
//import Fwelcom from './welcom'
import { myUsers, AppCaption} from "../../../redux/state";

function Firstrun({tcapt}) {
    // FIRST RUN
    //const cookies = new Cookies();
    const [frmCaption,setCaption] = useState('');

    const [routeResult,openUrl] = useState(<FRun tcapt = {meCaption} finish = {meFin}/>);

    //const [lngindex,setlngindex] = useState(cookies.get('lng_ui'));

    //////////////////////////////

    function meCaption(id){
        setCaption(id);
      }

      function meFin(id){
        if (id = "finish") {openUrl(< FFinish tcapt = {meCaption}/>);}
      }

    function updatecaption(){
      //tcapt("Bienvenido");
      //console.log(frmCaption)
      //AppCaption('Bienvenido');
      AppCaption('','Bienvenido','firstrun','');

    }

    useEffect(() => {
        updatecaption();
        // eslint-disable-next-line
     }, []); // AUTOLOAD - завантажує користувача із кешу

    return (
        <div>
            {routeResult} 
        </div>
        
    );
  }
  
  Firstrun.prototype = {
    tcapt: PropTypes.func.isRequired,
  }

export default Firstrun;