import '../style.css';

function AboutPg(props) {
    

    return (
        <div>
            <div className="polygonlogo"></div>
            <div className="glform80 fadeIn">
                <h2>{props.pal.id7}</h2>
                <ul className="paragraf00">
                    <li className="paragraf00li">{props.pal.id8}</li>
                    <li className="paragraf00li">{props.pal.id9}</li>
                    <li className="paragraf00li">{props.pal.id10}</li>
                    <li className="paragraf00li">{props.pal.id11}</li>
                    <li className="paragraf00li">{props.pal.id12}</li>
                    <li className="paragraf00li">{props.pal.id13}</li>
                    <li className="paragraf00li">{props.pal.id14}</li>
                    <li className="paragraf00li">{props.pal.id15}</li>
                    <li className="paragraf00li">{props.pal.id16}</li>
                    <li className="paragraf00li">{props.pal.id17}</li>
                    <li className="paragraf00li">{props.pal.id18}</li>
                    <li className="paragraf00li">{props.pal.id19}</li>
                    <li className="paragraf00li">{props.pal.id20}</li>
                </ul> 
            </div>
        </div>
    );
  }
  
export default AboutPg;