import './../style.css';
import '../../../css/widgsystem.css';

import React from "react";
import { myUsers, miUser, MW1close, LoadAvatarHis, LoadAvatar ,SetHistory, SndMSG} from "../../../redux/state";

// WIDGET SYSTEMS
import WdgCalendar from '../widgets/calendar';
import WdgSemana from '../widgets/semana';
import WdgBox from '../widgets/wgbox';
import CntrSelect from '../widgets/select';
import CntrTextbox, {CntrDataBox} from '../widgets/textbox';

export default class IndexUserHome extends React.Component{
    constructor() {
      super()
      this.state = {
          user : [],
          st_eqipo: [],
          st_lugar: [],
          st_client: [],
          st_eqipo_id: 0,
          st_lugar_id: 0,
          st_client_id: 0,
          st_contrato_id: 0,
          st_puesto_id: 0,
          st_periodo_id: 0,
          st_salario_id: 0,
          st_extra_id: 0,
          st_extraf_id: 0,
          st_alta_in: new Date(),
          st_usersel: [],
          st_loading: false
        };
      }
      

    loaduser (){
      if (this.state.st_usersel[0]){
        this.setState({ st_eqipo_id: this.state.st_usersel[0].id_eqipo })
        this.setState({ st_lugar_id: this.state.st_usersel[0].id_lugar })
        this.setState({ st_lugar_id: this.state.st_usersel[0].id_cliente })
        this.setState({ st_contrato_id: this.state.st_usersel[0].contrato })
        this.setState({ st_puesto_id: this.state.st_usersel[0].tpuesto })
        this.setState({ st_periodo_id: this.state.st_usersel[0].prueba })
        this.setState({ st_salario_id: this.state.st_usersel[0].salario })
        this.setState({ st_extra_id: this.state.st_usersel[0].extra })
        this.setState({ st_extraf_id: this.state.st_usersel[0].ExtraFest })
        //console.log('Оновлення даних емплео', this.state.st_usersel[0].user_id);

        this.setState({ st_loading: false })
      }
      //console.log('loaduser');

    }
    componentDidMount() {
        //this.verifitycard()
        this.LoadDataClients()
        console.log('componentDidMount');
    }

    verifitycard() {
        const { guser } = this.props;
        var i = 1;
        if (guser) {
            this.setState({user: guser })
        }
    }

    GETJSON (data,stname){
      fetch(
        'https://inupline.com/post.php',
        {
          method: 'POST',
          header : {
            Accept: 'application/json', 'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        }
      )
        .then (response => response.json())
        .then (response => {
            //console.log(response);
            /*this.setState({
              [stname]: response
            });*/
            this.setState({ [stname]: response });
            ;
            //return response;
            console.log('GETJSON:', response);

        })
      
        .then((result) => {
            //console.log('Success:', result);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    LoadDataClients() {
      //const { st_usersel } = this.state;

      var data = { 
        "cmd": 'FindText',
        "tbl": 'db_trabajador',
        'colum': 'id', 
        'find': this.props.newold
      };

      this.GETJSON(data,"st_usersel")

      var data = { 
        "cmd": 'FindText',
        "tbl": 'db_eqipo',
        'colum': 'corp_id', 
        'find': miUser.work
      };
      this.GETJSON(data,"st_eqipo")

      var data = { 
        "cmd": 'FindText',
        "tbl": 'db_lugar',
        'colum': 'corp_id', 
        'find': miUser.work
      };
      this.GETJSON(data,"st_lugar")

      var data = { 
        "cmd": 'FindText',
        "tbl": 'db_client',
        'colum': 'id_comp', 
        'find': miUser.work
      };
      this.GETJSON(data,"st_client")


      //this.loaduser()
      console.log('LoadDataClients');
    }


    PinSave(ide) {

      //console.log('PinSave CONFIRMAR:', ide.id);

      //e.preventDefault();
      var contrato = document.getElementById('stcontrato').value;
      var tpuesto = document.getElementById('stpuesto').value;
      var salario = document.getElementById('insalario').value;
      var extra = document.getElementById('inextra').value;
      var extraf = document.getElementById('inextraf').value;

      var prueba = document.getElementById('stperiodo').value;
      var data_in = document.getElementById('inaltain').value;

      var steqipo = document.getElementById('steqipo').value;
      var stlugar = document.getElementById('stlugar').value;
      var stclient = document.getElementById('stclient').value;

      //stclient
      //data_in = data
      //console.log('steqipo:', steqipo, 'stlugar:', stlugar);

      var newold = this.props.newold

      if (newold === 'new') {
        var data = { 
        "cmd": 'AddInTable',
        "user_id": ide.id,
        "usr_id": ide.id,
        "tbl": "db_trabajador",
        'corp_id': miUser.work, 
        'data_in': this.state.st_alta_in, 
        'contrato': contrato, 
        'tpuesto': tpuesto,
        'salario': salario, 
        'extra': extra,
        'ExtraFest': extraf, 
        'prueba': prueba,
        'id_lugar': stlugar, 
        'id_eqipo': steqipo,
        'id_cliente': stclient
      };
      SndMSG('Buena noticia ! la empresa te ha contratado !',  ide.id)
    }

      if (newold > 0) {
        var data = { 
        "cmd": 'UpdateTable',
        "user_id": newold,
        "usr_id": this.props.userid.id,
        "tbl": "db_trabajador",
        'data_in': this.state.st_alta_in, 
        'corp_id': miUser.work, 
        'contrato': contrato, 
        'tpuesto': tpuesto,
        'salario': salario, 
        'extra': extra,
        'ExtraFest': extraf, 
        'prueba': prueba,
        'id_lugar': stlugar, 
        'id_eqipo': steqipo,
        'id_cliente': stclient
      };
      SndMSG('La empresa ha modificada tus condiciones',  ide.id)

    }

      console.log('PinSave data:', data);

    
      fetch('https://inupline.com/post.php',
        {
          method: 'POST',
          body: JSON.stringify(data)
        }
      )
        .then (response => {
            console.log(response);
            })
    
        .then((result) => {
            console.log('Success:', result);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
        //console.log('data:', data);

        this.props.redrawall()

    }


    sellugar = (value) => {
      this.setState({ st_lugar_id: value }, () => 
      console.log(this.state.st_lugar_id));
    }

    selclient = (value) => {
      this.setState({ st_client_id: value }, () => 
      console.log(this.state.st_client_id));
    }

    seleqipo = (value) => {
      this.setState({ st_eqipo_id: value }, () => 
      console.log(this.state.st_eqipo_id));
    }

    selcontrato = (value) => {
      this.setState({ st_contrato_id: value }, () => 
      console.log(this.state.st_contrato_id));
    }

    selpuesto = (value) => {
      this.setState({ st_puesto_id: value }, () => 
      console.log(this.state.st_puesto_id));
    }

    selperiodo = (value) => {
      this.setState({ st_periodo_id: value }, () => 
      console.log(this.state.st_periodo_id));
    }

    selalta = (value) => {
      this.setState({ st_alta_in: value }, () => 
      console.log(this.state.st_alta_in));
    }

    componentDidUpdate(prevProps, prevState) {
      if(prevProps.newold !== this.props.newold) {
        //console.log('cheng  newold ', this.props.newold);
        this.LoadDataClients()
      }

      if(prevProps.userid !== this.props.userid) {
        //this.LoadDataClients()
      }

      if(prevState.st_usersel !== this.state.st_usersel) {
        if (this.state.st_usersel[0]){
          this.setState({ st_eqipo_id: this.state.st_usersel[0].id_eqipo })
          this.setState({ st_lugar_id: this.state.st_usersel[0].id_lugar })
          this.setState({ st_client_id: this.state.st_usersel[0].id_cliente })
          this.setState({ st_contrato_id: this.state.st_usersel[0].contrato })
          this.setState({ st_puesto_id: this.state.st_usersel[0].tpuesto })
          this.setState({ st_periodo_id: this.state.st_usersel[0].prueba })
          this.setState({ st_salario_id: this.state.st_usersel[0].salario })
          this.setState({ st_extra_id: this.state.st_usersel[0].extra })
          this.setState({ st_extraf_id: this.state.st_usersel[0].ExtraFest })
          //console.log('Оновлення даних емплео', this.state.st_usersel[0].user_id);
        }
      }

    }
    render() {
      const { st_eqipo } = this.state;
      const { st_client } = this.state;
      const { st_lugar } = this.state;
      //const { st_usersel } = this.state;
      const { newold } = this.props;
      const { userid } = this.props;
     
      return (
        <div className = "selcompany-info" >
            {newold}/{userid.id}

        {this.state.st_loading ? <div>LOADING</div>: (
          <div>

            <CntrSelect cid = "stcontrato" caption = "Contrato:" onChange={(e) => this.selcontrato(e.target.value)} value = {this.state.st_contrato_id}>
              <option key="se001" value="0">Indefenido</option>
              <option key="se002" value="1">Temporal</option>
              <option key="se003" value="2">Fin de obra</option>
              <option key="se004" value="3">Subcontrato</option>
            </CntrSelect>

            <CntrDataBox cid = "inaltain" caption = "Alta desde" placeholder="Fecha de alta" maxLength="11"  onClick={(e) => this.selalta(e.target.value)}  value = {this.state.st_alta_in}/>

            <CntrSelect cid = "stpuesto" caption = "Puesto:" onChange={(e) => this.selpuesto(e.target.value)} value = {this.state.st_puesto_id}>
              <option key="sp001" value="0">Oficial 1</option>
              <option key="sp002" value="1">Oficial 2</option>
              <option key="sp003" value="2">Oficial 3</option>
              <option key="sp004" value="3">Ayudante</option>
              <option key="sp005" value="4">Peon</option>
            </CntrSelect>

            <CntrSelect cid = "stclient" caption = "Client:" onChange={(e) => this.selclient(e.target.value)} value = {this.state.st_client_id}>
              {st_client.map((item => (
                  <option key={item.id} value={item.id}>{item.c_name}</option>
              )))}
            </CntrSelect>

            <CntrSelect cid = "steqipo" caption = "Eqipo:" onChange={(e) => this.seleqipo(e.target.value)} value = {this.state.st_eqipo_id}>
              {st_eqipo.map((item => (
                  <option key={item.id} value={item.id}>{item.name}</option>
              )))}
            </CntrSelect>

            <CntrSelect cid = "stlugar" caption = "Lugar:" onChange={(e) => this.sellugar(e.target.value)} value = {this.state.st_lugar_id}>
              {st_lugar.map((item => (
                  <option key={item.id} value={item.id}>{item.c_lugar}</option>
              )))}                  
            </CntrSelect>

            <CntrTextbox cid = "insalario" caption = "Salario" placeholder="salario" maxLength="11" value = {this.state.st_salario_id}/>
            <CntrTextbox cid = "inextra" caption = "Extra horas" placeholder="extra labo h" maxLength="11" value = {this.state.st_extra_id}/>
            <CntrTextbox cid = "inextraf" caption = "Extra horas" placeholder="extra fest h" maxLength="11" value = {this.state.st_extraf_id}/>

            <CntrSelect cid = "stperiodo" caption = "Prueba:"  onChange={(e) => this.selperiodo(e.target.value)} value = {this.state.st_periodo_id}>
              <option key="spr_000" value="0">inmediante</option>
              <option key="spr_001" value="1">7</option>
              <option key="spr_002" value="1">14</option>
              <option key="spr_003" value="2">30</option>
              <option key="spr_004" value="3">60</option>
              <option key="spr_005" value="4">90</option>
            </CntrSelect>

            <div className="downmodal">
              <button className="btnboxmenu" onClick={() => {this.PinSave(userid)}} >Confirmar</button>
              <button className="btnboxmenu" onClick={() => {MW1close('WM002')}} >CLOSE</button>
            </div>

          </div>
        )}

          
        </div>
      );
    }
  }    
