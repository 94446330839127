import './../style.css';
import './sliderdual.css';
import '../../../css/widgsystem.css';
import React from "react";

export default class SliderDual extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      sliderOne: this.props.v1,
      sliderTwo: this.props.v2,
      minGap: 0,
      sliderMaxValue: this.props.max,
      value: 0
    };
    //this.updateV1 = this.updateV1.bind(this);
    //this.updateV2 = this.updateV2.bind(this);
  }

  updateV1(event){
    //this.setState({sliderOne : event.target.value}, () => 

    this.props.onChange(this.state.sliderOne, this.state.sliderTwo, this.state.value)//);
  }

  updateV2(event){
    
    //this.setState({sliderTwo : event.target.value}, () => 
    this.props.onChange(this.state.sliderOne, this.state.sliderTwo, this.state.value)//);
  }

componentDidMount() {
  this.fillColor();

  this.setState({ value: this.props.horas });
  
}

componentDidUpdate(prevProps, prevState) {

  if(prevProps.v2 !== this.props.v2 ) {
    this.setState({ sliderTwo: this.props.v2 });
  }

  if (prevProps.v1 !== this.props.v1) {
    this.setState({ sliderOne: this.props.v1 });
  }

  if (prevProps.horas !== this.props.horas) {
    this.setState({ value: this.props.horas });
  }
  

  

  this.fillColor();
}


slideKeyOne(){
  this.fillColor();
  var slider1 = document.getElementById("slider-1");
  var slider2 = document.getElementById("slider-2");
  var currentVal = slider1.value;
  this.setState({
    sliderOne: currentVal
  })

  if(Number.parseInt(slider2.value) - Number.parseInt(slider1.value) <= this.state.minGap){
    let sliderOne = this.state.sliderTwo //- this.state.minGap;
    this.setState({ sliderOne: sliderOne }, () => 
    console.log("no puede ser mas"));
}

if(Number.parseInt(slider2.value) >= Number.parseInt(slider1.value)){
  let SetValue = Number.parseInt(slider2.value) - Number.parseInt(slider1.value)
  
  //let SetValue = this.props.horas

  this.setState({ value: SetValue }, () =>
  this.props.onChange(this.state.sliderOne, this.state.sliderTwo, this.state.value));
}

  this.fillColor();
  this.props.onChange(this.state.sliderOne, this.state.sliderTwo, this.state.value)//);

}

slideKeyTwo(){
  this.fillColor();
  var slider1 = document.getElementById("slider-1");
  var slider2 = document.getElementById("slider-2");
  var currentVal = slider2.value;
  this.setState({
    sliderTwo: currentVal
  })

  if(Number.parseInt(slider1.value) === 24){
    this.setState({ sliderOne:slider1.value -8  }, () => 
      console.log("reset"));
  }

  if(Number.parseInt(slider2.value) > 25){
    this.setState({ sliderTwo: 24  });
  }

  if(Number.parseInt(slider2.value) - Number.parseInt(slider1.value) <= this.state.minGap){
      let sliderTwo = this.state.sliderOne //+ this.state.minGap;
      this.setState({ sliderTwo: sliderTwo }, () => 
      console.log("no puede ser minimum"));
  }

  if(Number.parseInt(slider2.value) >= Number.parseInt(slider1.value)){
    let SetValue = Number.parseInt(slider2.value) - Number.parseInt(slider1.value)

    //let SetValue = this.props.horas

  

    this.setState({ value: SetValue }, () =>
    this.props.onChange(this.state.sliderOne, this.state.sliderTwo, this.state.value));
  }
  this.fillColor();

}

fillColor(){
  let sliderTrack = document.querySelector(".slider-track");
  let percent1 = (this.state.sliderOne / this.state.sliderMaxValue) * 100;
  let percent2 = (this.state.sliderTwo / this.state.sliderMaxValue) * 100;
  sliderTrack.style.background = `linear-gradient(to right, #3b3b3b ${percent1}% , #fcb322 ${percent1}% , #fcb322 ${percent2}%, #3b3b3b ${percent2}%)`;
}

  render() {
    return (
    <div>
      <div className="dayvalues">
          <span id="range1">
            {this.state.sliderOne}
          </span>
          <span> - </span>
          <span id="range2">
              {this.state.sliderTwo}
          </span>

          <span> = </span>
          <span id="range2">
              { this.props.horas }
          </span>
      </div>
      <div className="containerin">
        <div className="slider-track"></div>
        <input className="d-inline-block volume" type="range" min="0"  step="1" max={this.state.sliderMaxValue} value={this.state.sliderOne} id="slider-1" onInput={this.slideKeyOne.bind(this)} />
        <input className="d-inline-block volume" type="range" min="0"  step="1" max={this.state.sliderMaxValue} value={this.state.sliderTwo} id="slider-2" onInput={this.slideKeyTwo.bind(this)} />
      </div>
    </div>
    )
  }

}

/*
// Specifies the default values for props:
SliderDual.defaultProps = {
  v1: 8,
  v2: 18,
  max: 24
};*/