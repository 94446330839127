import './style.css';
import './flag-icon.css';
import LngItem from './LngItem'
import PropTypes from 'prop-types'

function LngList({toSel, onToggle, onCheng} ) {
    //const [count, setCount] = useState(0);
   
    function inSelect(index){
        if (index === toSel) { 
            //onCheng(index);
            //console.log(index);
            //console.log("Click menu por ITEM = " + index + ": Sel = " + props.toSel);
            return '1';
        } else { 
            return '0';
        }
    }

    return (
        <div id="myDropdown" className="kmenu-content show">
            <LngItem lng = 'gb' text = "English" isActive = {inSelect('gb')} url = "" icon ="fa fa-home" onClick={onToggle}/>
            <LngItem lng = 'es' text = "Español" isActive = {inSelect('es')} url = "" icon ="fa fa-home" onClick={onToggle}/>
            <LngItem lng = 'ua' text = "Українська" isActive = {inSelect('ua')} url = "" icon ="fa fa-home" onClick={onToggle}/>
        </div>
    );
  }
  
  LngList.prototype = {
    onCheng: PropTypes.func.isRequired,
    toSel: PropTypes.number,
    onToggle: PropTypes.func.isRequired
  }
export default LngList;