//import './../style.css';
//import '../../../css/widgsystem.css';
import './style.css';

//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";

import React from "react";
//import {UIlengID} from '../../../App'; // Потрібно файл де буде завантажуватися всі переміні
import { myUsers, LoadAvatarHis, AppCaption ,SetHistory} from "../../../redux/state";

//import GostAva from '../../../img/guest_nano.png';  //sadmin.png

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line , Doughnut} from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const data2 = {
  //labels: ['Red', 'Blue', 'Yellow'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export const options = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: false,
      text: 'Chart.js Line Chart - Multi Axis',
    },
  },
  scales: {
    y: {
      type: 'linear',
      display: true,
      position: 'left',
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const foo1 = [100, 50, 90, 140, 100, 120, 20 ];
const foo2 = [50, 100, 120, 150, 140, 100, 80 ];

export const data = {
  labels,
  datasets: [
    {
      label: 'GOSTS',
      data: foo1.map((foo1) => foo1),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
      yAxisID: 'y',
    },
    {
      label: 'USERS',
      data: foo2.map((foo2) => foo2),
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
      yAxisID: 'y1',
    },
  ],
};


export default class IndexUserHome extends React.Component{

    constructor() {
      super()
      this.state = {
          userids : [], // sel user id  7
        };
        this.updateIds = this.updateIds.bind(this);
      }
      
    updateIds = (ids) => {
      //MW1info(ids); // show modal
    }
  
    componentDidMount() {
      AppCaption('HISTORY');
     // myUsers.callUser();

      //this.redrawinfo(date)

    }

    
  
    render() {
      return (
        <div className = "saback-box">
              <HistoryList updateIds = {this.updateIds}/>
            {/* <div className = "chartboxhistory">
              <Line options={options} data={data} />
            </div> */}
            {/* {<div className = "chartboxhistory3">
              <CtrlHistory />
            </div>} */}
            {/* <div className = "chartboxhistory2">
              <Doughnut data={data2} />
            </div> */}
          </div>
      );
    }
  }    

  class HistoryList extends React.Component{ //extending Grid
    constructor(props) {
      super(props);
      this.state = {
        items: [],
        startDate: this.formatDate(new Date().toLocaleDateString("es-CL"))
      };
      this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
             startDate: event
        })
        //console.log('startDate', this.state.startDate);

        this.redrawTab(event)
      }


      redrawTab(date) {
        fetch("https://inupline.com/post.php", {
            method: 'POST',
            header : {
            Accept: 'application/json', 'Content-Type': 'application/json',
            },
            body : JSON.stringify({
            rootcmd: "historylist",
            hdate: date
        })
        })
        .then (response => response.json())
            .then(response => {
            this.setState({
                items: response
            });
            console.log(response);
            });
      }

    componentDidMount() {
        this.redrawTab (this.state.startDate);
        document.documentElement.className = 'dark';      
        //console.log('startDate', this.formatDate(this.state.startDate));
    }
  
    seluser = (ids) => {
      this.props.updateIds(ids);
    }

    iconlist = (ids) => {
        if (ids === "0") { return <span className="linG"><i className="fa fa-sign-in" aria-hidden="true"></i></span>;} // IN
        if (ids === "1") { return <span className="linR"><i className="fa fa-sign-out" aria-hidden="true"></i></span>;} // OUT
        if (ids === "2") { return <span className="linV"><i className="fa fa-user-plus" aria-hidden="true"></i></span>;} // NEW
        if (ids === "3") { return <span className="linY"><i className="fa fa-certificate" aria-hidden="true"></i></span>;} // first_run
        if (ids === "4") { return <span className="linN"><i className="fa fa-pencil-square" aria-hidden="true"></i></span>;} // Update user data
        if (ids === "5") { return <span className="linB"><i className="fa fa-eye" aria-hidden="true"></i></span>;} // GHOST
        if (ids === "6") { return <span className="linB"><i className="fa fa-file-text-o" aria-hidden="true"></i></span>;} // View CV
    }
  
    lngUI = (value) => {
      if (value) {
        var lng = value
        //console.log('flag-icon flag-icon-' + value.toLowerCase(),);
        return "flag-icon flag-icon-" + lng.toLowerCase();
      };
    }

    drkmode () {
      var theme = document.documentElement.className;

      if (theme === 'dark') {
        document.documentElement.className = 'light';
        //var theme = 'light'
      } else {
        document.documentElement.className = 'dark';
        //var theme = 'dark'
      }
    }

    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [year, day, month ].join('-');
  }

    render() {
      const { items } = this.state;
      var i = 1;
        return (
          <div>
            <div className = "btntools">
              <button className="btnboxmenu sulite" id="bch0" onClick={() => this.drkmode()}>IN</button>
              <input className="findsuser" type="date" id="seldata" placeholder="DOB (DD/MM/YYYY)" name="seldata" data-date="" onChange={e => this.handleChange(e.target.value)} value={this.state.startDate} />

              <input className="findsuser" id = "findtext" type="text" name="userName" placeholder="User name..." onKeyDown={this.handleKeyDown}/>
              <button className="btnboxmenu sulite" id="bfind"  onClick={() => {this.FindText()}} > <i className="fa fa-search" aria-hidden="true"></i></button>
            </div>
              <div id="gridhistory" className="gridhistory">
                    <ul id="grid1" className="">
                        {items.map(item => (

                        <li className="lisinmarker" key={item.id} id = {item.id}>
                            <div className="listelement" id="listelement20421">
                                <figure className="userlist" id="data-idc20421" data-idc="20421">
                                    <input className="checkboxs" id={"check" + item.id} name="checkbox20421" type="checkbox"/>
                                    <span className="linenumero">{i++}</span>
                                    <span className="lisdate"><i className="fa fa-clock-o" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;{item.htime}&nbsp;/&nbsp;{item.hdate}</span>
                                    {this.iconlist(item.error_id)}
                                    <span className="lpais"><span className={this.lngUI(item.user_pais)}></span> {item.user_city} ({item.user_pais})</span>
                                    <span className="lineip"><i className="fa fa-globe" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;{item.user_ip}</span>
                                    {LoadAvatarHis(item.huser_id, "pimg", null ,'m',item.service)}
                                    <figcaption>
                                        <span className="het">{item.husername} </span>
                                        <p className="hez">
                                            &nbsp;<i className={'fa ' + item.ico_type} aria-hidden="true"></i>
                                            &nbsp;<i className={'fa ' + item.ico_os} aria-hidden="true"></i>
                                            &nbsp;<i className={'fa ' + item.ico_brow} aria-hidden="true"></i>
                                            {/* &nbsp;&nbsp;&nbsp;{item.user_browser}  */}
                                        </p>
                                    </figcaption>
                                </figure>
                            </div>
                        </li>
                        ))}
                    </ul>
                    <div>
                        <p style={{color: "white"}}>All elements: {i-1} </p>
                    </div>
                </div>
          </div>
        );
    }
  }


