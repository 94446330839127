import './../style.css';
import '../../../css/widgsystem.css';

import React from "react";
//import {UIlengID} from '../../../App'; // Потрібно файл де буде завантажуватися всі переміні
import { myUsers, AppCaption} from "../../../redux/state";
//import GostAva from '../../../img/guest_nano.png';  //sadmin.png


export default class IndexUserHome extends React.Component{

    constructor() {
      super()
      this.state = {
          userids : [] // sel user id  
        };
        this.updateIds = this.updateIds.bind(this)
      }
      
    updatecaption(){
      AppCaption('','USER AJUSTES','myoptions','');

      myUsers.callUser();
    }
  
    useropenid(){
      //console.log('work');
    }
  
    updateIds = (ids) => {
      //MW1info(ids); // show modal
    }
  
    componentDidMount() {
      this.updatecaption();
    }
  
    render() {
      return (
        <div className = "saback-box">
            ERRORES
          </div>
      );
    }
  }    