//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faCoffee } from '@fortawesome/free-solid-svg-icons' 

function GhMenu({id, sel, text,  isActive, onClick, icon}) {
    let Sline = '';
    let Ssel = 'grey';
    //let Bline = '';

    if (isActive === '1') {
        Ssel = '#191919';
        Sline = '#ffffff';
        //Bline = '5px solid #FCB322';
    } else if (isActive === '0') {
        Sline = '#ffffff59';
        Ssel = 'white';
    }

    const styles = {
        dropbtn: {
            fontFamily: "sans-serif",
            fontSize: '24px',
            marginLeft: '5px',
            paddingLeft: '8px',
            paddingRight: '8px',
            paddingBottom: '8px',
            border: 'none',
            cursor: 'pointer',
            color: '#fff',
            //borderBottom: Bline
        },
        dropbtnicon: {
            marginLeft: '5px',
            border: 'none',
            cursor: 'pointer',
            backgroundColor: Sline,
            borderRadius: '5px',
            width: '20px'
        },
        spamicon: {
            fontSize: '24px',
            color: Ssel,
        }
    }   

function onMouseOver(event) {
    event.target.style.color = '#FCB322';
}

function MouseOut(event) {
    event.target.style.color = '#ffffff';
}
    
    return (
        <button onMouseOver={onMouseOver} onMouseOut={MouseOut} onClick={() => onClick(id)} style={styles.dropbtn}>
            <span onMouseOver={onMouseOver} onMouseOut={MouseOut} className="mxtext" style={styles.dropbtn}>{text}</span>
            <span onMouseOver={onMouseOver} onMouseOut={MouseOut} className="mxicon" style={styles.dropbtnicon}><i className={icon} style={styles.spamicon} aria-hidden="true"></i></span>
        </button>
    );
}

export default GhMenu;