import './../style.css';
import '../../../css/widgsystem.css';

import React from "react";
//import {UIlengID} from '../../../App'; // Потрібно файл де буде завантажуватися всі переміні
import { myUsers, miUser, AppCaption, LoadAvatar ,MW1close, MW1info, ModalInfo} from "../../../redux/state";
import { Link, useNavigate } from "react-router-dom";

//import GostAva from '../../../img/guest_nano.png';  //sadmin.png

import EmpleoAjustes from "./empleo_ajustes"
import EmpleoInfo from "./empleo_info"
import EmpleoDel from "./empleo_del"
import EmpleoChat from "./empleo_chat"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


export default class IndexUserHome extends React.Component{
    constructor() {
      super()
      this.state = {
          userids : [],
          guser: [],
          ftext: '', // sel user id  7
        };
      }
       
    componentDidMount() {
      AppCaption('EMPLEADOS','MI EMPRESA','micompany','empleados');
      myUsers.callUser();
    }
  
    
    FindText = () => {
      var ftext = document.getElementById('findtext').value;
      this.setState({ftext: ftext})
    }

    render() {
      return (
        <div >
            <div className = "btntools">
              <input className="findsuser" id = "findtext" type="text" name="userName" placeholder="User name..." onKeyDown={this.handleKeyDown}/>
              <button className="btnboxmenu sulite" id="bfind"  onClick={() => {this.FindText()}} > <i className="fa fa-search" aria-hidden="true"></i></button>
            </div>
            <div id = "listusr">
              <SuserList search = {this.state.ftext}/>
            </div>
        </div>
      );
    }
  }    
  

  class SuserList extends React.Component{ //extending Grid
    constructor(props) {
      super(props);
      this.state = {
        count: 0, 
        userid: [],
        guser: [],
        intramite: [],
        counttram: [], 
        items: [],
        //usersel: [],
        oldnew: '',
        tabIndex: 0 // sel user id  7

      };
      this.RedrawAll = this.RedrawAll.bind(this);
      this.openid = React.createRef();
    }

    notifyok = () => toast.success(' DATOS ACTUALIZADO !!!', {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });

    GetBase(tbl,colum,row, mstate, data2){
      let datar = [];
      datar = { 
        "cmd": 'FindText',
        "colum": colum,
        "tbl": tbl,
        'find': row, //this.props.search, 
      };

      if (data2) {datar.finds = data2}

      //console.log("UPDATE  data " , datar)

      fetch('https://inupline.com/post.php',
        {
          method: 'POST',
          body: JSON.stringify(datar)
        }
      )
      
      .then (response => response.json())
        .then(response => {
          //console.log(data2 + ' GetBase ' + tbl ,response);

          this.setState({
            [mstate]: response
          });
          return response;
        });
    }

    componentDidUpdate(prevProps, prevState) {
      // Check if the suplied props is changed
      let OldIntro = []; 

      const { intramite } = this.state;
      const { guser } = this.state;
      const { items } = this.state;
      var bl = [];
      var linall = [];

      if(prevState.items !== this.state.items) {

        for (var i = 0, len = this.state.items.length; i < len; i++) {
          //OldIntro.push ({'user_id':this.state.items[i].user_id,'id': this.state.items[i].id})
          OldIntro.push (this.state.items[i].usr_id)
        } 
        //if(this.state.items.length !== 0) { 
          this.GetBase("users","id",0, "guser", OldIntro)
        //}
          this.setState({count: items.length })
          
          

          //console.log("OldIntro " , OldIntro)
        }

      if(prevState.guser !== this.state.guser) {
        for (var x = 0, lex = guser.length; x < lex; x++) {
          for (var a = 0, lea = intramite.length; a < lea; a++) {
              if (intramite[a].id === guser[x].id) {  bl.push (intramite[a].id) }
          }
        } 

        for (var a = 0, lea = intramite.length; a < lea; a++) {
            linall.push (intramite[a].id)
        }

        for (var a = 0, lea = bl.length; a < lea; a++) {
          var index = linall.indexOf(bl[a]);

          if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
            linall.splice(index, 1);
          }
        }

        //console.log(" - linall " , linall )

        this.setState({counttram: linall })
        //this.setState({intramite: linall })

      }


      if(prevState.intramite !== this.state.intramite) {
          //this.setState({counttram: this.state.intramite.length })
          //this.GetBase("users","id",0, "guser", OldIntro)

      }

    }
  
    componentWillMount () {
      this.GetBase("db_trabajador","corp_id", miUser.work, "items")
      this.GetBase("users","work",miUser.work, "intramite")
    }
  
    seluser = (ids) => {      
      this.props.updateIds(ids);
    }
  
    selcompany = (itm) => {
      this.props.GetCompany(itm); // передаємо в батьківський контрол атрибут
    }

    ShowSetings(selid,onwe) {
      MW1info("WM001");

      this.setState({tabIndex: 0 }) //tabIndex
      this.setState({userid: selid })
      this.setState({oldnew: onwe })
      //this.setState({usersel: usersel })
      this.openid.current.LoadDataClients();

    }

    ShowInfo(selid) {
      MW1info("WM001");
      this.setState({tabIndex: 1 }) //tabIndex
      this.setState({userid: selid })
    }

    DeleteInfo(selid,onwe) {
      MW1info("WM001");
      this.setState({tabIndex: 3 }) //tabIndex
      this.setState({userid: selid })
      this.setState({oldnew: onwe })

    }

    DeleteTab(selid,onwe) {
      this.setState({tabIndex: 3 }) //tabIndex
      this.setState({userid: selid })
      this.setState({oldnew: onwe })
      //console.log('DeleteInfo', onwe )

    }
    

    ShowChat(selid) {
      MW1info("WM001");
      this.setState({tabIndex: 2 }) //tabIndex
      this.setState({userid: selid }) //tabIndex
    }

    RedrawAll = (frm) => {
      this.GetBase("db_trabajador","corp_id", miUser.work, "items")
      this.GetBase("users","work",miUser.work, "intramite")
        MW1close('WM001');
      this.notifyok();
    }

    

    listbox() {
      const { items } = this.state;
      const { guser } = this.state;

      var i = 1;
      
      //console.log(items, guser )

      if (items) {
      return (
        items.map((item , index) => (
          guser.map(gitem => {
            if (gitem.id === item.usr_id && '3' !== item.contrato) {
              return <li className="listmaket" key={item.id} id = {item.id} >
                      <div className="listempleado" id={item.id}>
                          <figure className="userlist" id={item.id} data-idc="20421">
                              <span className="list_empleo_numer">{this.showindex(index)}</span>
                                <figcaption>
                                    {LoadAvatar(gitem, "list_empleo_pimg", null ,'m', '')}
                                    <span className="list_empleo_het">{gitem.fname} {gitem.lname}</span>
                                    <p className="list_empleo_hez"><i className="fa fa-info-circle" aria-hidden="true">
                                        </i>&nbsp;&nbsp;work&nbsp;{gitem.work}&nbsp;work&nbsp;{gitem.id}&nbsp; Puesto: {item.tpuesto}</p>
                                        {/*console.log(gitem, item)*/}
                                </figcaption>
                          </figure>
                      </div>
                      <div className="editbtn">
                        <div className="btn_setings"><div className="fa wa fa-calendar"  aria-hidden="true">&nbsp;</div></div>
                        <div className="btn_setings"><div className="fa wa fa-comments-o"  aria-hidden="true">&nbsp;</div></div>
                        <div className="btn_setings"><div className="fa wa fa-users"  aria-hidden="true">&nbsp;</div></div>
                        <div className="btn_setings"><div className="fa wa fa-street-view">&nbsp;</div></div>
                        <div className="btn_setings" onClick={() => {this.ShowSetings(gitem,item.id)}} ><div className="fa wa fa-pencil">&nbsp;</div></div>
                      </div>
                  </li>
            }
          })
        ))
      )
      } else {
        return "WAIT..."
      }
    }

    listbox_subcontrato() {
      const { items } = this.state;
      const { guser } = this.state;

      var i = 1;
      
      //console.log(items, guser )

      if (items) {
      return (
        items.map((item , index) => (
          guser.map(gitem => {
            if (gitem.id === item.usr_id && '3' === item.contrato) {
              return <li className="listmaket" key={item.id} id = {item.id} >
                      <div className="listempleado" id={item.id}>
                          <figure className="userlist" id={item.id} data-idc="20421">
                              <span className="list_empleo_numer">{this.showindex(index)}</span>
                                <figcaption>
                                    {LoadAvatar(gitem, "list_empleo_pimg", null ,'m', '')}
                                    <span className="list_empleo_het">{gitem.fname} {gitem.lname}</span>
                                    <p className="list_empleo_hez"><i className="fa fa-info-circle" aria-hidden="true">
                                        </i>&nbsp;&nbsp;&nbsp;{gitem.work}&nbsp;Puesto:{item.tpuesto}</p>
                                </figcaption>
                          </figure>
                      </div>
                      <div className="editbtn">
                        <div className="btn_setings"><div className="fa wa fa-calendar"  aria-hidden="true">&nbsp;</div></div>
                        <div className="btn_setings"><div className="fa wa fa-comments-o"  aria-hidden="true">&nbsp;</div></div>
                        <div className="btn_setings"><div className="fa wa fa-users"  aria-hidden="true">&nbsp;</div></div>
                        <div className="btn_setings"><div className="fa wa fa-street-view">&nbsp;</div></div>
                        <div className="btn_setings" onClick={() => {this.ShowSetings(gitem,item.id)}} ><div className="fa wa fa-pencil">&nbsp;</div></div>
                      </div>
                  </li>
            }
          })
        ))
      )
      } else {
        return "WAIT..."
      }
    }

    listbox_tramit() {
      
      const { intramite } = this.state;
      const { counttram } = this.state;
      var i = 0;
      var lins = [];
      var linall = [];


      if (intramite) {
      //return (
        //for (var i = 0, lei = intramite.length; i < lei; i++) {
          //console.log("counttram ", intramite)
          intramite.map((item , index) => {
          for (var b = 0, len = counttram.length; b < len; b++) {
            if (counttram[b] === item.id || counttram.length === 0) { i=i++; lins.push (<li className="listmaket" key={item.id} id = {item.id} >
                <div className="listempleado" id={item.id}>
                    <figure className="userlist" id={item.id} data-idc="20421">
                        <span className="list_empleo_numer">{this.showindex(i)}</span>
                        
                        {LoadAvatar(item, "list_empleo_pimg", null ,'m', '')}
                        <figcaption>
                            <span className="list_empleo_het">{item.fname} {item.lname}  {item.id}</span>
                            <p className="list_empleo_hez"><i className="fa fa-info-circle" aria-hidden="true">
                                </i>&nbsp;&nbsp;&nbsp;{item.nacionalidad}&nbsp;{item.work}</p>
                        </figcaption>
                    </figure>
                </div>
                <div className="editbtn"> 
                  <div className="btn_setings" onClick={() => {this.ShowSetings(item,"new")}} ><div className="fa wa fa-check">&nbsp;</div></div>
                  <div className="btn_setings" onClick={() => {this.ShowInfo(item)}}><div className="fa fa-user"  aria-hidden="true">&nbsp;</div></div>
                  <div className="btn_setings" onClick={() => {this.ShowChat(item)}}><div className="fa wa fa-comments-o"  aria-hidden="true">&nbsp;</div></div>
                  <div className="btn_setings" onClick={() => {this.DeleteInfo(item,"new")}}><div className="fa wa fa-trash-o"  aria-hidden="true">&nbsp;</div></div>
                </div>
            </li>)
          }}})
        
        return lins;   //fa fa-ellipsis-v
        }
      
    }

    showindex(index) {
      return (Number(index) +1 );
    }

    intamcount(){
      if (this.state.counttram.length > 0){
          return <div className = "txtinfo" style={{color: 'white'}}> <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> En trámite {this.state.counttram.length} personas</div>
      }
    }

    setTabIndex(index){
      this.setState({tabIndex: index }) //tabIndex
    }
  
    render() {
      const { items } = this.state;
      const { oldnew } = this.state;
      const { tabIndex } = this.state;
      const { userid } = this.state;
      
        return (
          <div id="gridhistory" className="gridhistory">
              <span className = "txtinfo">Contratados:</span>

              <ul id="grid1" className="">
                  {this.listbox()}
              </ul>
              <span className = "txtinfo">SUBCONTRATO</span>

              <ul id="grid1" className="">
                  {this.listbox_subcontrato()}
              </ul>

              <span className = "txtinfo"><i className="fa fa-info-circle" aria-hidden="true"></i> En la lista de empleados hay {this.state.count} personas</span>

              {this.intamcount()}

              <ul id="grid1" className="">
                  {this.listbox_tramit()}
              </ul>
              
              <ModalInfo id = "WM001" titles = "modal 1">
                <div className = "cont_ava">
                  {LoadAvatar(userid, "list_empleo_ecimg", null ,'', '')}
                  <div className = "userc_name">{userid.fname} {userid.lname}</div>
                  <div className="btn_panel_modal_left_top" >
                    <div className="btn_modal_tool" onClick={(e) => { e.preventDefault(); window.location.href='tel:+34602643572';}}><div className="fa fa-phone"  aria-hidden="true">&nbsp;</div></div>
                    <div className="btn_modal_tool" onClick={(e) => { e.preventDefault(); window.location.href='whatsapp://+34602643572';}}><div className="fa fa-whatsapp"  aria-hidden="true">&nbsp;</div></div>

                    <div className="btn_modal_tool" onClick={() => {this.DeleteTab(userid,!oldnew?"new":oldnew)}}><div className="fa wa fa-trash-o"  aria-hidden="true">&nbsp;</div></div>
                  </div>
                </div>
                <div className = "conteiner_ce">
                  <Tabs selectedIndex={tabIndex} onSelect={index => this.setTabIndex(index)}>
                    <TabList>
                      <Tab>Contratar</Tab>
                      <Tab><i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;CV</Tab>
                      <Tab><i className="fa wa fa-comments-o" aria-hidden="true"></i>&nbsp;CHAT</Tab>
                      <Tab>&nbsp;Edit</Tab>

                    </TabList>

                    <TabPanel>
                      <EmpleoAjustes userid = {this.state.userid} redrawall = {this.RedrawAll} newold = {this.state.oldnew} ref={this.openid}/>
                    </TabPanel>
                    <TabPanel>
                      <EmpleoInfo  userid = {this.state.userid}/>
                    </TabPanel>
                    <TabPanel>
                      <EmpleoChat userid = {this.state.userid}/>
                    </TabPanel>
                    <TabPanel>
                      <EmpleoDel userid = {this.state.userid} redrawall = {this.RedrawAll}  newold = {this.state.oldnew} table = 'db_trabajador'/>
                    </TabPanel>
                  </Tabs>
                </div>
              </ModalInfo>


              

              <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              />

          </div>
        );
    }
  }
  
  