import './../style.css';
import '../../../css/widgsystem.css';

import React from "react";
//import {UIlengID} from '../../../App'; // Потрібно файл де буде завантажуватися всі переміні
import { myUsers, miUser, LoadAvatarHis, LoadAvatar ,AppCaption} from "../../../redux/state";

//import GostAva from '../../../img/guest_nano.png';  //sadmin.png


export default class IndexUserHome extends React.Component{
    constructor() {
      super()
      this.state = {
          userids : [],
          ftext: '' // sel user id  7
        };
        this.updateIds = this.updateIds.bind(this);
      }
      
    updateIds = (ids) => {
      //MW1info(ids); // show modal
    }
  
    componentDidMount() {
      AppCaption('Documents','MI EMPRESA','micompany','documentos');
      myUsers.callUser();
    }
  
    

    FindText = () => {
      var ftext = document.getElementById('findtext').value;
      this.setState({ftext: ftext})
    }

    render() {
      return (
        <div >
            <div className = "btntools">
            <input className="findsuser" id = "findtext" type="text" name="userName" placeholder="User name..." onKeyDown={this.handleKeyDown}/>
            <button className="btnboxmenu sulite" id="bfind"  onClick={() => {this.FindText()}} > <i className="fa fa-search" aria-hidden="true"></i></button>
          </div>
            <div id = "listusr">
              
              <SuserList search = {this.state.ftext}/>
              <span className = "txtinfo"><i className="fa fa-info-circle" aria-hidden="true"></i> * info</span>
            </div>
        </div>
      );
    }
  }    
  

  class SuserList extends React.Component{ //extending Grid
    constructor(props) {
      super(props);
      this.state = {
        items: [],
      };
    }
  
    findtext(){
      //var ftext = document.getElementById('findtext').value;
      //this.props.search
  
      var data = { 
        "cmd": 'FindText',
        "colum": "id_comp",
        "tbl": "db_client",
        'find': miUser.work//this.props.search, 
      };
      //console.log(data);
      fetch('https://inupline.com/post.php',
        {
          method: 'POST',
          body: JSON.stringify(data)
        }
      )
      .then (response => response.json())
        .then(response => {
          this.setState({
            items: response
          });
          console.log(response);
        });
    }
  
    componentDidUpdate(prevProps, prevState) {
      // Check if the suplied props is changed
      if(prevProps.search !== this.props.search) {
        // run the function with the suplied new property
        this.findtext();
      }
    }
  
    componentDidMount() {
      this.findtext();
    }
  
    seluser = (ids) => {
      //console.log(ids);
      this.props.updateIds(ids);
    }
  
    selcompany = (itm) => {
      this.props.GetCompany(itm); // передаємо в батьківський контрол атрибут
    }
  
  
    render() {
      const { items } = this.state;
      var i = 1;
        return (
          <div id="gridhistory" className="gridhistory">
              <ul id="grid1" className="">
                  {items.map(item => (
  
                    <li className="lisinmarker" key={item.id} id = {item.id} >
                    <div className="listempleado" id={item.id}>
                        <figure className="userlist" id={item.id} data-idc="20421">
                            <span className="list_empleo_numer">{i++}</span>
                            
                            {LoadAvatarHis(item, "list_empleo_pimg", null ,'m','c')}
                            <figcaption>
                                <span className="list_empleo_het">{item.c_name} (ID:{item.id})</span>
                            </figcaption>
                        </figure>
                    </div>
                    </li>
                  ))}
              </ul>
              <div>
                  <p className="tablinfo">All: {i-1} </p>
              </div>
          </div>
        );
    }
  }
  
   
  
   