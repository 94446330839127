import './style.css';
import '../../../css/widgsystem.css';
//import './style.css';

import React, { useState } from "react";
import { miUser, LoadAvatarHis, LogoCompany, AppCaption, SendForm, MW1close, GetData} from "../../../redux/state";

class SuserList extends React.Component{ //extending Grid
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  findtext(){
    //var ftext = document.getElementById('findtext').value;
    //this.props.search

    var data = { 
      "cmd": 'FindTextAny',
      "colum": "cname",
      "tbl": "db_company",
      'find': this.props.search, 
    };
    //console.log(data);
    fetch('https://inupline.com/post.php',
      {
        method: 'POST',
        body: JSON.stringify(data)
      }
    )
    .then (response => response.json())
      .then(response => {
        this.setState({
          items: response
        });
        console.log(response);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the suplied props is changed
    if(prevProps.search !== this.props.search) {
      // run the function with the suplied new property
      this.findtext();
    }
  }

  componentDidMount() {
    this.findtext();
  }

  seluser = (ids) => {
    //console.log(ids);
    this.props.updateIds(ids);
  }

  selcompany = (itm) => {
    this.props.GetCompany(itm); // передаємо в батьківський контрол атрибут
  }


  render() {
    const { items } = this.state;
    var i = 1;
      return (
        <div>
            <ul id="grid1">
                {items.map(item => (
                    <li className="listmaket" key={item.id} id = {item.id} onClick={() => this.selcompany(item)}>
                    <div className="listempleado" id={item.id}>
                        <figure className="userlist" id={item.id} data-idc="20421">
                            <span className="list_empleo_numer">{i++}</span>
                            
                            {LogoCompany(item, "list_empleo_pimg", null ,'m', '')}                      
                            <figcaption>
                                <span className="list_empleo_het">{item.cname}</span>
                                <p className="list_empleo_hez"><i className="fa fa-info-circle" aria-hidden="true">
                                    </i>&nbsp;&nbsp;&nbsp;{item.cdomicilio}</p>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="editbtn">
                    <div className="btn_setings"><div className="fa fa-check">&nbsp;</div></div>
                    <div className="btn_setings"><div className="fa fa-info"  aria-hidden="true">&nbsp;</div></div>
                    </div>
                    </li>
                ))}

       

            </ul>
            <div>
                <p className="tablinfo">All: {i-1} </p>
            </div>
        </div>
      );
  }
}

export default class IndexUserHome extends React.Component{

  constructor() {
    super()
    this.state = {
        compamyids : [], // sel user id  
        sel_id: '',
        sel_name: '',
        sel_pin: '',
        sel_comp: [],
        ftext: '',
        st_dbname: 'db_subcontratos'
      };
      this.updateIds = this.updateIds.bind(this)
    }
    
  updatecaption(){
    //tcapt ('USER EDITOR');
    //AppCaption('COMPANYS');

    //myUsers.callUser();
  }

  useropenid(){
    //console.log('work');
  }

  updateIds = (ids) => {
    this.setState({compamyids: ids})
    //MW1info(ids); // show modal

  }

  componentDidMount() {
    //this.updatecaption();
  }

  GetCompany = (obj) => {
    this.setState({sel_id: obj.id,
    sel_name: obj.cname,
    sel_pin: obj.cpin,
    sel_comp: obj })

    console.log('sel_comp',this.state.sel_comp)

  }

  FindText = () => {
    var ftext = document.getElementById('findtext').value;
    this.setState({ftext: ftext})
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.FindText();
    }
  }

  SaveCheng (id) {
    const { newold } = this.props;
    const { st_dbname } = this.state;

    var myform  = document.getElementById('FormSunCont'); 
    var form = new FormData(myform);

    form.append("sc_name", this.state.sel_comp.cname);
    form.append("sc_note", 'xxxx');
    form.append("corp_id", miUser.work);
    form.append("id_contrato", this.state.sel_comp.id);
    form.append("sc_data", GetData('d'));
    form.append("id_user", miUser.id);

    
    console.log(...form);

    //this.SendForm('formElem','UpdateTable','id',st_eqipo.id)}}
    SendForm(newold, st_dbname,'FormSunCont','UpdateTable','id',id)

    this.props.redrawall();
}


  render() {
    const { compamyids } = this.state;
    return (
      <div className = "saback-box">
          
          <div className = "btntools">
            <input className="findsuser" id = "findtext" type="text" name="userName" placeholder="Enter company name..." onKeyDown={this.handleKeyDown}/>
            <button className="btnboxmenu sulite" id="bfind"  onClick={() => {this.FindText()}} > <i className="fa fa-search" aria-hidden="true"></i></button>
          </div>
          <div className="FindListSubcontr">
            <SuserList updateIds = {this.updateIds} GetCompany = {this.GetCompany.bind(this)} search = {this.state.ftext}/>
            
            <span className = "txtinfo"><i className="fa fa-info-circle" aria-hidden="true"></i> * Para Contratar elijate una empresa de la lista (si no está en la lista utiliza buscador)</span>
            <br/>
            <form id="FormSunCont">
              <input id="sc_name" style={{display : 'block'}} value={this.state.sel_comp.cname}/>
              <input id="sc_note" style={{display : 'block'}} value={""}/>
              <input id="corp_id" style={{display : 'none'}} value={miUser.work}/>
              <input id="id_contrato" style={{display : 'none'}} value={this.state.sel_comp.id}/>
              <input id="sc_data" style={{display : 'none'}} value={ GetData('d')}/>
              <input id="id_user" style={{display : 'none'}} value={miUser.id}/>

              
              {this.props.newold}
            </form>
          </div>


          <div className="downmodal">
            <button className="btnboxmenu" onClick={() => {this.SaveCheng('new')}}>Contratar</button>
            <button className="btnboxmenu" onClick={() => {MW1close('WM002')}} >CLOSE</button>
          </div>
        </div>
    );
  }
}    


