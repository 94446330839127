import './../style.css';
import '../../../css/widgsystem.css';

import React from "react";

export default class CntrSelect extends React.Component {
    constructor(props){
      super(props);
      this.state = { value : this.props.value };
      this.onChange = this.onChange.bind(this);
    }
    
    onChange(event){
      this.setState({value : event.target.value})
    }
  
    componentDidUpdate(prevProps) {
      // Типове використання (не забудьте порівняти пропси):
      if (this.props.value !== prevProps.value) {
        this.setState({value : this.props.value})
      }
    }
  
    render(){
    return (
      <div className={this.props.classCSS}>
          <label className="lbllist">
            <span className="textlabel">{this.props.caption}</span>
            <select multiple={false} className="frmlistbox" id = {this.props.cid} name={'tb_' + this.props.cid} value={this.state.value} onChange={this.onChange} placeholder={this.props.placeholder} {...this.props}>
                {this.props.children}
            </select>
          </label>
          </div>
      );
    }
  } 