import './../style.css';
import '../../../css/widgsystem.css';

import React, { useEffect } from "react";
import PropTypes from 'prop-types'
import {UIlengID} from '../../../App'; // Потрібно файл де буде завантажуватися всі переміні
import { myUsers, miUser,AppCaption} from "../../../redux/state";

function IndexUserHome() {

  function updatecaption(){
    //tcapt (UIlengID[24] ? UIlengID[24] : 'my profile');
    //AppCaption(UIlengID[24] ? UIlengID[24] : 'my profile')
    AppCaption('',UIlengID[24] ? UIlengID[24] : 'my profile','myperfil','');

    myUsers.callUser();

    //console.log(myUsers);
    //console.log(miUser);
    // не стирати тест отримання даниз з функції іншої бібліотеки !!!
    //myTimer.increaseTimer(); 
    //console.log(myTimer);
    //#################################################################################
  }

useEffect(() => {updatecaption()}, []); 

const MW2Class = (id) => {
  const oldClassName = document.getElementById('WM001').className;
  const newClassName = oldClassName === 'WM001open' ? 'WM001hide' : 'WM001open'
  document.getElementById('WM001').className=newClassName;
}

function MW1info (id) {
  if (id !== 'undefined' && id !== 'null'){ 
    const oldClassName = document.getElementById(id).className;
    const newClassName = oldClassName === 'WM001open' ? 'WM001hide' : 'WM001open'
    document.getElementById(id).className=newClassName;
    //console.log('MW1info = ' + id);
  }
}


function SwitchCheck(props) {
  return (
  <div className="box__heading">
    <div className="box__label">{props.clabel}</div>
      <label className="switch">
        <input type="checkbox" onClick={props.onClick} name="cv_public" id="chcvp" />
        <span className="slider round"></span>
      </label>
  </div> 
  )
}

function InfoLabel(props) {
  return (
    <div className="info">
      {props.clabel ? <h4 className="infotext"><i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp; {props.clabel}</h4> : ""}
    </div>
  )
}

function ModalWindows(props) {
  return (
    <div className="WM001hide" id="WM001">
      <div className="ModalWidg">
        <img src="https://chart.googleapis.com/chart?cht=qr&chl=https://inupline.com/?cvid=$users_id&chs=120x120&chld=L|0" className='my-code' alt="my-code"/>
        
      </div>

      <InfoLabel clabel = {props.infolabel}/>
      <div className="downmodal">
        <button className="btnboxmenu"  onClick={MW2Class}>CLOSE</button>
      </div>

      <div className="ctrl_link">
        <button className="red_key"><i className="fa fa-info-circle" aria-hidden="true"></i></button>
        <button className="red_key" onClick={MW2Class}><i className="fa fa-window-close" aria-hidden="true"></i></button>
      </div>

    </div>
  )
}

function Submission(event) {
  event.preventDefault();
}


function WidgetsBox(props) {
  const { title = 'title', infolabel = 'infolabel', psh = 's1x' , psw = 'h1x' } = props;
  
  return (
    <div className= {'back-box ' + psh + ' ' + psw + ' thema'}>
    <div className="title_box"><div className="title">{title}</div></div>
        
    <div id="usercv">
  <form className="frmcv" id="frmcv" onSubmit = {Submission}>
    <div className="set_link">
        <button className="set_key" onClick={MW2Class}><i className="fa fa-bar-chart" aria-hidden="true"></i></button>
        <button className="set_key" onClick={() => {MW2Class('WM001')}}><i className="fa fa-qrcode" aria-hidden="true"></i></button>
        <button className="set_key"><i className="fa fa-eye" aria-hidden="true"></i></button>
        <button className="set_key"><i className="fa fa-floppy-o" aria-hidden="true"></i></button>
    </div>   

      <div className="url_link">
        
        {props.children}
      </div>  
      
      
      <InfoLabel clabel = {props.infolabel}/>

      {/*<SwitchCheck clabel = 'Publico' />*/}

  </form>
</div>

    </div>
 )

}

  return (
    <div className = 'fonback-box'>
      <div className="cvback-box hxx" style={{backgroundImage:'url(https://inupline.com/ssd/cv/bf_90.jpg)'}}>
        <div className="sh"></div>
        <div className="iconsh"></div>
            <div className="icon" id="AvatarPreview" form="userform" style={{backgroundImage:'url(' + miUser.efoto + ')'}}>
              <input className="iconAva" type="file" id="AvatarUpload" form="userform" name="image" accept=".png, .jpg, .jpeg" />
            </div>

            <div className="fname">{miUser.fname} {miUser.lname}</div>
            <div className="lname"><i className="fa fa-briefcase" aria-hidden="true"></i>&nbsp;{miUser.efoto}</div>

            <div className="panel">
              <button type="button" className="btnboxmenu btn-themap">
                <i className="fa fa-picture-o" aria-hidden="true">
                  <input className="iconFon" type='file' id="FonBackUpload" form="userform" name="imagefon" accept=".png, .jpg, .jpeg" />
                </i>
              </button>
                <button type="button" className="btnboxmenu btn-themap"><i className="fa fa-eye" aria-hidden="true"></i></button>
              </div>

              <div className="url_link">
              <button className="red_key" onClick={MW2Class}><i className="fa fa-qrcode" aria-hidden="true"></i></button>
              <button className="red_key" ><i className="fa fa-facebook-official" aria-hidden="true"></i></button>
              <button className="red_key"><i className="fa fa-linkedin-square" aria-hidden="true"></i></button>
              <button className="red_key"><i className="fa fa-whatsapp" aria-hidden="true"></i></button>
              </div>  

              <img src="https://chart.googleapis.com/chart?cht=qr&chl=https://inupline.com/?cvid=$users_id&chs=120x120&chld=L|0" className='qr-code' alt="qr-code"/>

              <div className="Qrstatus">
              <div className="">Time IN: 00.00.00</div>
              <div className="">Date IN: 00.00.0000</div>
              <div className="">Register: 00.00.0000</div>
              </div>

              <WidgetsBox title='CURRICULUM' infolabel='My INFo'>
                  Sign Me Up!
              </WidgetsBox>

              <WidgetsBox title='HISTORY' infolabel='CV (0)'/>
              <WidgetsBox title='TRABAJO'/>
              <WidgetsBox title='ABOUT ME'/>
              <WidgetsBox title='PERSONAL' psh = 's2x' psw = 'h2x'/>

              <ModalWindows infolabel = 'QR code' />
      </div>
    </div>
    
  );
}
/*
WidgetsBox.defaultProps = {
  title: "title",
  infolabel: "infolabel",
  sizeh: "s2x",
  sizew: "h2x"
}*/


export default IndexUserHome;
