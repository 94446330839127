import './style.css';
import Cookies from 'universal-cookie';
import ImgLogo from '../../img/miniU.png';  //sadmin.png
import ImgSadmin from '../../img/sadmin.png';  //sadmin.png

import { Link, useNavigate } from "react-router-dom";

import CMenuButton from './CMenuButton';
//import AppMenuBox from './AppMenuBox';
import UserMenuBox from './UserMenuBox';

import React, { useState, useEffect } from 'react';
import { myUsers, LoadAvatar,  SetHistory} from "../../redux/state";

function AppBar(props) {
    const cookies = new Cookies();
    const navigate = useNavigate();

    const [isFullScreen, setFullScreen] = useState(true);
    const [isMenubtm, setMenubtm] = useState(false);


    function keyclick() {
      SetHistory('1');
      cookies.remove('token', { path: '/'});
      cookies.remove('fname', { path: '/'});
      cookies.remove('lname', { path: '/'});
      cookies.remove('userid', { path: '/'});
      cookies.remove('userava', { path: '/'});
      console.log('clear cookies');
      props.userout('1');

      navigate("/");
    }

    function HideMenu() {
      setMenubtm(true);
      toggleClass();
      //toggle1Class();
      //toggle2Class();
    }

    function page2click(eva) {
      setMenubtm(true);
    }

      const toggleClass = () => {
        const oldClassName = document.getElementById('Menu00').className;
        const newClassName = oldClassName === 'menuopen' ? 'menuhide' : 'menuopen'
        document.getElementById('Menu00').className=newClassName;
      }

      const toggle1Class = () => {
        const oldClassName = document.getElementById('Menu01').className;
        const newClassName = oldClassName === 'menu2open' ? 'menu2hide' : 'menu2open'
        document.getElementById('Menu01').className=newClassName;
        
        const MenuUserInfo = document.getElementById('Menu02').className;
        const newMenuUserInfo = MenuUserInfo === 'menu2open' ? 'menu2hide' : 'menu2hide'
        document.getElementById('Menu02').className=newMenuUserInfo;
      }

      const toggle2Class = () => {
        const oldClassName = document.getElementById('Menu02').className;
        const newClassName = oldClassName === 'menu2open' ? 'menu2hide' : 'menu2open'
        document.getElementById('Menu02').className=newClassName;

        if (props.user.role === "3") {
          const MenuUserInfo = document.getElementById("Menu01").className;
          const newMenuUserInfo = MenuUserInfo === "menu2open" ? "menu2hide" : "menu2hide"
          document.getElementById("Menu01").className=newMenuUserInfo;
        }
      }

      useEffect(() => {
        myUsers.callUser();
      }, []);

    ///  Fullscreen ////////////////////////// 79.116.184.252 2a0c:5a82:3301:fc00:1c34:ba7a:8d82:5edb 86.127.231.87
    function FullScreenView (bool) {
      if (bool === true) { 
        var elem = document.documentElement;
          if (elem.requestFullscreen) {
              elem.requestFullscreen();
              setFullScreen(false);
          } else if (elem.webkitRequestFullscreen) { /* Safari */
              elem.webkitRequestFullscreen();
          } else if (elem.msRequestFullscreen) { /* IE11 */
              elem.msRequestFullscreen();
          } 
      } else { 
        if (document.exitFullscreen) {
          document.exitFullscreen();
          setFullScreen(true);
        } else if (document.webkitExitFullscreen) { 
          document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { 
          document.msExitFullscreen();
      } 
      };
    }
    
      //########## ZoNA SUPER ADMIN #######################################################################

      function ButtSadmin() {
        if (props.user.role === "3") {
          return <img className="sadmin" onClick={toggle1Class} src={ImgSadmin} alt={props.user.id}></img>;
        } else {
          return "";
        }
      }
      
      function ButtSadminMenu() {
        if (props.user.role === "3") {
          return <div className="menu2hide" id="Menu01">
            <div className = 'SadminMenuBox'>
                  <Link as={Link} to="/auser"> 
                    <button className="btnboxmenu" onClick={(e) => { page2click();}}>{'USERS'}</button>
                  </Link>
                  <Link as={Link} to="/aerror"> 
                    <button className="btnboxmenu" onClick={(e) => { page2click();}}>{'ERROR'}</button>
                  </Link>

                  <Link as={Link} to="/ahistory"> 
                    <button className="btnboxmenu" onClick={(e) => { page2click();}}>{'HISTORY'}</button>
                  </Link>

                  <Link as={Link} to="/achart"> 
                    <button className="btnboxmenu" onClick={(e) => { page2click();}}>{'CHAT'}</button>
                  </Link>

                  <Link as={Link} to="/awebhost"> 
                    <button className="btnboxmenu" onClick={(e) => { page2click();}}>{'Web ghost'}</button>
                  </Link>

                  <Link as={Link} to="/acompany"> 
                    <button className="btnboxmenu" onClick={(e) => { page2click();}}>{'COMPANYS'}</button>
                  </Link>

            </div>
      </div>;
        } else {
          return "";
        }
      }

      function GoBack(event) {
        event.preventDefault()

        var div1 = document.getElementById("cpt_app_a");
        var dataurl = div1.getAttribute("data-url");

        var div3 = document.getElementById("cpt_home");
        div3.removeAttribute("class")

        navigate(dataurl, { replace: true });
        //return dataurl
      }

      function GoBack1(event) {
        event.preventDefault()

        var div1 = document.getElementById("cpt_app_b");
        var dataurl = div1.getAttribute("data-url");

        var div3 = document.getElementById("cpt_home");
        div3.removeAttribute("class")

        navigate(dataurl, { replace: true });
        //return dataurl
      }

      function GoHome(event) {
        event.preventDefault()

        var div1 = document.getElementById("cpt_home");
        var dataurl = div1.getAttribute("data-url");
        div1.setAttribute("class", 'active'); 
        var div2 = document.getElementById("cpt_app_a");
        var div3 = document.getElementById("cpt_app_b");

        div2.removeAttribute("class")
        div3.removeAttribute("class")

        navigate(dataurl, { replace: true });
        //return dataurl
      }
      
    return (
        <div className="appbar">
            <CMenuButton MenuShow = {toggleClass} clos = {isMenubtm}/>
            {LoadAvatar(props.user,"appava",toggle2Class,'m')}
            <img className="applogo" src={ImgLogo} alt="logo" onClick = {() => FullScreenView(isFullScreen)}></img>
            {/*<p className="applogotext" id = "cpt_app">{caption}</p>*/}

            <ul className="applogotext breadcrumb breadcrumb--classic breadcrumb--classic-chevron">
            <li id = "cpt_home" data-url = '/'>
                <a id = "cpt_home_a" href="" onClick={GoHome}><i className="fa fa-home"></i></a>
            </li>
            <li id = "cpt_app_a" data-url = '/'> 
              <a id = "cpt_app" href="" onClick={GoBack}></a>
            </li>
            <li id = "cpt_app_b">
              <a id = "cpt_app_1" href="" onClick={GoBack1}></a>
            </li>
            
          </ul>

            <ButtSadmin />

            <div className="menuhide" id="Menu00">
                <div className = 'AppMenuBox'>
                    {/*<AppMenuBox />*/}
                    <div className = 'AppMenuBtm'>
                        <Link as={Link} to="/"> 
                          <button className="btnboxmenu" onClick={(e) => { HideMenu();}}>HOME</button>
                        </Link> 

                        <Link as={Link} to="/job"> 
                          <button className="btnboxmenu" onClick={(e) => { HideMenu();}}>My JOB</button>
                        </Link> 

                        <Link as={Link} to="/jornada"> 
                          <button className="btnboxmenu" onClick={(e) => { HideMenu();}}>JORNADA</button>
                        </Link> 

                        <Link as={Link} to="/partes"> 
                          <button className="btnboxmenu" onClick={(e) => { HideMenu();}}>PARTES</button>
                        </Link> 

                        <Link as={Link} to="/micompany"> 
                          <button className="btnboxmenu" onClick={(e) => { HideMenu();}}>My Company</button>
                        </Link> 

                        <Link as={Link} to="/companys"> 
                          <button className="btnboxmenu" onClick={(e) => { HideMenu();}}>Presupuestos</button>
                        </Link> 

                        <Link as={Link} to="/myhistory"> 
                          <button className="btnboxmenu" onClick={(e) => { HideMenu();}}>Página web</button>
                        </Link> 
                    </div>
                </div>
            </div>

            <ButtSadminMenu />

            <div className="menu2hide" id="Menu02">
                <div className = 'UserMenuBox'>
                  <UserMenuBox user = {props.user} work = {props.work}/>
                    <div className = 'UserMenuBtm'>

                        <Link as={Link} to="/myperfil"> 
                          <button className="btnboxmenu">{props.pal.id24 ? props.pal.id24 : 'my profile'}</button>
                        </Link> 

                        <Link as={Link} to="/myoptions"> 
                          <button className="btnboxmenu">{props.pal.id25 ? props.pal.id25 : 'setings'}</button>
                        </Link> 

                        <Link as={Link} to="/myhistory"> 
                          <button className="btnboxmenu">{props.pal.id26 ? props.pal.id26 : 'history'}</button>
                        </Link> 

                        <Link as={Link} to="/firstrun"> 
                          <button className="btnboxmenu">First Run</button>
                        </Link> 

                        <Link as={Link} to="/mychat"> 
                          <button className="btnboxmenu">CHAT</button>
                        </Link> 

                        <button className="btnboxmenu" id="um_ext" onClick={keyclick}>{props.pal.id27 ? props.pal.id27 : 'Salir'}</button>


                        
                    </div>
                </div>
            </div>
        </div>
    );
  }

  
export default AppBar;