import '../style.css';
import React , { useEffect }  from 'react';

import { Link, useNavigate } from "react-router-dom";


function Page404(props) {

    const navigate = useNavigate();

    useEffect(() => {
        // Parallax Code
        //var scene = document.getElementById('scene');
        //var parallax = new Parallax(scene);
     }, [null]); 
   
    const handleBack = () => {
        navigate("/")
    }

    return (
        <div className="wrapper-box"> 
        <section className="wrapper">

        <div className="container">

            <div id="scene" className="scene" data-hover-only="false">


                <div className="circle" data-depth="1.2"></div>

                <div className="one" data-depth="0.9">
                    <div className="content">
                        <span className="piece"></span>
                        <span className="piece"></span>
                        <span className="piece"></span>
                    </div>
                </div>

                <div className="two" data-depth="0.60">
                    <div className="content">
                        <span className="piece"></span>
                        <span className="piece"></span>
                        <span className="piece"></span>
                    </div>
                </div>

                <div className="three" data-depth="0.40">
                    <div className="content">
                        <span className="piece"></span>
                        <span className="piece"></span>
                        <span className="piece"></span>
                    </div>
                </div>

                <p className="p404" data-depth="0.50">404</p>
                <p className="p404" data-depth="0.10">404</p>

            </div>

            <div className="text">
                <article>
                    <p>Uh oh! Looks like you got lost. Go back to the homepage!</p>
                    <button onClick={handleBack}>HOME PAGE!</button>

                </article>
            </div>

        </div>
        </section>
        </div>
    );
}
  
export default Page404;