import React, { useState } from 'react';
import ImgLogo from '../img/miniU.png';

const styles = {
    logog: {
        position: 'absolute',
        margin: '30px',
        width: '40px',
        height: 'auto',
        top: '-10px',
      }
}

function Logo_btm(props) {
     
    
    var elem = document.documentElement;

    const [isFullScreen, setFullScreen] = useState(true);

///  Fullscreen //////////////////////////
function openFullscreen() {
    if (elem.requestFullscreen) {
        elem.requestFullscreen();
        setFullScreen(false);
    } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
    }
      console.log('openFullscreen ' + isFullScreen);
    }

    function closeFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
            setFullScreen(true);
          } else if (document.webkitExitFullscreen) { 
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { 
            document.msExitFullscreen();
        }
        console.log('closeFullscreen ' + isFullScreen);
    }
    
    return (
        <img style={styles.logog} src={ImgLogo} alt="logo" onClick={isFullScreen ? openFullscreen : closeFullscreen}></img>
    );
}
  
export default Logo_btm;