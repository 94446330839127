import { LoadAvatar } from "../../redux/state";

export default function UserCard(props) {

  //console.log(corp)
  return (
    <div className = 'app_user_card'>
        {LoadAvatar(props.user, "userc_ava", null ,'m')}
        <p className="userc_name">{props.user.fname + " " + props.user.lname}</p>
        <p className="userc_status">{props.work.cname}</p>

      <div className = 'app_user_rank'>
        <p className="userc_rank">0</p>
        <p className="userc_rank_h1">anuncios</p>
        <p className="userc_rank">0</p>
        <p className="userc_rank_h2">views</p>
        <p className="userc_rank">0</p>
        <p className="userc_rank_h3">likes</p>

      </div>
    </div>
  );
}