// import { async } from '@firebase/util';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';

export const GetWork = createAsyncThunk("work/GetWork", async () => {
  //const cookies = new Cookies();

  return await fetch("https://inupline.com/post.php", {
    method: 'POST',
    header : {
        Accept: 'application/json', 'Content-Type': 'application/json',
    },
    credentials: "include", // This here
    body : JSON.stringify({
        "cmd": 'LoadCompanyInfo',
        "tbl": 'db_company',
        "id_work": 3
    })
    }).then((res)=> res.json());
});

export const LoginCompany = createSlice ({
  name: 'work',
  initialState : { 
    work: [],
    loadingW: false,
  },
  reducers: {},
  extraReducers: (builder) => { 
    builder
    .addCase (GetWork.pending, (state) => { state.loadingW = false; })
    .addCase (GetWork.fulfilled, (state, { payload }) => { state.loadingW = true; state.work = payload})
    .addCase (GetWork.rejected, (state) => { state.loadingW = false })
    },
  }
)

  //export const {} = LoginCompany.actions;

  //export const CS_actions = counterSlice.actions;
  export default LoginCompany;