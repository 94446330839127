import './../style.css';
import '../../../css/widgsystem.css';
//import '../style.css';

import React, { useState } from "react";
import { miUser, LoadAvatarHis, LogoCompany, AppCaption, MW1info, ModalInfo} from "../../../redux/state";

function  PinSave(ide) {
  var data = { 
    "cmd": 'UpdateTable',
    "user_id": miUser.id,
    "tbl": "users",
    'work': ide, 
  };

  fetch('https://inupline.com/post.php',
    {
      method: 'POST',
      body: JSON.stringify(data)
    }
  )
    .then (response => {
        console.log(response);
        })

    .then((result) => {
        console.log('Success:', result);
        alert("save data");
        MW1info("WM002");
    })
    .catch((error) => {
        console.error('Error:', error);
    });
}

class SuserList extends React.Component{ //extending Grid
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  findtext(){
    //var ftext = document.getElementById('findtext').value;
    //this.props.search

    var data = { 
      "cmd": 'FindTextAny',
      "colum": "cname",
      "tbl": "db_company",
      'find': this.props.search, 
    };
    //console.log(data);
    fetch('https://inupline.com/post.php',
      {
        method: 'POST',
        body: JSON.stringify(data)
      }
    )
    .then (response => response.json())
      .then(response => {
        this.setState({
          items: response
        });
        console.log(response);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the suplied props is changed
    if(prevProps.search !== this.props.search) {
      // run the function with the suplied new property
      this.findtext();
    }
  }

  componentDidMount() {
    this.findtext();
  }

  seluser = (ids) => {
    //console.log(ids);
    this.props.updateIds(ids);
  }

  selcompany = (itm) => {
    this.props.GetCompany(itm); // передаємо в батьківський контрол атрибут
  }


  render() {
    const { items } = this.state;
    var i = 1;
      return (
        <div id="gridhistory" className="gridhistory">
          <div className="master">
              <div className="container">
                
              {items.map(item => (
                <div className="card" href="" key={item.id} id = {item.id} onClick={() => this.selcompany(item)}>
                <div className="card__background">{LogoCompany(item, "saava", null ,'m')}</div>
                <div className="card__shadow"> </div>
                <div className="card__info">
                  <div className="card__name">{item.cname} </div>
                  <div className="card__about"><span className="age">{i++} </span> <span className="country"></span></div>
                  <div className="card__description">ID {item.id} </div>
                </div>
                <div className="card__follow" onClick={() => this.seluser(item)}><span>ADD</span></div></div>
            ))}
              </div>
            </div>

            {/* <ul id="grid1" className="">
                {items.map(item => (

                <li className="lisinmarker" key={item.id} id = {item.id} onClick={() => this.selcompany(item)}>
                    <div className="listelement" id={item.id}>
                        <figure className="userlist" id={item.id} data-idc="20421">
                            <span className="list_comp_numer">{i++}</span>
                            
                            {LogoCompany(item, "list_comp_pimg", null ,'m')}
                            <figcaption>
                                <span className="list_comp_het">{item.cname}</span>
                                <p className="list_comp_hez"><i className="fa fa-info-circle" aria-hidden="true">
                                    </i>&nbsp;&nbsp;&nbsp;{item.cdomicilio}</p>
                            </figcaption>
                        </figure>
                    </div>
                </li>
                ))}
            </ul> */}
            <div>
                <p className="tablinfo">All: {i-1} </p>
            </div>
        </div>
      );
  }
}

    //#################################################################################
    const MW2pin = (pin) => {
      var euser_id = document.getElementById('pincomp').value;
      console.log('PIN = ' + euser_id);

      if (pin === euser_id){ 
        document.getElementById('pinpage').className='page_hide';
        document.getElementById('pinpage1').className='page_show';
        document.getElementById('pinerror1').textContent  = "PinCode correcto";

      } else {
        document.getElementById('pinerror').textContent  = "PinCode incorrecto !";

        
        //document.getElementById('pinpage').className='page_hide';
        //document.getElementById('pinpage1').className='page_show';
      }
    }
    

  

  const ConmPinPage = (id) => {

    if (id !== "0" && id !== ""){ 
      console.log('ConmPinPage = ' + id);
      document.getElementById('pinpage').className='page_show';
      document.getElementById('pinpage1').className='page_hide';
    } else if( id !== "") {
      document.getElementById('pinpage').className='page_hide';
      document.getElementById('pinpage1').className='page_show';
    }
  }



export default class IndexUserHome extends React.Component{

  constructor() {
    super()
    this.state = {
        compamyids : [], // sel user id  
        sel_id: '',
        sel_name: '',
        sel_pin: '',
        sel_comp: [],
        ftext: ''
      };
      this.updateIds = this.updateIds.bind(this)
    }
    
  updatecaption(){
    //tcapt ('USER EDITOR');
    AppCaption('','COMPANYS','companys','');

    //myUsers.callUser();
  }

  useropenid(){
    //console.log('work');
  }

  updateIds = (ids) => {
    this.setState({compamyids: ids})
    MW1info(ids); // show modal
  }

  componentDidMount() {
    this.updatecaption();
  }

  GetCompany = (obj) => {
    this.setState({sel_id: obj.id,
    sel_name: obj.cname,
    sel_pin: obj.cpin,
    sel_comp: obj }, () =>  {MW1info("WM002");})
  }

  FindText = () => {
    var ftext = document.getElementById('findtext').value;
    this.setState({ftext: ftext})
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.FindText();
      console.log(event.key)
    }
  }

  PinKeyDown = (event) => {
    if (event.key === 'Enter') {
      MW2pin(this.state.sel_pin);
    }
  }

  render() {
    const { compamyids } = this.state;
    return (
      <div className = "saback-box">
          
            <div className = "btntools">
              <input className="findsuser" id = "findtext" type="text" name="userName" placeholder="Enter company name..." onKeyDown={this.handleKeyDown}/>
              <button className="btnboxmenu sulite" id="bfind"  onClick={() => {this.FindText()}} > <i className="fa fa-search" aria-hidden="true"></i></button>
            </div>

            <div id = "listusr">
              <SuserList updateIds = {this.updateIds} GetCompany = {this.GetCompany.bind(this)} search = {this.state.ftext}/>
              
              <span className = "txtinfo"><i className="fa fa-info-circle" aria-hidden="true"></i> * Para Incorporación elijate una empresa de la lista (si no está en la lista utiliza buscador)</span>
            </div>

          <ModalInfo id = "WM002" titles = {this.state.sel_name} userid = {this.state.sel_id}>
              <div className = "selcompany-box" >
                {ConmPinPage(this.state.sel_pin)}
                {LogoCompany(this.state.sel_comp, "txticonc", null ,'')}

                <div className = "page_hide" id = "pinpage"> 

                  <div className="comp_content">
                    <span className = "txtempr">{this.state.sel_name}</span>
                    <input className="comp_pin" type="text" name="userName" placeholder="PIN" id = "pincomp" onKeyDown={this.PinKeyDown}/>
                    <span className = "txtinfo"><i className="fa fa-info-circle" aria-hidden="true"></i> Algunas empresas tiene único PIN para incorporación </span>
                    <br/><br/><br/>
                    <span className = "txtinfo" id= "pinerror"></span>

                  </div>

                  <div className="savemodal">
                  <button className="btnboxmenu" onClick={() => {MW2pin(this.state.sel_pin)}} >NEXT</button>
                </div>
                
                </div>

                <div className = "page_hide" id = "pinpage1"> 

                  <div className="comp_content">
                    <span className = "txtempr">Bienvenido en</span><br/>
                    <span className = "txtempr">{this.state.sel_name}</span><br/>
                    <span className = "txtinfo"><i className="fa fa-info-circle" aria-hidden="true"></i> Para guardar elegida empresa pulsa next </span>
                    <br/><br/><br/>
                    <span className = "txtinfo" id= "pinerror1"></span>
                  </div>

                  <div className="savemodal">
                  <button className="btnboxmenu" onClick={() => {PinSave(this.state.sel_id)}} >SAVE</button>
                </div>
                </div>
              </div>
          </ModalInfo>
        </div>
    );
  }
}    


