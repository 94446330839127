import './../style.css';
import '../../../css/widgsystem.css';

import React from "react";

var VarSemDay = 1
let hor = 0;
let hor2 = 0;
let hoe = 0;
let hoe2 = 0;
let hof = 0;
let hof2 = 0;
let hol = 0;
let hol2 = 0;

export default class WdgCalendar extends React.Component{ //extending Grid
    constructor(props) {
      super(props);
      this.SelDay = this.SelDay.bind(this);
    }

    SelDay = (iday,isem) => {
      this.props.selectD(Number(iday)); // передаємо в батьківський контрол атрибут
      this.props.selectS(Number(isem)); // передаємо в батьківський контрол атрибут
      //console.log("WdgCalendar ids " , iday, isem )
    }

    //horas
    SemDay(item, cday){
      var semid = 1

      item.map((itmlugar => {
        if (Number(itmlugar.day) === cday) {
          semid = itmlugar.semana
        }
      }));

      return semid;
    }

    ShowDay(item, cday, key){
      
      let print_m =  [];

      let print_w_1 = <TWeek key = {key + 'ws1'} week="S1"/>;

      let a_day = '';
      let a_client = '';
      let a_lugar = '';
      let a_horas = '';
      let a_firm = '';

      let s_l = <DeyJC id = {key + 'sl'} key = {key + 'sl'} week = '' day = '' lugar='' horas = '' colors = '0'/>;
      let s_m = <DeyJC id = {key + 'sm'} key = {key + 'sm'} week = '' day = '' lugar='' horas = '' colors = '0'/>;
      let s_x = <DeyJC id = {key + 'sx'} key = {key + 'sx'} week = '' day = '' lugar='' horas = '' colors = '0'/>;
      let s_j = <DeyJC id = {key + 'sj'} key = {key + 'sj'} week = '' day = '' lugar='' horas = '' colors = '0'/>;
      let s_v = <DeyJC id = {key + 'sv'} key = {key + 'sv'} week = '' day = '' lugar='' horas = '' colors = '0'/>;
      let s_s = <DeyJC id = {key + 'ss'} key = {key + 'ss'} week = '' day = '' lugar='' horas = '' colors = '0'/>;
      
      let colL = '0'; // колір заливки по замовчуваню
      let s = 1; // Перше число 
        
        this.props.horas.map((itmlugar => {
          if (Number(item.day) === Number(itmlugar.c_dia) )  {
            a_day = itmlugar.c_dia;
            a_client = itmlugar.id_client;
            a_lugar = itmlugar.id_lugar;
            a_horas = itmlugar.c_horas;
            a_firm= itmlugar.id_user_firm;
          }
        }));

        if (Number(item.t) === 1) { colL = '0' }
        if (Number(item.l) === 1) { colL = '0' }
        if (Number(item.f) === 1) { colL = '2' }
        if (Number(item.p) === 1) { colL = '3' }
        if (Number(item.v) === 2) { colL = '4' }
        if (item.week === "s" || item.week === "d") { colL = '1' }

        // переміщення першого числа у відповідний день тижня
        if (item.week === "l" && Number(item.day) === s) { 
          print_m = [print_w_1, <DeyJC key = {key} SelDay = {this.SelDay} cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar='' horas = {a_horas} colors = {colL} status= {a_firm}/>]
        }

        if (item.week === "m" && Number(item.day) === s) { 
          print_m = [print_w_1,s_l,<DeyJC key = {key} SelDay = {this.SelDay} cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar='' horas = {a_horas} colors = {colL} status= {a_firm}/> ] 
        }

        if (item.week === "x" && Number(item.day) === s) { 
          print_m = [print_w_1,s_l,s_m,<DeyJC key = {key} SelDay = {this.SelDay} cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar='' horas = {a_horas} colors = {colL} status= {a_firm}/> ] 
        }

        if (item.week === "j" && Number(item.day) === s) { 
          print_m = [print_w_1,s_l,s_m,s_x,<DeyJC key = {key} SelDay = {this.SelDay} cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar='' horas = {a_horas} colors = {colL} status= {a_firm}/> ] 
        }

        if (item.week === "v" && Number(item.day) === s) { 
          print_m = [print_w_1,s_l,s_m,s_x,s_j,<DeyJC key = {key} SelDay = {this.SelDay} cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar='' horas = {a_horas} colors = {colL} status= {a_firm}/> ]
        }

        if (item.week === "s" && Number(item.day) === s) { 
          print_m = [print_w_1,s_l,s_m,s_x,s_j,s_v,<DeyJC key = {key} SelDay = {this.SelDay} cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar='' horas = {a_horas} colors = {colL} status= {a_firm}/> ] 
        }

        if (item.week === "d" && Number(item.day) === s) { 
          print_m = [print_w_1,s_l,s_m,s_x,s_j,s_v,s_s,<DeyJC key = {key} SelDay = {this.SelDay} cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar='' horas = {a_horas} colors = {colL} status= {a_firm}/> ]  
        }
        // розміщення інших чисел зразу після першого
        
        if (Number(item.day) > s ) { 
          if (item.week === "l" && Number(item.day) < 10  ) {  
            print_m = [<TWeek key = {key + 'ws2'} week={"S2"}/>, <DeyJC key = {key} SelDay = {this.SelDay} cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar='' horas = {a_horas} colors = {colL} status= {a_firm}/>]
            //iw++;
            //this.setState({ iw: this.state.iw++ });
          } else if (item.week === "l" && Number(item.day) < 16 ) {  
            print_m = [<TWeek key = {key + 'ws3'} week={"S3"}/>, <DeyJC key = {key} SelDay = {this.SelDay} cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar='' horas = {a_horas} colors = {colL} status= {a_firm}/>]
            //iw++;
            //this.setState({ iw: this.state.iw++ });
          } else if (item.week === "l" && Number(item.day) < 23 ) {  
            print_m = [<TWeek key = {key + 'ws4'} week={"S4"}/>, <DeyJC key = {key} SelDay = {this.SelDay} cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar='' horas = {a_horas} colors = {colL} status= {a_firm}/>]
          } else if (item.week === "l" && Number(item.day) < 30 ) {  
            print_m = [<TWeek key = {key + 'ws5'} week={"S5"}/>, <DeyJC key = {key} SelDay = {this.SelDay} cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar='' horas = {a_horas} colors = {colL} status= {a_firm}/>]
          } else if (item.week === "l" && Number(item.day) < 32 ) {  
            print_m = [<TWeek key = {key + 'ws6'} week={"S6"}/>, <DeyJC key = {key} SelDay = {this.SelDay} cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar='' horas = {a_horas} colors = {colL} status= {a_firm}/>]
          } else {
            print_m = [<DeyJC key = {key} SelDay = {this.SelDay} cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar='' horas = {a_horas} colors = {colL} status= {a_firm}/>]
          }
        }  

      return print_m
      // моніторин даних
      // передача даних

      
    }
  
    componentDidUpdate(prevProps) {
      // Типове використання (не забудьте порівняти пропси):
      if (this.props.Allhoras !== prevProps.Allhoras) {
        this.props.Allhoras(Number(hol),Number(hoe),Number(hof));
      }

    }

    componentDidUpdate(){
        const { cday, citems } = this.props;
        const SemanaSel = this.SemDay(citems, cday);
        if (VarSemDay !== SemanaSel) {
          VarSemDay =SemanaSel;
          this.props.selectS(SemanaSel); // передаємо в батьківський контрол атрибут
          //console.log("selssssssssssssssssssem " , SemanaSel );
        }

        if (hor2 !== hor || hol2 !== hol || hoe2 !== hoe || hof2 !== hof) {
          hor2 = hor;
          hol2 = hol;
          hoe2 = hoe;
          hof2 = hof;
          this.props.Allhoras(Number(hol),Number(hoe),Number(hof));
          //console.log("selssssssssssssssssssem " , SemanaSel );
        }
    }

    funcalhoras(wday) {
      this.props.horas.map((itmlugar => {
        if (Number(wday.day) === Number(itmlugar.c_dia) )  {
          hor = (Number(hor) + Number(itmlugar.c_horas)); //semana
        }
      }));

      

      return hor;
    }

    fastrrender() {
      const { cday, citems, info } = this.props;
      let drawweek = [];
      hor = 0;
      for (var i = 0, len = citems.length; i < len; i++) {
        if(citems[i]){
          //console.log('citems ', i , citems[i])
          drawweek.push ( this.ShowDay(citems[i], cday, "CAL" + citems[i].day + i));  
          this.funcalhoras(citems[i])
        }
      }

      if (info !== undefined ){
        if (hor !== 0){
          if (hor > (Number(this.props.info.laboral)* 8)){ 
            hol = Number(this.props.info.laboral) * 8
          } else {
            hol = hor
          }
  
          if (hor > (Number(this.props.info.laboral)* 8)){ hoe = hor - Number(this.props.info.laboral) * 8 } else {hoe = 0}
          hof = (Number(this.props.info.laboral) + Number(this.props.info.puentes)) * 8 - hor
          
          console.log(hor , hol , hoe, hof)
        } else {
          hol = 0
          hoe = 0
          hof = (Number(this.props.info.laboral) + Number(this.props.info.puentes)) * 8 - hor
          
          //console.log(hor , hol , hoe, hof)
        }
      }
      
      

      return drawweek;

    }

    render() {
      const { cday, citems, info } = this.props;
      {/*const listItems = this.ShowDay(citems, cday);*/}

        return (
          <div className="JorCalendar" id = 'JorCalendar'>
            <div className="CalendarMes">
                <div className="SpaceCaption"></div>
                <div className="DayCaption">L</div>
                <div className="DayCaption">M</div>
                <div className="DayCaption">X</div>
                <div className="DayCaption">J</div>
                <div className="DayCaption">V</div>
                <div className="DayCaption">S</div>
                <div className="DayCaption">D</div>
                  {
                    this.fastrrender()
                  }
            </div>
            {info !== undefined ? <div><div className="ClPbox colTr"><i className="ClPCapt">Laborales =  {info.laboral}</i></div>
              <div className="ClPbox colFS"><i className="ClPCapt">No Laborales =  {info.nolaboral}</i></div>
              <div className="ClPbox colFi"><i className="ClPCapt">Festivos =  {info.festivos}</i></div>
              <div className="ClPbox colPu"><i className="ClPCapt">Puentes =  {info.puentes}</i></div><br />
              <div className="ClPbox "><i className="ClPCapt">{'Total: ' + hor + ' h'}</i></div></div>
            : ''}
              

          </div>
        );
    }
  }

  class TWeek extends React.Component{
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <div className="WeekCaption">{this.props.week}</div>
      )
    }
  }

  //function DeyJC(props) {
    class DeyJC extends React.Component{ //extending Grid
        constructor(props) {
          super(props);
          this.seluser = this.seluser.bind(this);
        }
    
        seluser = (event) => {

          var iday = event.target.id
          var isem = event.target.dataset['sem']
          
          if (iday && isem) {
            this.props.SelDay(iday,isem); // передаємо в батьківський контрол атрибут
          }
          
          console.log("ids " , iday, isem )
        }

        //cday

        SelectDay = (wday) => {
          let retrn ="";
          if (wday.day === wday.cday) { retrn =" DaySelect"; } else { retrn =this.StyleColor(this.props) + " DayLJC"; }
          
          return retrn;
        }

        StyleColor = (props) => {
          if (props.colors === "0") { return "colTr"; }
          if (props.colors === "1") { return "colFS"; }
          if (props.colors === "2") { return "colFi"; }
          if (props.colors === "3") { return "colPu"; }
          if (props.colors === "4") { return "colVa"; }
          if (props.colors === "5") { return "colfirmado"; }
        }
    
        StyleStatus = (a_firm, wday) => {
          let retrn ="";
          if (wday.day === '') { retrn =""; }
          if (wday.day !== '' && a_firm === '') { retrn =<i className="fa fa-calendar-times-o colFail" aria-hidden="true"></i>; } // Редаговано і збережено та не підписано
          if (a_firm === '0') { retrn =<i className="fa fa-calendar-check-o colWait" aria-hidden="true"></i>; } // Редаговано і збережено та не підписано
          if (a_firm > 0) { retrn =<i className="fa fa-check colSave" aria-hidden="true"></i>; } // Редаговано і збережено та не підписано
    
          return retrn;
        }
        //pointer-events: none; //disable clicks
        render() {
          return (
            <div className={this.SelectDay(this.props) } key = {"MHD" + this.props.day + this.props.semana} id = {this.props.day} data-sem = {this.props.semana} onClick={this.seluser}>
              <p className="CaptionDayJC">{this.props.week}</p>
              <p className="DateDayJC">{this.props.day}</p>
              <p className="LugarDayJC">{this.props.lugar}</p>
              <p className="HorasDayJC">{this.props.horas}</p>
              <div className="calendstatus">{this.StyleStatus(this.props.status, this.props.day)}</div>
            </div>
          )
        }
      }

