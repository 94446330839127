import '../style.css';
import Cookies from 'universal-cookie';
//import DatePicker from "react-datepicker";
import React, { useState, useEffect } from "react";
//import "react-datepicker/dist/react-datepicker.css";
//import ImgLogo from '../../img/miniU.png';
//import Select from 'react-select';
import Resizer from "react-image-file-resizer";
import PropTypes from 'prop-types'


function Firstrun({tcapt, finish}) {
    // FIRST RUN
    const cookies = new Cookies();
    //const fname = cookies.get('fname')
    //const lname = cookies.get('lname') 

    const [urlfoto, setUrlFoto] = useState(cookies.get('userava')); // foto default
    const [startDate, setStartDate] = useState(new Date());

    const [upimg, setupimg] = useState("");
    const [image, setImage] = useState("");
    const [convimage, setConvImage] = useState("");
    const [convimagem, setConvImageMini] = useState("");

    
    // LIST //////////////////////////////
    const LngList = [
        { value: 'gb', label: 'English' },
        { value: 'es', label: 'Español' },
        { value: 'ua', label: 'Українська' }
    ]

    const PaisList = [
        { value: 'esp', label: 'España' },
        { value: 'ucr', label: 'Ukraine' },
    ]

    //////////////////////////////

    function useDisplayImage() {
        const [result, setResult] = useState("");

        function uploader(e) {
            const imageFile = e.target.files[0];
            const cimage = resizeFile(imageFile);
            const cimageM = resizeFileMini(imageFile);
            setConvImage(cimage);
            //console.log(cimageM);
            setupimg('1');
            console.log("add foto");
            const reader = new FileReader();
            reader.addEventListener("load", (e) => {
                setResult(e.target.result);
            });

            reader.readAsDataURL(imageFile);
            //posttext(imageFile);

        }
        return { result, uploader };
        
    }

    const { result, uploader } = useDisplayImage();

    const resizeFile = (file) => new Promise(resolve => {
        Resizer.imageFileResizer(file, 300, 300, 'JPEG', 100, 0,
        uri => {
          resolve(uri);
          setConvImage(uri);
          console.log(uri);
        }, 'base64' );
    });

    const resizeFileMini = (file) => new Promise(resolve => {
        Resizer.imageFileResizer(file, 64, 64, 'JPEG', 100, 0,
        uri => {
          resolve(uri);
          setConvImageMini(uri);
          console.log(uri);
        }, 'base64' );
    });



    function inupava(){
        var uid = cookies.get('userid');
        var uri = "https://inupline.com/ssd/" + uid + ".jpg" ;
        setConvImage(uri);
        setConvImageMini(uri);
        console.log(uri);
    }

    function SendFile() {
        const formData = new FormData();
        formData.append("cmd", "savefile" );
        formData.append("user_id", cookies.get('userid'));
        
        //
        if (upimg === "1"){
            formData.append("file",  convimage );
            //formData.append("size", convimage.size);
            formData.append("filem",  convimagem );
            console.log("Con foto")
        } else {
            console.log("Sin foto")
        }

        formData.append("first_run", "1" );
        formData.append("foto", "1" );

        formData.append("tbl", "users" );
        
        /*
        var cidiama = document.getElementById('frmIdioma').textContent;
        var cpais = document.getElementById('frmPais').textContent;
        var ccode = document.getElementById('frmCode').value;
        var cbdate = document.getElementById('frmBDate').value;
        //var ctel = document.getElementById('frmTel').value;
        //var ctel2 = document.getElementById('frmTel2').value;
        var crch0 = document.getElementById('styled-checkbox-0').checked;
        //var crch1 = document.getElementById('styled-checkbox-1').checked;
        //var crch2 = document.getElementById('styled-checkbox-2').checked;
        //var crch3 = document.getElementById('styled-checkbox-3').checked;
        //var crch4 = document.getElementById('styled-checkbox-4').checked;

/*
        console.log("cidiama " + cidiama);
        console.log("cpais " + cpais);
        console.log("ccode " + ccode);
        console.log("cbdate " + cbdate);
        //console.log("ctel " + ctel);
        //console.log("ctel2 " + ctel2);
        console.log("chk redava " + crch0);
        //console.log("chk CV " + crch1);
        //console.log("chk tel " + crch2);
        //console.log("chk email " + crch3);
        //console.log("chk autoin " + crch4);

        //updatecaption();
        //tcapt('1');
*/
		fetch(
			'https://inupline.com/savefile.php',
			{
				method: 'POST',
				body: formData,
			}
		)
        .then (response => {
            console.log(response);
            })

        .then((result) => {
            console.log('Success:', result);
        })
        .catch((error) => {
            console.error('Error:', error);
        });

        finish('finish');
    }

    function updatecaption(){
        tcapt('Bienvenido');
    }

    useEffect(() => {
        updatecaption();
        inupava();
        // eslint-disable-next-line
     }, []); // AUTOLOAD - завантажує користувача із кешу

     const style_btn = {
        color: '#141414',
        fontWeight: 'bold',
        width: "calc(100% - 30px)"
      };

      const style_lbl = {
        color: 'white',
        fontSize: 36
      };

    return (
        <div>
            <div className="appcard">
            
                <div className = "bcard">
                    <p style={style_lbl}>Este es tu primera entrada</p> 
                    <p>Necesitamos más información</p> 
                    <p>Para personalizar tu cuenta</p> 
                </div>

                <div className = "bcard">
                   <p>De momento tienes que elegir su foto</p> 

                    {/* <label htmlFor="frmIdioma" >Idioma (de interfase de la app)</label> */}
                    {/*<Select className = "textcampo" options={LngList} defaultValue = {LngList[2]} id = "frmIdioma"/>*/}

                    {/* <label htmlFor="frmPais" >Pais (de donde vas usar)</label> */}
                    {/*<Select className = "textcampo" options={PaisList} defaultValue = {PaisList[0]} id = "frmPais"/>*/}

                    {/* <label htmlFor="frmCode" >Codigo especial (si tienes)</label> */}
                    {/* <input type="text" id = "frmCode"/> */}

                    {/* <label htmlFor="frmBDate" >Fecha de nacimiento</label> */}
                    {/*<DatePicker dateFormat="dd/MM/yyyy" className = "textcampo" id = "frmBDate" selected={startDate} onChange={(date) => setStartDate(date)} />*/}
                    

                    <div className="imgprv">
                        <div className="avatar-upload">
                            <div className="avatar-edit">
                                <input type='file' onChange={(e) => { setImage(e.target.files[0]); uploader(e);}} id="imageUpload"  name="image" accept=".png, .jpg, .jpeg" />
                                <label htmlFor="imageUpload"></label> 
                            </div>

                            <div id="imagePreview">
                                <img className="avatar-preview" id="ava" src={convimage ? convimage : urlfoto }/>
                            </div>
                        </div>
                    </div>
                    
                    {/* <div className = 'separ'>
                        <input className="styled-checkbox" id="styled-checkbox-0" type="checkbox" name="chkmtcv" value="value1" onChange={(e) => { inupava(e);}}/>
                        <label htmlFor="styled-checkbox-0">Utilizar otro foto</label>
                    </div> */}

                    <button className="btnboxmenu" onClick={SendFile} style={style_btn}>GUARDAR</button>

                </div>

                <div className = "bcard">
                    <p>Pulsa Guardar para siguiente paso</p> 
                    <p></p> 
                </div>

            </div>
        </div>
        
    );
  }
  
  Firstrun.prototype = {
    tcapt: PropTypes.func.isRequired,
    finish: PropTypes.func.isRequired,
  }

export default Firstrun;