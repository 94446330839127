import './../style.css';
import '../../../css/widgsystem.css';
import './style.css';

import React from "react";
//import {UIlengID} from '../../../App'; // Потрібно файл де буде завантажуватися всі переміні
import { myUsers, AppCaption} from "../../../redux/state";
//import GostAva from '../../../img/guest_nano.png';  //sadmin.png
import ListTrello, { ListItm, ListElement }  from "../widgets/listtrello";
import DayBox from "../widgets/daybox";


export default class IndexUserHome extends React.Component{

    constructor() {
      super()
      this.state = {
          userids : [] // sel user id  
        };
        this.updateIds = this.updateIds.bind(this)
      }
      
    updatecaption(){
      AppCaption('ERROR EDITOR');
      myUsers.callUser();
    }
  
    useropenid(){
      //console.log('work');
    }
  
    updateIds = (ids) => {
      //MW1info(ids); // show modal
    }
  
    componentDidMount() {
      this.updatecaption();
    }
  
    render() {
      return (
        <div className = "saback-box">
            <ListTrello lwidth = "75" margin = "17">
          <ListItm name = "XXX">
            
            <DayBox></DayBox>

          </ListItm>

          <ListItm name = "asdsdf">
            
          </ListItm>

          <ListItm name = "asdsdf">
          <ListElement exmpl = '1'></ListElement>
            <ListElement exmpl = '2'></ListElement>
            <ListElement exmpl = '4'></ListElement>
            <ListElement exmpl = '1'></ListElement>
            <ListElement exmpl = '2'></ListElement>
            <ListElement exmpl = '4'></ListElement>
            <ListElement exmpl = '4'></ListElement>
            <ListElement exmpl = '4'></ListElement>
            <ListElement exmpl = '4'></ListElement>
            <ListElement exmpl = '3'></ListElement>
            <ListElement exmpl = '3'></ListElement>
          </ListItm>

          <ListItm name = "asdsdf">
            <ListElement exmpl = '1'></ListElement>
            <ListElement exmpl = '2'></ListElement>
            <ListElement exmpl = '4'></ListElement>
            <ListElement exmpl = '1'></ListElement>
            <ListElement exmpl = '2'></ListElement>
            <ListElement exmpl = '4'></ListElement>            
            <ListElement exmpl = '4'></ListElement>
            <ListElement exmpl = '3'></ListElement>
            <ListElement exmpl = '3'></ListElement>            
            <ListElement exmpl = '4'></ListElement>

          </ListItm> 
          </ListTrello> 
          </div>
      );
    }
  }    