import './../style.css';
import '../../../css/widgsystem.css';

import React from "react";
import { myUsers,MW1close, miUser, LoadAvatarHis, LoadAvatar ,SndMSG} from "../../../redux/state";

// WIDGET SYSTEMS
import WdgCalendar from '../widgets/calendar';
import WdgSemana from '../widgets/semana';
import WdgBox from '../widgets/wgbox';
import CntrSelect from '../widgets/select';
import CntrTextbox from '../widgets/textbox';

export default class IndexUserHome extends React.Component{
    constructor() {
      super()
      this.state = {
          user : [], // sel user id  7
        };
      }
      
    componentDidMount() {
        this.verifitycard()
    }

    verifitycard() {
        const { guser } = this.props;
        var i = 1;
        if (guser) {
            this.setState({user: guser })
        }
    }

    PinSave(ide) {

      var newold = this.props.newold
      var tablename = this.props.table

      if (newold === 'new') {
      var data = { 
        "cmd": 'UpdateTable',
        "user_id": ide.id,
        "tbl": "users",
        'work': 0
      };

      SndMSG('CV descartado',  ide.id)
    }

    if (newold > 0) {
      var data = { 
        "cmd": 'DelRecTable',
        "iid": newold,
        "tbl": tablename, //"db_trabajador"
      };

      SndMSG('Despedido',  ide.id)

    }

      fetch('https://inupline.com/post.php',
        {
          method: 'POST',
          body: JSON.stringify(data)
        }
      )
        .then (response => {
            console.log(response.json());
            })
    
        .then((result) => {
            console.log('Success:', result);
            //alert("save data");
            //MW1info("WM002");
        })
        .catch((error) => {
            console.error('Error:', error);
        });

        this.props.redrawall()
    }

    
    render() {
      const { user } = this.state;
      const { userid } = this.props;

      return (
        <div className = "selcompany-info" >

{this.props.newold}


          <span className = "txtinfo"><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Eliminar este info? </span>

          <div className = "txtinfo" >
            <CntrTextbox caption = 'Motivo'></CntrTextbox>
          </div>

          <div className="downmodal">
            <button className="btnboxmenu" onClick={() => {this.PinSave(userid)}} >Eliminar</button>
            <button className="btnboxmenu" onClick={() => {MW1close('WM001')}} >CLOSE</button>
          </div>

        </div>
      );
    }
  }    
