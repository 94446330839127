import './../style.css';
import '../../../css/widgsystem.css';
import './style.css';

import React from "react";
//import {UIlengID} from '../../../App'; // Потрібно файл де буде завантажуватися всі переміні
import { myUsers, LoadAvatarHis, AppCaption ,SetHistory} from "../../../redux/state";

//import GostAva from '../../../img/guest_nano.png';  //sadmin.png

export default class IndexUserHome extends React.Component{

    constructor() {
      super()
      this.state = {
          userids : [], // sel user id  7
        };
        this.updateIds = this.updateIds.bind(this);
      }
      
    updateIds = (ids) => {
      //MW1info(ids); // show modal
    }
  
    componentDidMount() {
      //AppCaption('MY JOBS');
      AppCaption('','MY JOBS','','')
      myUsers.callUser();
    }
  
    render() {
      return (
        <div className = "saback-box">
            


        </div>
      );
    }
  }    
