import { configureStore } from '@reduxjs/toolkit';

import LoginUser from "./slice/user";
import LoginCompany from "./slice/company";
import loadingTXT from "./slice/txtui";

// BASIC DE REDAX.actions
//export const gerUser = ACCOUNT_USER.actions;// CS_actions;
//export const account = ACCOUNT_CHAT.actions;

// BASIC DE REDAX.reducer
export const store = configureStore({
  reducer: {
    users: LoginUser.reducer,
    works: LoginCompany.reducer,
    userui: loadingTXT.reducer}
    // middleware: getDefaultMiddleware => getDefaultMiddleware().concat(ACCOUNT_USER)
});