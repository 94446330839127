import React , { useState, useEffect }  from 'react';
import './style.css';
//import ImgLogo from '../../img/miniU.png';  //sadmin.png

export default function JornadaPage(props) {
    const [jsonResponse,setJsonRespons] = useState([]);
    const [mesHoras,setMesHoras] = useState([]);
    const [meslugar,setMesLugar] = useState([]);
    const [mesClient,setMesClient] = useState([]);
    const [mesCompany,setMesCompany] = useState();

    const GetBaseJSON = async (datar) => {
       
      const response = await fetch('https://inupline.com/post.php',
        {
          method: 'POST',
          header : {
            Accept: 'application/json', 'Content-Type': 'application/json',
          },
          body: JSON.stringify(datar)
        }
      );
      const data = await response.json();
      console.log("calender GetBaseJSON " , data);
      return data;
    }

      useEffect(() => {
        var data = { 
          "cmd": 'ReadHorasMes',
          "tbl": 'db_horario',
          "id_user": props.user.id, 
          "c_mes": props.mes,
          "c_ano": props.ano
        };
        //console.log('data:', data);
        
        (async () => {
          const response = await GetBaseJSON(data);
          setMesHoras(response);
        })()

        console.log("props.user " , props.user.id);

      }, [props.user]); 
  
      useEffect(() => {
        var data = { 
          "cmd": 'ReadTableLugar',
          "tbl": 'db_lugar'
        };

        (async () => {
          const response = await GetBaseJSON(data);
          setMesLugar(response);
        })()
      }, [props.user]); 

      useEffect(() => {
        var data = { 
          "cmd": 'ReadTableClient',
          "id_work": props.user.work, //miUser.work,
          "tbl": 'db_client'
        };

        (async () => {
          const response = await GetBaseJSON(data);
          setMesClient(response);
        })()
      }, [props.user]); 

           
      const ShowDay = (item, cday, key) => {
      
        let print_m =  [];
  
        let print_w_1 = <TWeek key = {key + 'ws1'} week="S1"/>;
  
        let a_day = '';
        let a_client = '';
        let a_lugar = '';
        let a_horas = '';
        let id_lugar = '';
        let a_firm = '';
        let a_semana = '';
        let data = [];
  
        let s_l = <DeyJC data = {data} id = {key + 'sl'} key = {key + 'sl'} week = '' day = '' lugar='' horas = '' colors = '0'/>;
        let s_m = <DeyJC data = {data} id = {key + 'sm'} key = {key + 'sm'} week = '' day = '' lugar='' horas = '' colors = '0'/>;
        let s_x = <DeyJC data = {data} id = {key + 'sx'} key = {key + 'sx'} week = '' day = '' lugar='' horas = '' colors = '0'/>;
        let s_j = <DeyJC data = {data} id = {key + 'sj'} key = {key + 'sj'} week = '' day = '' lugar='' horas = '' colors = '0'/>;
        let s_v = <DeyJC data = {data} id = {key + 'sv'} key = {key + 'sv'} week = '' day = '' lugar='' horas = '' colors = '0'/>;
        let s_s = <DeyJC data = {data} id = {key + 'ss'} key = {key + 'ss'} week = '' day = '' lugar='' horas = '' colors = '0'/>;
        
        let colL = '0'; // колір заливки по замовчуваню
        let s = 1; // Перше число 
          
            mesHoras.map((itmlugar => {
            if (Number(item.day) === Number(itmlugar.c_dia) )  {
              a_day = itmlugar.c_dia;
              a_client = itmlugar.id_client;
              //a_lugar = itmlugar.id_lugar;
              id_lugar = itmlugar.id_lugar;
              a_horas = itmlugar.c_horas;
              a_firm= itmlugar.id_user_firm;
              data = itmlugar;
            }
          }));

          //let def = a_lugar;

          meslugar.map((itms => {
            if (Number(itms.id) === Number(id_lugar) )  {
                a_lugar = itms.c_lugar;
                //console.log(a_day, a_lugar )
            }
          }));



          if (Number(item.t) === 1) { colL = '0' }
          if (Number(item.l) === 1) { colL = '0' }
          if (Number(item.f) === 1) { colL = '2' }
          if (Number(item.p) === 1) { colL = '3' }
          if (Number(item.v) === 2) { colL = '4' }
          if (item.week === "s" || item.week === "d") { colL = '1' }
  
          // переміщення першого числа у відповідний день тижня
          if (item.week === "l" && Number(item.day) === s) { 
            print_m = [print_w_1, <DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar={a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/>]
          }
  
          if (item.week === "m" && Number(item.day) === s) { 
            print_m = [print_w_1,s_l,<DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar={a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/> ] 
          }
  
          if (item.week === "x" && Number(item.day) === s) { 
            print_m = [print_w_1,s_l,s_m,<DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar={a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/> ] 
          }
  
          if (item.week === "j" && Number(item.day) === s) { 
            print_m = [print_w_1,s_l,s_m,s_x,<DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar={a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/> ] 
          }
  
          if (item.week === "v" && Number(item.day) === s) { 
            print_m = [print_w_1,s_l,s_m,s_x,s_j,<DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar={a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/> ]
          }
  
          if (item.week === "s" && Number(item.day) === s) { 
            print_m = [print_w_1,s_l,s_m,s_x,s_j,s_v,<DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar={a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/> ] 
          }
  
          if (item.week === "d" && Number(item.day) === s) { 
            print_m = [print_w_1,s_l,s_m,s_x,s_j,s_v,s_s,<DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar={a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/> ]  
          }
          // розміщення інших чисел зразу після першого
          
          if (Number(item.day) > s ) { 
            if (item.week === "l" && Number(item.day) < 10  ) {  
              print_m = [<TWeek key = {key + 'ws2'} week={"S2"}/>, <DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar= {a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/>]
              //iw++;
              //this.setState({ iw: this.state.iw++ });
            } else if (item.week === "l" && Number(item.day) < 16 ) {  
              print_m = [<TWeek key = {key + 'ws3'} week={"S3"}/>, <DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar= {a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/>]
              //iw++;
              //this.setState({ iw: this.state.iw++ });
            } else if (item.week === "l" && Number(item.day) < 23 ) {  
              print_m = [<TWeek key = {key + 'ws4'} week={"S4"}/>, <DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar= {a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/>]
            } else if (item.week === "l" && Number(item.day) < 30 ) {  
              print_m = [<TWeek key = {key + 'ws5'} week={"S5"}/>, <DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar= {a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/>]
            } else if (item.week === "l" && Number(item.day) < 32 ) {  
              print_m = [<TWeek key = {key + 'ws6'} week={"S6"}/>, <DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar= {a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/>]
            } else {
              print_m = [<DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar= {a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/>]
            }
          }  
  
        return print_m
        // моніторин даних
        // передача даних
        
      }

      const calcday = (moon,year) => {
        let mess = moon;
        let mess2 = mess;
        
        let ano =  year;
        let ano2 = ano;
    
        if (mess === 12) { 
          mess2 = 1
          ano2 ++
        } else {
          mess2 ++
        }
    
        //const {Temporal} = temporal;
        var dateString = ano + "-" + mess + "-1"  //"2015-12-31";
        var day_start = new Date(dateString.replace(/-/g, "/"));
        var dateString2 = ano2 + "-" + mess2 + "-1"  //"2015-12-31";
        var day_end = new Date(dateString2.replace(/-/g, "/"));
    
    
        //let day_start = new Date(mess + " 1 " + ano);
        //let day_end = new Date(mess2 + " 1 " + ano2);
        let total_mes = day_start.getMonth()+1;
        let total_days = Number((day_end - day_start) / (1000 * 60 * 60 * 24)+1);
    
        let calender_items = [];
    
        let dayj = ['0','1','1','1','1','1','0']; 
        //var dayn = ['НЕДІЛЯ','ПОНЕДІЛОК','ВІВТОРОК','СЕРЕДА','ЧЕТВЕР','ПЯТНИЦЯ','СУБОТА'];   
        let dayes = ['d','l','m','x','j','v','s'];   
    
        let fie = ['mm dd yyyy','1 6 2022','12 8 2021','12 25 2021']; 
        let puent = ['mm dd yyyy','1 7 2022','12 23 2021','12 24 2021','12 31 2021']; 
    
        let laboral = 0;
        let nolaboral = 0;
        let festivos = 0;
        let puentes = 0;
    
        let fiday = 0;
        let puday = 0;
    
        //console.log("total_mes " + total_mes);
    
        let week =1;
          for (let i = 1; i < total_days; i++) {
            var dateString3 = ano + "-" + mess + "-" + i;  //"2015-12-31";
            let today = new Date(dateString3.replace(/-/g, "/"));
            let dd   = today.getDate();
            let day  = dayj[today.getDay()];
            let dayjn  = dayes[today.getDay()];
    
            if (day === "1") { laboral ++;} else {nolaboral ++}
    
            for (let f = 0; f < fie.length ; f++) {
              //let day_fiestas = new Date(fie[f]);
              let day_fiestas = new Date(fie[f].replace(/-/g, "/"));
    
              if (today.getTime() === day_fiestas.getTime()) { 
                festivos ++; 
                fiday = 1;
              }
            } 
    
            for (let f = 0; f < puent.length; f++) {
              let day_puente = new Date(puent[f]);
              if (today.getTime() === day_puente.getTime()) { 
                puentes ++;
                puday = 1;
              }
            } 
    
            calender_items.push({'day': dd, 'week': dayjn, 'mes':total_mes, 'l':day, 'f':fiday, 'p':puday ,'semana' : week,'t' : 0}) 
            
            if (dayjn === "d") { week ++;} // розмічаємо тижні
            puday = 0;
            fiday = 0;
    
        }
        return calender_items;
      }

    const fastrrender = () => {
        const cday = new Date().getDate();

        let cmes = props.mes //new Date().getMonth() ; 
        let cano = props.ano //new Date().getFullYear();

        let cldata = calcday(Number(cmes),Number(cano));

        let drawweek = [];

        for (var i = 0, len = cldata.length; i < len; i++) {
            if(cldata[i]){
                if(cldata[i].week !== 'd' & cldata[i].week !== 's' ){ drawweek.push ( ShowDay(cldata[i], cday, "CAL" + cldata[i].day + i));  }
            }
          }

        return drawweek;
  
      }

  return (
    <div className="container-landscape">
      <p>asdsdfsdf</p>
        {/* <img className="partlogo" src={"https://inupline.com/ssd/c"  + props.cid + ".jpg?" + props.cname }  alt="Sin Logo"/> */}
        {/*<img className="partlogo" src={ImgLogo}  alt="Sin Logo"/>*/}

        <div className="catempresaname"> {props.cname} </div>
        <div className="cattb01"> PERSONAL ASIGNADO </div>
        <div className="cattb02"> Nº OBRA </div>
        <div className="cattb03"> _  </div>

        <div className="cattb04"> CLIENTE </div>
        <div className="cattb05"> {props.client} </div>

        <div className="cattb06"> TRABAJADOR </div>
        <div className="cattb07"> {props.user.fname}  {props.user.lname}</div>

        <div className="caltitle"> CONTROL  JORNADA  LABORAL</div>
        <div className="caltimpo"> {props.mes} / {props.ano} </div>

        
            <div className="CalendarBox">
                <div className="SpaceCap"></div>
                <div className="DayCap">Lunes</div>
                <div className="DayCap">Martes</div>
                <div className="DayCap">Miércoles</div>
                <div className="DayCap">Jueves</div>
                <div className="DayCap">Viernes</div>
                
                  {
                    fastrrender()
                  }
            </div>
        
        <div className="firmwareapp">2022 © inUpLine. All Rights Reserved. www.inupline.com</div>

    </div>
  );
}

class TWeek extends React.Component{
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <div className="WeekCap">{this.props.week}</div>
      )
    }
  }

  //function DeyJC(props) {
    class DeyJC extends React.Component{ //extending Grid
        constructor(props) {
          super(props);
          this.seluser = this.seluser.bind(this);
        }
    

        seluser = (event) => {

          var iday = event.target.id
          var isem = event.target.dataset['sem']
          
          if (iday && isem) {
            //this.props.SelDay(iday,isem); // передаємо в батьківський контрол атрибут
          }
          
          console.log("this.props " , this.props )
        }

       
        //pointer-events: none; //disable clicks
        render() {
          return (
            <div className={this.props.day ? "DayBox" : "DayBoxFREE"}  key = {"MHD" + this.props.day + this.props.semana} id = {this.props.day} data-sem = {this.props.semana} onClick={this.seluser}>
              <p className={this.props.day ? "DateDayBox" : ""}>{ this.props.day}</p>
              
              <p className={this.props.day ? "cattimeall" : ""}>{this.props.horas && Number(this.props.horas) !== 0 ? this.props.data.c_desde_1 + '-' + this.props.data.c_hasta_1 : "" }</p>

              <p className={this.props.horas ? "CapHoras" : ""}>{this.props.horas ? "Horas: " + this.props.horas : ""}</p>
              <p className="CapLugar">{this.props.horas ? this.props.lugar : this.props.lugar}  {/*this.props.lugar*/}</p>
            </div>
          )
        }
      }

