import './../style.css';
import '../../../css/widgsystem.css';
import React from "react";

export default function SwitchCheck(props) {
    return (
    <div className="box__heading">
      <div className="box__label">{props.clabel}</div>
        <label className="switch">
          <input type="checkbox" {...props}  />
          <span className="slider round"></span>
        </label>
    </div> 
    )
  }