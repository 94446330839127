// import './../style.css';
// import '../../../css/widgsystem.css';
// import './style.css';
// import './user_list.css';

import Modal from "../../Modal/modal";
import BestList from '../../SuperListBox/SuperListBox';
import Resizer from "react-image-file-resizer";

import React, { useRef, useState, useEffect } from "react";
//import {UIlengID} from '../../../App'; // Потрібно файл де буде завантажуватися всі переміні
import { myUsers, LoadAvatar, AppCaption, notifyok, notifyerror } from "../../../redux/state";
import GostAva from '../../../img/guest_nano.png';  //sadmin.png

export default class IndexUserHome extends React.Component{

  constructor() {
    super()
    this.state = {
        userids : [], // sel user id  
        showmdl : false,
        value: [],
      };
      this.updateIds = this.updateIds.bind(this)
      this.showmodal = this.showmodal.bind(this)
    }

    showmodal = (ids) => {
      this.setState({showmdl: ids})
      //!ids ? this.setState({showmdl: true}) : this.setState({showmdl: false});
      //console.log("SHOW" , ids)
   }
    
  updatecaption(){
    //tcapt ('USER EDITOR');
    //AppCaption('USER EDITOR');
    AppCaption('','USERS','auser','');
    myUsers.callUser();
  }

  useropenid(){
    //console.log('work');
  }

  updateIds = (ids) => {
    this.setState({userids: ids})
    this.showmodal(true)
  }

  componentDidMount() {
    this.updatecaption();
    this.loaddata();
  }

  loaddata(){
    var data = { 
      "cmd": 'FindTextAny',
      "colum": "fname",
      "tbl": "users",
      'find': "", 
      // "rootcmd": "userlist"
    };

    fetch("https://inupline.com/post.php", {
      method: 'POST',
      header : {
        Accept: 'application/json', 'Content-Type': 'application/json',
      },
      body : JSON.stringify(data)
    })
    .then (response => response.json())
    .then(response => {
      this.setState({value: response})}
    )
      .catch((error) => {
        console.error('Error:', error);
    });
  }

  render() {
    const { userids, value } = this.state;
    return (
      <div className = "saback-box">
          <div id = "listusr">
            <BestList updateIds = {this.updateIds} value={value}/>
          </div>

          <Modal show = {this.state.showmdl} userid = {userids.id} showmodal = {this.showmodal} caption = {userids.fname + ' ' + userids.lname  + " (" + userids.id  + ")"} >
            <div className = "cont_ava">
              <AvatarCtrl usrs = {userids.id}/>
            </div>

            <div className = "conteiners">
              <div className = "conteiner1">

                  <InSelBox cid = "usrrole" caption = "role:" value = {userids.role} placeholder="usrrole">
                      <option value="0">ghost</option>
                      <option value="1">user</option>
                      <option value="2">client</option>
                      <option value="3">root</option>
                      <option value="4">admin</option>
                      <option value="5">moderator</option>
                      <option value="6">autonomo</option>
                      <option value="7">boss</option>
                      <option value="8">pro</option>
                      <option value="9">vip</option>
                  </InSelBox>

                  <InSelBox cid = "ui_leng" caption = "ui_leng:" value = {userids.ui_leng} placeholder="ui_leng">
                    <option value="0">English</option>
                    <option value="1">Українська</option>
                    <option value="2">Español</option>
                  </InSelBox>
                
                  <InSelBox cid = "ui_mode" caption = "ui_mode:" value = {userids.ui_mode} placeholder="ui_mode">
                    <option value="0">Dashbord (admin)</option>
                    <option value="1">Shop (Taller)</option>
                    <option value="2">ShopClient (info)</option>
                    <option value="3">Almacen</option>
                    <option value="4">Logistica (transporte)</option>
                    <option value="5">Architector</option>
                    <option value="6">Trabajador</option>
                    <option value="7">New modo</option>
                  </InSelBox>

                  <InSelBox cid = "ui_tema" caption = "ui_tema:" value = {userids.ui_tema} placeholder="ui_tema">
                    <option value="0">Dark Green</option>
                    <option value="1">Dark Naranja</option>
                    <option value="2">Dark Teja</option>
                    <option value="3">Dark FIOL</option>
                  </InSelBox>

                  <InSelBox cid = "first_run" caption = "First_run:" value = {userids.first_run} placeholder="First_run">
                    <option value="0">False</option>
                    <option value="1">True</option>
                  </InSelBox>

              </div>

              <div className = "conteiner2">
                <InTextBox cid = "userfname" caption = "First Name:" value = {userids.fname} placeholder="First Name" />
                <InTextBox cid = "userlname" caption = "Last Name:" value = {userids.lname} placeholder="Last Name" />
                <InTextBox cid = "usernacionalidad" caption = "Nacionalidad:" value = {userids.nacionalidad} placeholder="nacionalidad" />
                <InTextBox cid = "userbdate" caption = "bdate:" value = {userids.bdate} placeholder="bdate" />
                <InTextBox cid = "userrdad" caption = "rdad:" value = {userids.rdad} placeholder="rdad" />
                <InTextBox cid = "usernif" caption = "nif:" value = {userids.nif} placeholder="nif" />
                <InTextBox cid = "userphone" caption = "phone:" value = {userids.phone} placeholder="phone" />
                <InTextBox cid = "usercvabout" caption = "cv_about:" value = {userids.cv_about} placeholder="cv_about" />
                <InTextBox cid = "useremail" caption = "email:" value = {userids.email} placeholder="email" />

              </div>
            </div>
          </Modal>

          {/* <ModalInfo titles = {userids.fname + ' ' + userids.lname  + " (" + userids.id  + ")"} userid = {userids.id} onChange={this.handleClick}>
            
          </ModalInfo> */}

          
        </div>
    );
  }
}    

class InSelBox extends React.Component {
  constructor(props){
    super(props);
    this.state = { value : '' };
    this.updateInput = this.updateInput.bind(this);
  }
  
  updateInput(event){
    this.setState({value : event.target.value})
  }

  componentDidUpdate(prevProps) {
    // Типове використання (не забудьте порівняти пропси):
    if (this.props.value !== prevProps.value) {
      this.setState({value : this.props.value})
    }
  }

  render(){
  return (
        <label className="lbllist">
          <span className="textlabel">{this.props.caption}</span>
          <select className="frmlistbox" id = {this.props.cid} name={'tb_' + this.props.cid} value={this.state.value} onChange={this.updateInput} placeholder={this.props.placeholder}>
              {this.props.children}
          </select>
        </label>

    );
  }
} 

class InTextBox extends React.Component {
  constructor(props){
    super(props);
    this.state = { value : '' };
    this.updateInput = this.updateInput.bind(this);
  }
  
  updateInput(event){
    this.setState({value : event.target.value})
  }

  componentDidUpdate(prevProps) {
    // Типове використання (не забудьте порівняти пропси):
    if (this.props.value !== prevProps.value) {
      this.setState({value : this.props.value})
    }
  }

  render(){
  return (
        <label className="lbllist">
          <span className="textlabel">{this.props.caption}</span>
          <input className="frmtextbox" type="text" id = {this.props.cid} name={'tb_' + this.props.cid} value={this.state.value} onChange={this.updateInput} placeholder={this.props.placeholder}/>
        </label>
    );
  }
} 

function AvatarCtrl(props) { 
  let ava2 = GostAva;

  if (props.usrs === undefined) {
    ava2 = GostAva;
  } else {
    ava2 = "https://inupline.com/ssd/" + props.usrs + ".jpg?" + Date();
  }

  const [upimg, setupimg] = useState('');
  const [image, setImage] = useState('');
  const [convimage, setConvImage] = useState('');
  const [convimagem, setConvImageMini] = useState('');
  //const [urlfoto, setUrlFoto] = useState(GostAva); // foto default

  //console.log("https://inupline.com/ssd/" + props.usrs + ".jpg");
  

  function useDisplayImage() {
    const [result, setResult] = useState("");

    function uploader(e) {
        const imageFile = e.target.files[0];
        const cimage = resizeFile(imageFile);
        const cimageM = resizeFileMini(imageFile);
        setConvImage(cimage);
        setConvImageMini(cimageM);
        setupimg('1');
        console.log("add foto");
        const reader = new FileReader();
        reader.addEventListener("load", (e) => {
            setResult(e.target.result);
        });

        reader.readAsDataURL(imageFile);

    }
    return { result, uploader };
    
}

  const { result, uploader } = useDisplayImage();

  const resizeFile = (file) => new Promise(resolve => {
      Resizer.imageFileResizer(file, 300, 300, 'JPEG', 100, 0,
      uri => {
        resolve(uri);
        setConvImage(uri);
        console.log(uri);
      }, 'base64' );
  });

  const resizeFileMini = (file) => new Promise(resolve => {
      Resizer.imageFileResizer(file, 64, 64, 'JPEG', 100, 0,
      uri => {
        resolve(uri);
        setConvImageMini(uri);
        console.log(uri);
      }, 'base64' );
  });

  function reserts() {
      ava2=GostAva;
      setConvImage(GostAva);
  }

  function volvers() {
    ava2="https://inupline.com/ssd/" + props.usrs + ".jpg";
    //ava2=GostAva;
    setConvImage('');
}

  return (
  <div className="imgprv">
      <div className="avatar-upload">
          <div className="avatar-edit">
              <input type='file' onChange={(e) => { setImage(e.target.files[0]); uploader(e);}} id="imageUpload"  name="image" accept=".png, .jpg, .jpeg" />
              <label htmlFor="imageUpload"></label> 
          </div>

          <div className="avatar-reset" onClick={() => { reserts()}}>
              <label></label> 
          </div>

          <div className="avatar-volver" onClick={() => { volvers()}}>
              <label></label> 
          </div>

          <div className="avatar-save" onClick={() => { UpdateFile(props.usrs,'1',convimage,convimagem)}}>
              <label></label> 
          </div>

          <div id="imagePreview">
              <label htmlFor="ava" ></label>
              <img className="avatar-preview" id="ava" src={convimage ? convimage : ava2 }/>
          </div>
      </div>
  </div>
  )
}

function UpdateFile(usrid,upimg,convimage,convimagem) {
  const formData = new FormData();
  formData.append("cmd", "savefile" );
  formData.append("user_id", usrid);
  
  //
  if (upimg === "1"){
      formData.append("file",  convimage );
      //formData.append("size", convimage.size);
      formData.append("filem",  convimagem );
      console.log("Con foto")
  } else {
      //console.log("Sin foto")
  }

  formData.append("foto", "1" );
  formData.append("tbl", "users" );
 
fetch(
'https://inupline.com/savefile.php',
{
  method: 'POST',
  body: formData,
}
)
  .then (response => {
      console.log(response);
      })

  .then((result) => {
      notifyok("Foto actualizado");
      console.log('Success:', result);
  })
  .catch((error) => {
      console.error('Error:', error);
  });

  //finish('finish');
}



