import React , { useState, useEffect }  from 'react';
import './style.css';
import ImgLogo from '../../img/miniU.png';  //sadmin.png
import { screen } from '@testing-library/react';

export default function JornadaPage(props) {
    const [jsonResponse,setJsonRespons] = useState([]);
    const [mesHoras,setMesHoras] = useState([]);
    const [meslugar,setMesLugar] = useState([]);
    const [mesClient,setMesClient] = useState([]);
    const [mesCompany,setMesCompany] = useState();
    const [timeSemana,setTimeSemana] = useState([{'l': 55, "m" : 555}]);
    const [timeLugar,setTimeLugar] = useState([{'l': 'BARAJAS', "m" : ''}]);
    const [timeNota,setTimeNota] = useState([{'l': '', "m" : 'sd'}]);

    const [timePeriod,setTimePeriod] = useState([{'l': 1, "d" : 7}]);


    const GETJSON = (data) => {
        fetch(
          'https://inupline.com/post.php',
          {
            method: 'POST',
            header : {
              Accept: 'application/json', 'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          }
        )
          .then (response => response.json())
          .then (response => {
              //console.log('LoadHorasMes:', response);

              setMesHoras(response);
          })
          //console.log("calender GETJSON JSON.stringify " , mesHoras);
      }

      const SetInfo = (s) => {
        //sem
        let ArrHoras = [];
        let ArrLugares = [];
        let ArrNotas = [];
        let semana = calcday(0, props.mes,props.ano, s);

        //let SemHoras = [];

        let SemL = '';
        let SemM = '';
        let SemX = '';
        let SemJ = '';
        let SemV = '';
        let SemS = '';
        let SemD = '';

        let LugL = '';
        let LugM = '';
        let LugX = '';
        let LugJ = '';
        let LugV = '';
        let LugS = '';
        let LugD = '';

        let NotL = '';
        let NotM = '';
        let NotX = '';
        let NotJ = '';
        let NotV = '';
        let NotS = '';
        let NotD = '';

        let Per = [];
        let FechaPeriod = [];

        mesHoras.map((item => {
          for (let f = 0; f < semana.length; f++) {
            if (semana[f].day === Number(item.c_dia)) { 
              //ArrHoras.push ({[semana[f].week] : item.c_horas });
              let lugar = LugarNameID(item.id_lugar)
              if (semana[f].week === 'l') { SemL = item.c_horas; LugL = lugar; NotL = item.nota; FechaPeriod.push (Number(item.c_dia))}
              if (semana[f].week === 'm') { SemM = item.c_horas; LugM = lugar; NotM = item.nota; FechaPeriod.push (Number(item.c_dia)) }
              if (semana[f].week === 'x') { SemX = item.c_horas; LugX = lugar; NotX = item.nota; FechaPeriod.push (Number(item.c_dia)) }
              if (semana[f].week === 'j') { SemJ = item.c_horas; LugJ = lugar; NotJ = item.nota; FechaPeriod.push (Number(item.c_dia)) }
              if (semana[f].week === 'v') { SemV = item.c_horas; LugV = lugar; NotV = item.nota; FechaPeriod.push (Number(item.c_dia)) }
              if (semana[f].week === 's') { if (item.c_horas > 0) { SemS = item.c_horas; LugS = lugar; NotS = item.nota; FechaPeriod.push (Number(item.c_dia)) }}
              if (semana[f].week === 'd') { if (item.c_horas > 0) { SemD = item.c_horas; LugD = lugar; NotD = item.nota; FechaPeriod.push (Number(item.c_dia)) }}
            }
            }
          }
        ));
        
        //SemHoras = [ArrHoras[0],ArrHoras[1],ArrHoras[2],ArrHoras[3],ArrHoras[4],ArrHoras[5],ArrHoras[6]] // (ArrHoras[0],ArrHoras[1],ArrHoras[2],ArrHoras[3],ArrHoras[4],ArrHoras[5],ArrHoras[6])
        let SumSeman = 0,LugSeman = '';
        SumSeman = Number(SemL) + Number(SemM) + Number(SemX) + Number(SemJ) + Number(SemV) + Number(SemS) + Number(SemD);
        
        let PerL = Math.min(...FechaPeriod)
        let PerD = Math.max(...FechaPeriod)

        //ArrLugares.push ({'s':'SAB - ' + LugS, 'd':'DOM - ' +LugD , 'sum': LugS});

        if ( LugL == LugM && LugM == LugX && LugM == LugJ && LugJ == LugV) {
          LugSeman = LugL;
          //console.log('ALL LugSeman', LugSeman)
          ArrLugares.push ({'s':'SAB - ' + LugS, 'd':'DOM - ' +LugD , 'sum': LugSeman});
        } else {
          ArrLugares.push ({'l':'LUN - ' + LugL, 'm':'MAR - ' + LugM, 'x':'MIE - ' + LugX, 'j':'JUE - ' + LugJ, 'v':'VIER - ' + LugV, 's':'SAB - ' + LugS, 'd':'DOM - ' +LugD  , 'sum': 'Diferentes lugares'});
        }
        
        ArrHoras.push ({'l':SemL, 'm':SemM, 'x':SemX, 'j':SemJ, 'v':SemV, 's':SemS, 'd':SemD , 'sum': SumSeman});
        ArrNotas.push ({'l':NotL, 'm':NotM, 'x':NotX, 'j':NotJ, 'v':NotV, 's':NotS, 'd':NotD });
        Per.push ({'l': PerL , 'd': PerD});       

        //console.log('ALL SetInfo', ArrHoras, ArrLugares, ArrNotas,Per , FechaPeriod, props.sem)

        //return alllugares
        setTimeSemana(ArrHoras)
        setTimeLugar(ArrLugares)
        setTimeNota(ArrNotas)
        setTimePeriod(Per);
      }
  
      
      const LoadHorasMes = () => {
          var data = { 
            "cmd": 'ReadHorasMes',
            "tbl": 'db_horario',
            "id_user": props.user.id,
            "c_mes": props.mes,
            "c_ano": props.ano
          };
          
          GETJSON(data);

          SetInfo(props.sem);
      }

      const LoadDataClients = () => {
        var data = { 
          "cmd": 'ReadTableClient',
          "id_work": props.user.work, //miUser.work,
          "tbl": 'db_client'
        };
  
  
        fetch(
            'https://inupline.com/post.php',
            {
              method: 'POST',
              header : {
                Accept: 'application/json', 'Content-Type': 'application/json',
              },
              body: JSON.stringify(data)
            }
          )
            .then (response => response.json())
            .then (response => {
                //console.log('ReadTableClient:', response);

                setMesClient(response);
            })


        var data = { 
          "cmd": 'ReadTableLugar',
          "id_work": props.user.work, //miUser.work,
          "tbl": 'db_lugar'
        };
  
        fetch(
            'https://inupline.com/post.php',
            {
              method: 'POST',
              header : {
                Accept: 'application/json', 'Content-Type': 'application/json',
              },
              body: JSON.stringify(data)
            }
          )
            .then (response => response.json())
            .then (response => {
                //console.log('ReadTableLugar:', response);

                setMesLugar(response);
            })
      }

      const LugarNameID = (id) => {
        let poslugar = '';
        meslugar.map((item => {
            if (Number(id) === Number(item.id)) { 
              poslugar = item.c_lugar;
            }
        }));
        return poslugar;
      }

      const calcday = (day, moon, year, semana) => {
        let mess = moon;
        let mess2 = mess;
        
        let ano =  year;
        let ano2 = ano;
    
        if (mess === 12) { 
          mess2 = 1
          ano2 ++
        } else {
          mess2 ++
        }
    
        //const {Temporal} = temporal;
        var dateString = ano + "-" + mess + "-1"  //"2015-12-31";
        var day_start = new Date(dateString.replace(/-/g, "/"));
        var dateString2 = ano2 + "-" + mess2 + "-1"  //"2015-12-31";
        var day_end = new Date(dateString2.replace(/-/g, "/"));
    
    
        //let day_start = new Date(mess + " 1 " + ano);
        //let day_end = new Date(mess2 + " 1 " + ano2);
        let total_mes = day_start.getMonth()+1;
        let total_days = Number((day_end - day_start) / (1000 * 60 * 60 * 24)+1);
    
        let calender_items = [];
    
        let dayj = ['0','1','1','1','1','1','0']; 
        //var dayn = ['НЕДІЛЯ','ПОНЕДІЛОК','ВІВТОРОК','СЕРЕДА','ЧЕТВЕР','ПЯТНИЦЯ','СУБОТА'];   
        let dayes = ['d','l','m','x','j','v','s'];   
    
        let fie = ['mm dd yyyy','1 6 2022','12 8 2021','12 25 2021']; 
        let puent = ['mm dd yyyy','1 7 2022','12 23 2021','12 24 2021','12 31 2021']; 
    
        let laboral = 0;
        let nolaboral = 0;
        let festivos = 0;
        let puentes = 0;
    
        let fiday = 0;
        let puday = 0;
    
        //console.log("total_mes " + total_mes);
    
        let week =1;
          for (let i = 1; i < total_days; i++) {
            var dateString3 = ano + "-" + mess + "-" + i;  //"2015-12-31";
            let today = new Date(dateString3.replace(/-/g, "/"));
            let dd   = today.getDate();
            let day  = dayj[today.getDay()];
            let dayjn  = dayes[today.getDay()];
    
            if (day === "1") { laboral ++;} else {nolaboral ++}
    
            for (let f = 0; f < fie.length ; f++) {
              //let day_fiestas = new Date(fie[f]);
              let day_fiestas = new Date(fie[f].replace(/-/g, "/"));
    
              if (today.getTime() === day_fiestas.getTime()) { 
                festivos ++; 
                fiday = 1;
              }
            } 
    
            for (let f = 0; f < puent.length; f++) {
              let day_puente = new Date(puent[f]);
              if (today.getTime() === day_puente.getTime()) { 
                puentes ++;
                puday = 1;
              }
            } 
    
            calender_items.push({'day': dd, 'week': dayjn, 'mes':total_mes, 'l':day, 'f':fiday, 'p':puday ,'semana' : week,'t' : 0}) 
            
            if (dayjn === "d") { week ++;} // розмічаємо тижні
            puday = 0;
            fiday = 0;
    
        } 
    
        //console.log("calender_items " , calender_items);

        let wsem = [];
        for (let f = 0; f < calender_items.length; f++) {
          if (Number(semana) === Number(calender_items[f].semana)) { 
            wsem.push({'day': calender_items[f].day, 'semana' : calender_items[f].semana, 'week' : calender_items[f].week }) 
          }
        }

        //console.log("SEMANA DAY  " , wsem, calender_items.length);

        return wsem;
      }



    useEffect(() => {
        LoadHorasMes();
        LoadDataClients();
        //console.log('this.props', props)
        //console.log('mesHoras', mesHoras)
        //console.log('mesHoras', calcday(0, props.mes,props.ano, 1))
      }, [props.user.id]); 

      useEffect(() => {
        //LoadHorasMes();
        //LoadDataClients();
        SetInfo(props.sem);

        //console.log('this.props', props)
        //console.log('mesHoras', mesHoras)
        //console.log('mesHoras', calcday(0, props.mes,props.ano, 1))
      }, [mesHoras]); 


      useEffect(() => {
        //LoadHorasMes();
        //LoadDataClients();
        SetInfo(props.sem);

        //console.log('this.props', props)
        //console.log('mesHoras', mesHoras)
        //console.log('mesHoras', calcday(0, props.mes,props.ano, 1))
      }, [meslugar]); 
// https://chart.googleapis.com/chart?cht=qr&chs=300x300&chl=serhii%20bilous%20y5488584s

  return (
    <div className="container-portrait">
        {/*<img className="partlogo" src={ImgLogo}  alt="Sin Logo"/>*/}
        {/* <img className="partlogo" src={"https://inupline.com/ssd/c"  + props.cid + ".jpg?" + props.cname }  alt="Sin Logo"/> */}

        <div className="catempresaname"> {props.cname} </div>
        <div className="catps01"> PERSONAL ASIGNADO </div>
        <div className="catps02"> Nº OBRA </div>
        <div className="catps03"> _  </div>

        <div className="catps04"> LUGAR </div>
        <div className="catps05"> {timeLugar[0].sum} </div>

        <div className="catps06"> cliente </div>
        <div className="catps07"> {props.client}</div>

        <div className="catps08"> Fecha </div>
        <div className="catps09"> {timePeriod[0].l + " - " + timePeriod[0].d + ' / ' + props.mes + ' / ' + props.ano}</div>
               
        <div className="firmwareapp">2022 © inUpLine. All Rights Reserved. www.inupline.com</div>

        <div className="table001">  
          <div className="catps10s"> Nombre </div>
          <div className="catps11s"> Lun </div>
          <div className="catps12s"> Mar </div>
          <div className="catps13s"> Mie </div>
          <div className="catps14s"> Jue </div>
          <div className="catps15s"> Vier </div>
          <div className="catps16s"> Sab </div>
          <div className="catps17s"> Dom </div>
          <div className="catps18s"> Suma </div>
        </div>

        <div className="table002">  
          <div className="catps10"> {props.user.fname}  {props.user.lname} </div>
          <div className="catps11"> {timeSemana[0].l <= 0 ? "" : timeSemana[0].l} </div>
          <div className="catps12"> {timeSemana[0].m <= 0 ? "" : timeSemana[0].m} </div>
          <div className="catps13"> {timeSemana[0].x <= 0 ? "" : timeSemana[0].x} </div>
          <div className="catps14"> {timeSemana[0].j <= 0 ? "" : timeSemana[0].j} </div>
          <div className="catps15"> {timeSemana[0].v <= 0 ? "" : timeSemana[0].v} </div>
          <div className="catps16"> {timeSemana[0].s <= 0 ? "" : timeSemana[0].s} </div>
          <div className="catps17"> {timeSemana[0].d <= 0 ? "" : timeSemana[0].d} </div>
          <div className="catps18"> {timeSemana[0].sum} </div>
        </div>

        <div className="table003">  
          <div className="catpsTI01"> informacion:</div>
          <div className="catpsTI02"> {timeSemana[0].l <= 0 ? "" : timeLugar[0].l} </div>
          <div className="catpsTI02"> {timeSemana[0].m <= 0 ? "" : timeLugar[0].m} </div>
          <div className="catpsTI02"> {timeSemana[0].x <= 0 ? "" : timeLugar[0].x} </div>
          <div className="catpsTI02"> {timeSemana[0].j <= 0 ? "" : timeLugar[0].j} </div>
          <div className="catpsTI02"> {timeSemana[0].v <= 0 ? "" : timeLugar[0].v} </div>
          <div className="catpsTI02"> {timeSemana[0].s <= 0 ? "" : timeLugar[0].s} </div>
          <div className="catpsTI02"> {timeSemana[0].d <= 0 ? "" : timeLugar[0].d} </div>
        </div>

        <div className="table004">  
          <div className="catpsTN01"> notas:</div>
          <div className="catpsTN02"> {timeNota[0].l} </div>
          <div className="catpsTN02"> {timeNota[0].m} </div>
          <div className="catpsTN02"> {timeNota[0].x} </div>
          <div className="catpsTN02"> {timeNota[0].j} </div>
          <div className="catpsTN02"> {timeNota[0].v} </div>
          <div className="catpsTN02"> {timeNota[0].s} </div>
          <div className="catpsTN02"> {timeNota[0].d} </div>
        </div>


        <div className="tableC1">  
          <div className="catpsTC01"> Conforme {props.cname} </div>
          <div className="catpsTC02"> {<img className="FirmaDigital" src={'https://chart.googleapis.com/chart?cht=qr&chs=300x300&chl=Firmado%20Digitalmente%20Por%20' + props.user.fname + '%20' + props.user.lname + '%20' + props.user.id + '%20Fecha:%20' + timePeriod[0].d + ' / ' + props.mes + ' / ' + props.ano}  alt="firma"/>} </div>
          <div className="catpsTC03"> Fecha: </div>
          <div className="catpsTC04"> {timePeriod[0].d + '/' + props.mes + '/' + props.ano} </div>

        </div>

        <div className="tableC2">  
          <div className="catpsTC01"> Conforme RESPONSABLE CLIENTE </div>
          <div className="catpsTC02"> F </div>
          <div className="catpsTC03"> Fecha: </div>


          <div className="catpsTC04"> {timePeriod[0].d + '/' + props.mes + '/' + props.ano} </div>
        </div>

    </div>
  );
}


