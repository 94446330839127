import React , { useState, useEffect }  from 'react';
import { useDispatch, connect } from 'react-redux'
import { myUsers, miUser, AppCaption, LoadAvatar ,MW1close, MW1info, ModalInfo} from "../../../redux/state";
import { ToastContainer, toast } from 'react-toastify';

import JornadaEmpleo  from './jornada_empleo';



  function HorasUser(dbhoras,uid,cid,wid) {
    let i = 0;
    //console.log('HorasUser', dbhoras,uid,cid,wid )
    var txtMes = document.getElementById('month').value;
    var txtAno = document.getElementById('year').value;

    dbhoras.map((item , index) => {
        if (item.id_user === uid && item.id_empresa === wid && item.id_client === cid && txtMes === item.c_mes && txtAno === item.c_ano) {
          i = i + Number(item.c_horas)
          //console.log('listtrclient', i)
        }
      })
      
      return Number(i);
  }


  function listtrclient(items,cid,wid) {
    let i = 0;
    //console.log('listsdd', items, guser )

    items.map((item , index) => {
        if (item.corp_id === wid && item.id_cliente === cid) {
          i++;
          //console.log('listtrclient', item.id_cliente, cid , i)
        }
      })
      
      return Number(i);
  }


  const ServGetBase = async (tbl,colum,row) => {
    //const [loadData,setLoadData] = useState();

    let datar = { 
      "cmd": 'FindText',
      "colum": colum,
      "tbl": tbl,
      'find': row, //this.props.search, 
    };

    const response = await fetch('https://inupline.com/post.php',
      {
        method: 'POST',
        body: JSON.stringify(datar)
      }
    );
    const data = await response.json();
    return data;
  }


  function CompanyJornada(props) {
    const [userData,setUserData] = useState([]);
    const [userTram,setUserTram] = useState([]);
    const [corpClient,setCorpClient] = useState([]);
    const [horasUser,setHoresUser] = useState([]);
    const [selUser,setSelUser] = useState([]);
    const [selCopr,setSelCorp] = useState([]);

    const [actMes,setActMes] = useState(new Date().getMonth() + 1);
    const [actAno,setActAno] = useState(new Date().getFullYear());

    function listsdd(items,guser,cid, dbhoras) {
      let i = 0;
      
      // MW1info("WM001");
      // MW1close('WM001'); 
      //setSelUser(55);

      const SelUser = (uid,cid) => {
        MW1info("WM001");
        setSelUser(uid);
        setSelCorp(cid);
      }

      if (items) {
      return (
        items.map((item , index) => (
          guser.map(gitem => {
            if (gitem.id === item.usr_id && '3' !== item.contrato && item.id_cliente === cid) {
              i++;
              return <li className="listmaket" key={item.id} id = {item.id} >
                      <div className="listempleado" id={item.id}>
                          <figure className="userlist" id={item.id} data-idc="20421">
                              <span className="list_empleo_numer">{i}</span>
                                <figcaption>
                                    {LoadAvatar(gitem, "list_empleo_pimg", null ,'m', '')}
                                    <span className="list_empleo_het">{gitem.fname} {gitem.lname} ( {HorasUser(dbhoras,item.usr_id,item.id_cliente,miUser.work) } )</span>
                                    <p className="list_empleo_hez"><i className="fa fa-info-circle" aria-hidden="true">
                                        </i>&nbsp;&nbsp;work&nbsp;{gitem.work}&nbsp;work&nbsp;{gitem.id}&nbsp; Puesto: {item.tpuesto}</p>
                                        {/*console.log(gitem, item)*/}
                                </figcaption>
                          </figure>
                      </div>
                      <div className="editbtn">
                        <div className="btn_setings" onClick={() => {SelUser(gitem,cid)}} ><div className="fa wa fa-calendar"  aria-hidden="true">&nbsp;</div></div>
                        <div className="btn_setings"><div className="fa wa fa-comments-o"  aria-hidden="true">&nbsp;</div></div>
                        <div className="btn_setings"><div className="fa wa fa-users"  aria-hidden="true">&nbsp;</div></div>
                        <div className="btn_setings"><div className="fa wa fa-street-view">&nbsp;</div></div>
                        <div className="btn_setings" onClick={() => {this.ShowSetings(gitem,item.id)}} ><div className="fa wa fa-pencil">&nbsp;</div></div>
                      </div>
                  </li>
            }
          })
        ))
      )
      } else {
        return "WAIT..."
      }
    }

    useEffect(() => {
      AppCaption('jornada','MI EMPRESA','micompany','jornada');
    }, []); 

    useEffect(() => {
      (async () => {
        const response = await ServGetBase("db_trabajador","corp_id", miUser.work);
        setUserData(response);
      })()
    }, []); 

    useEffect(() => {
      (async () => {
        const response = await ServGetBase("users","work",miUser.work);
        setUserTram(response);
      })()
    }, []); 

    useEffect(() => {
      (async () => {
        const response = await ServGetBase("db_client","id_comp",miUser.work);
        setCorpClient(response);
      })()

    }, []); 

    useEffect(() => {
      (async () => {
        const response = await ServGetBase("db_horario","id_empresa",miUser.work);
        setHoresUser(response);
      })()

    }, []); 


    //onClick={(e) => { updateHooks()}}
    return (
      <div >
          <div className = "btntools">

            <select className="findsuser" defaultValue={actMes} id="month" name="month" onChange={(e) => setActMes(e.target.value)} > 
              <option value="1">enero</option>
              <option value="2">febrero</option>
              <option value="3">marzo</option>
              <option value="4">abril</option>
              <option value="5">mayo</option>
              <option value="6">junio</option>
              <option value="7">julio</option>
              <option value="8">agosto</option>
              <option value="9">septiembre</option>
              <option value="10">octubre</option>
              <option value="11">noviembre</option>
              <option value="12">diciembre</option>
            </select>

            <select className="findsuser" defaultValue={actAno} id="year" name="year" onClick={(e) => setActAno(e.target.value)}>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
            </select>
            
          </div>

          <div className="tabs">
            {corpClient.map(itmClient => (
              <div className="tab" key={itmClient.id}>
                <input className = 'inputtabs' type="checkbox" id={itmClient.id}/>
                <label className="tab-label" htmlFor={itmClient.id}>{itmClient.c_name} (trabajadores: {listtrclient(userData,itmClient.id, miUser.work)})</label>
                <div className="tab-content">
                  {listsdd(userData,userTram,itmClient.id,horasUser)}
                </div>
              </div>
            ))}

          </div>

          <ModalInfo id = "WM001" titles = "modal 1">
              <JornadaEmpleo uid = {selUser} mes = {actMes} ano = {actAno} cid = {selCopr}/>
          </ModalInfo>
              

            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />

        </div>
    );
  }
  
  function mapStateToProps (state) {
      //console.log('CORP ' , state.companyReducer.corp[0]   )
    return { 
      //lng: state.lnguiReducer.lng, 
      palabras: state.palabrasReducer.palabras[0], 
      user: state.userReducer.user[0],
      corp: state.companyReducer.corp[0]
    }
  }
  
  export default connect(mapStateToProps)(CompanyJornada)