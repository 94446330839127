import './../style.css';
import '../../../css/widgsystem.css';

import React from "react";

let hor = 0;

export default class WdgSemana extends React.Component{
    constructor(props) {
      super(props);
      this.state = {
        horasall: []
      };
    }

    SelectDay = (wday,colL) => {
      let retrn =" colVa";
      if (wday === this.props.wday) { retrn ="wday wdayselect"; } else { retrn ="wday " + this.StyleColor(colL); }
      
      return retrn;
    }

    StyleColor = (props) => {
      if (props === "0") { return "colTr"; }
      if (props === "1") { return "colFS"; }
      if (props === "2") { return "colFi"; }
      if (props === "3") { return "colPu"; }
      if (props === "4") { return "colVa"; }
      if (props === "5") { return "colfirmado"; }
    }

    StyleStatus = (a_firm, wday) => {
      let retrn ="";
      if (wday.day === '') { retrn =""; }
      if (wday.day !== '' && a_firm === '') { retrn =<i className="fa fa-calendar-times-o colFail" aria-hidden="true"></i>; } // Редаговано і збережено та не підписано
      if (a_firm === '0') { retrn =<i className="fa fa-calendar-check-o colWait" aria-hidden="true"></i>; } // Редаговано і збережено та не підписано
      if (a_firm > 0) { retrn =<i className="fa fa-check colSave" aria-hidden="true"></i>; } // Редаговано і збережено та не підписано

      return retrn;
    }

    funcalhoras(wday) {
      this.props.horas.map((itmlugar => {
        if (Number(wday.day) === Number(itmlugar.c_dia) )  {
          hor = (Number(hor) + Number(itmlugar.c_horas)); //semana
        }
      }));
      return hor;
    }
    

    weekday(wday) {
      let a_day = '';
      let a_client = '';
      let a_lugar = '';
      let a_horas = '';
      let a_firm = '';
      let colL = '0'; // колір заливки по замовчуваню
      //hor = 0;
      //console.log("wday " , wday); 

      if (Number(wday.t) === 1) { colL = '0' }
      if (Number(wday.l) === 1) { colL = '' }
      if (Number(wday.f) === 1) { colL = '2' }
      if (Number(wday.p) === 1) { colL = '3' }
      if (Number(wday.v) === 2) { colL = '4' }
      if (wday.week === "s" || wday.week === "d") { colL = '1' }

      this.props.horas.map((itmlugar => {
        if (Number(wday.day) === Number(itmlugar.c_dia) )  {
          a_day = itmlugar.c_dia;
          a_client = itmlugar.id_client;
          a_lugar = itmlugar.id_lugar;
          a_horas = itmlugar.c_horas;
          a_firm= itmlugar.id_user_firm;
          
          //if (sem === wday.semana) {
            //hor = (Number(hor) + Number(itmlugar.c_horas)); //semana
          //}
        }

      }));


      this.props.cclient.map((itmlugar => {
        if (itmlugar.id === a_client) { a_client = itmlugar.c_name }
      }));

      this.props.clugar.map((itmlugar => {
        if (itmlugar.id === a_lugar) { a_lugar = itmlugar.c_lugar }
      }));

      return (
        <div className = {this.SelectDay(wday.day,colL)} key = {wday.week + wday.day} id = {wday.week + wday.day} onClick={() => this.seluser(wday)}>
          <div className = "cday">
            {wday.day}
          </div>
          <div className = "dday">dia</div>
          <div className = "sday">
            {wday.week}
          </div>
          <div className="vertical-line"></div>
          <div className="checkico">{this.StyleStatus(a_firm, wday)}</div>

          <div className="cliente">{a_client}</div>
          <div className="lugar">{a_lugar}</div>

          <div className="choras">horas</div>
          <div className="horas" id={"SHR" + wday.day}>{a_horas}</div>
        </div>
      )
    }

    showweek(item) {
      //let calender_items = [];
      //let selectweek = [];
      let drawweek =[];
      let maxday = this.props.citems.length;
      if(maxday === 32 ) {maxday = maxday -1}

        if (Number(item.day) === 1 && item.week === 'm') { drawweek = [ this.weekday({'day': '', 'week': 'Lunes','colors' : '0'})] }
        if (Number(item.day) === 1 && item.week === 'x') { drawweek = [ this.weekday({'day': '', 'week': 'Lunes','colors' : '0'}), this.weekday({'day': '', 'week': 'Martes','colors' : '0'})] }
        if (Number(item.day) === 1 && item.week === 'j') { drawweek = [ this.weekday({'day': '', 'week': 'Lunes','colors' : '0'}), this.weekday({'day': '', 'week': 'Martes','colors' : '0'}), this.weekday({'day': '', 'week': 'Miércoles','colors' : '0'})] }
        if (Number(item.day) === 1 && item.week === 'v') { drawweek = [ this.weekday({'day': '', 'week': 'Lunes','colors' : '0'}), this.weekday({'day': '', 'week': 'Martes','colors' : '0'}), this.weekday({'day': '', 'week': 'Miércoles','colors' : '0'}), this.weekday({'day': '', 'week': 'Jueves','colors' : '0'})] }
        if (Number(item.day) === 1 && item.week === 's') { drawweek = [ this.weekday({'day': '', 'week': 'Lunes','colors' : '0'}), this.weekday({'day': '', 'week': 'Martes','colors' : '0'}), this.weekday({'day': '', 'week': 'Miércoles','colors' : '0'}), this.weekday({'day': '', 'week': 'Jueves','colors' : '0'}), this.weekday({'day': '', 'week': 'Viernes','colors' : '0'})] }
        if (Number(item.day) === 1 && item.week === 'd') { drawweek = [ this.weekday({'day': '', 'week': 'Lunes','colors' : '0'}), this.weekday({'day': '', 'week': 'Martes','colors' : '0'}), this.weekday({'day': '', 'week': 'Miércoles','colors' : '0'}), this.weekday({'day': '', 'week': 'Jueves','colors' : '0'}), this.weekday({'day': '', 'week': 'Viernes','colors' : '0'}), this.weekday({'day': '', 'week': 'Sábado','colors' : '1'})] }

        drawweek.push (this.weekday(item))

        if (Number(item.day) === maxday && item.week === 'l') { drawweek.push ( this.weekday({'day': '', 'week': 'm','colors' : '0'}),this.weekday({'day': '', 'week': 'x','colors' : '0'}),this.weekday({'day': '', 'week': 'j','colors' : '0'}),this.weekday({'day': '', 'week': 'v','colors' : '0'}), this.weekday({'day': '', 'week': 's','colors' : '1'}), this.weekday({'day': '', 'week': 'd','colors' : '1'})) }
        if (Number(item.day) === maxday && item.week === 'm') { drawweek.push ( this.weekday({'day': '', 'week': 'x','colors' : '0'}),this.weekday({'day': '', 'week': 'j','colors' : '0'}),this.weekday({'day': '', 'week': 'v','colors' : '0'}),this.weekday({'day': '', 'week': 's','colors' : '1'}), this.weekday({'day': '', 'week': 'd','colors' : '1'})) }
        if (Number(item.day) === maxday && item.week === 'x') { drawweek.push ( this.weekday({'day': '', 'week': 'j','colors' : '0'}),this.weekday({'day': '', 'week': 'v','colors' : '0'}),this.weekday({'day': '', 'week': 's','colors' : '1'}), this.weekday({'day': '', 'week': 'd','colors' : '1'})) }
        if (Number(item.day) === maxday && item.week === 'j') { drawweek.push ( this.weekday({'day': '', 'week': 'v','colors' : '0'}),this.weekday({'day': '', 'week': 's','colors' : '1'}), this.weekday({'day': '', 'week': 'd','colors' : '1'})) }
        if (Number(item.day) === maxday && item.week === 'v') { drawweek.push ( this.weekday({'day': '', 'week': 's','colors' : '1'}),this.weekday({'day': '', 'week': 'd','colors' : '1'})) }
        if (Number(item.day) === maxday && item.week === 's') { drawweek.push ( this.weekday({'day': '', 'week': 'd', 'colors' : '1'})) }

        //console.log("tt " , item );

        return drawweek; 
      
  }

  renderlist() {
    const { citems } = this.props;
    var drawweek = [];
    hor = 0;
    for (var i = 0, len = citems.length; i < len; i++) {
      if(citems[i].semana === this.props.sem && Number(citems[i].day) > 0){
        drawweek.push ( this.weekday(citems[i]));
        this.funcalhoras(citems[i])
        //console.log("tt " , citems[i] );
      }
    }
  
    return drawweek
  }

  seluser = (ids) => {
    this.props.selectD(ids.day); // передаємо в батьківський контрол атрибут
  }

    render() {
      
        return (
          <div className="JorCalendar">
              {this.renderlist()}
              <p className="GrStotal">{'Total: ' + hor + ' h'} </p>

          </div>
        );
    }
  }
