//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faCoffee } from '@fortawesome/free-solid-svg-icons' 

function LngItem({lng, text, isActive, onClick}) {
    

function onMouseOver(event) {
    //event.target.style.color = '#191919';
}

function MouseOut(event) {
    //event.target.style.color = Ssel;
}
function lngUI () {
    //lng = value
    return "flag-icon flag-icon-" + lng;
}

    return (
        <button onMouseOver={onMouseOver} onMouseOut={MouseOut} onClick={() => onClick(lng)} className="menubutton" type="button"><span className={lngUI()}></span> {text}</button> 
    );
}

export default LngItem;