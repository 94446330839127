import './../style.css';
import '../../../css/widgsystem.css';
import './style.css';

import React, { useState, useEffect } from "react";
import { myUsers, AppCaption} from "../../../redux/state";
import DayBox from "../widgets/daybox";
import MapsToGo from "../widgets/mapstogo";
import ListTrello, { ListItm, ListElement }  from "../widgets/listtrello";

import SWlistbox from "../widgets/SWlistbox";
import $ from 'jquery';

export default class IndexUserHome extends React.Component{

    constructor() {
      super()
      this.state = {
          userids : [] // sel user id  
        };
        this.updateIds = this.updateIds.bind(this)
      }
      
    updatecaption(){
      //tcapt ('USER EDITOR');
      AppCaption('Partes');
      myUsers.callUser();
    }
  
    useropenid(){
      //console.log('work');
    }
  
    updateIds = (ids) => {
      //MW1info(ids); // show modal
    }
  
    componentDidMount() {
      this.updatecaption()

      $(function() {
        var taeb = $(".taeb-switch");
        taeb.find(".taeb").on("click", function() {
          var $this = $(this);
      
          if ($this.hasClass("active")) return;
      
          var direction = $this.attr("taeb-direction");
      
          taeb.removeClass("left center right").addClass(direction);
          taeb.find(".taeb.active").removeClass("active");
          $this.addClass("active");
          console.log('SEL ' + direction);
        });
      });
    }
  
    render() {
      return (
        <div className = "saback-box">

        {/*<MapsToGo></MapsToGo>*/}
        <div style={{padding:'10px'}}>
            <div className="wrapper">
                <div className="taeb-switch left text-center">
                    <div className="taeb active" taeb-direction="left">Falta</div>
                    <div className="taeb" taeb-direction="center">Todas</div>
                    <div className="taeb" taeb-direction="right">Firmados</div>
                </div>
            </div>
        </div>

        <div style={{paddingTop:'0px'}}>
            <SWlistbox></SWlistbox>
        </div>

      </div>
      );
    }
  }    
