import React from 'react'
import ImgLogo from '../img/miniU.png';

function Logo_in(props) {
    
    return (
        <div className="logog0">
            <div className="logog1">{props.pal.id1}</div>
            <div className="logog2in">in</div>
            <img className="logog2l" src={ImgLogo} alt="UP"/>
            <div className="logog2line">Pline</div>
            <div className="logog3">{props.pal.id2}</div>
        </div>
    );
}
  
export default Logo_in;