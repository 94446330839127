// COMPUNENTS MENU-BUTTON
// 16/07/2022
// SERHII BILOUS

// EJEMPLO:
// <CMenuButton MenuShow = {toggleClass} clos = {isMenubtm}/>
// MenuShow - run function if click
// clos - true o false if open

export default function CMenuButton({MenuShow, clos}) {
  const toggleClass = () => {
    const oldClassName = document.getElementById('nav-icon3').className;
    const newClassName = oldClassName === 'open' ? '' : 'open'
    document.getElementById('nav-icon3').className=newClassName;
    MenuShow(newClassName);
  }

  if (clos === true) {
    document.getElementById('nav-icon3').className='';
  }
  
  return (
      <div id="nav-icon3" onClick={toggleClass}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
  );
}
