import './style.css';
import './../style.css';
import '../../../css/widgsystem.css';

import React from "react";
import { AppCaption, LogoCompany } from "../../../redux/state";
//import { PDFDownloadLink} from '@react-pdf/renderer';
import { Link, useNavigate } from "react-router-dom";

// WIDGET SYSTEMS
import WdgCalendar from '../widgets/calendar';
import WdgSemana from '../widgets/semana';
import WdgBox from '../widgets/wgbox';
import CntrSelect from '../widgets/select';
//import CntrTextbox from '../widgets/textbox';
import WdgDaybox from './daybox';
import { useDispatch, connect } from 'react-redux'

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Doughnut} from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


// 
let DatosInfo = {
  Mes: [
      {
        laboral: '', 
        nolaboral: '', 
        festivos: '', 
        puentes: '', 
        totalhoras: 0,
        extra: 0,
        falta: 0,
      }
  ]
};

let timmesa = [2, 168, 8]

let data2 = {
  //labels: ['Red', 'Blue', 'Yellow'],
  datasets: [
    {
      label: '# of Votes',
      data: timmesa,
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
      ],
      borderWidth: 1,
    },
  ],
}

class JornadaPage extends React.Component{
    constructor(props) {
      super(props);
      this.state = {
        citems: [],
        cday: new Date().getDate(),
        csem: 1,
        cmes: new Date().getMonth() + 1, // Місяць
        cano: new Date().getFullYear(),
        clugar: [],
        cclient: [],
        horasall: [],
        work: [],
        cdayhoras: 1,
        totalhoras: 0,
        extra: 0,
        falta: 0,
        updatepage: new Date().getTime(),
        ActUser: []
      };
      this.GetAno = this.GetAno.bind(this);
    }


    GETJSON (data,stname){
      fetch(
        'https://inupline.com/post.php',
        {
          method: 'POST',
          header : {
            Accept: 'application/json', 'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        }
      )
        .then (response => response.json())
        .then (response => {
            //console.log(response);
            this.setState({
              [stname]: response
            });
            return response;
        })
      
        .then((result) => {
            console.log('Success:', stname , result);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    LoadHorasMes() {

        var data = { 
          "cmd": 'ReadHorasMes',
          "tbl": 'db_horario',
          "id_user": this.props.user.userid, // miUser.id,
          "c_mes": this.state.cmes,
          "c_ano": this.state.cano
        };

        //console.log('data:', this.props.user.userid , this.props.user.work);

        this.GETJSON(data,"horasall")
    }

    LoadDataClients() {
      var data = { 
        "cmd": 'ReadTableClient',
        "id_work": this.props.user.work, //miUser.work,
        "tbl": 'db_client'
      };

      //console.log('data:', this.props.user.work);

      this.GETJSON(data,"cclient")
      var data = { 
        "cmd": 'ReadTableLugar',
        "tbl": 'db_lugar'
      };

      //console.log('data:', this.props.user.work);

      this.GETJSON(data,"clugar")
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.corp !== this.props.corp) {

        this.LoadDataClients()

        this.selectMes(Number(new Date().getMonth() + 1));
        this.SelectAno(Number(new Date().getFullYear()));

        //console.log('something prop has changed.')
      }

      if (prevState.updatepage !== this.state.updatepage) {
        this.LoadHorasMes();
      }

      if (prevState.csem !== this.state.csem) {

      }
    }


    componentDidMount() {  
      AppCaption('','jornada','jornada','');

      this.GetUpdateAll();
      this.LoadDataClients();
      this.GetAno (this.state.cmes, this.state.cano);
      this.LoadHorasMes();
    }


    componentWillUnmount() {
      //console.log("2 CLD LOAD M = " , Number(new Date().getMonth()), ' YER = ' , Number(new Date().getFullYear()));
    }

    currentDate() {
      var monthNames = [ "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
                     "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER" ];
      var days = ['SUNDAY','MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY','SATURDAY'];   
      //var dayj = ['1','1','1','1','1','0','0'];                       
                    
      var today = new Date();
      var dd   = today.getDate();
      var mm   = monthNames[today.getMonth()]; 
      var yyyy = today.getFullYear();
      var day  = days[today.getDay()];

      //var dj  = dayj[today.getDay()];

      today = 'Date is :' + dd + '-' + mm + '-' + yyyy;
      return 'Day is : ' + day
  }

    calcday(moon,year){
    let mess = moon;
    let mess2 = mess;
    
    let ano =  year;
    let ano2 = ano;

    if (mess === 12) { 
      mess2 = 1
      ano2 ++
    } else {
      mess2 ++
    }

    //const {Temporal} = temporal;
    var dateString = ano + "-" + mess + "-1"  //"2015-12-31";
    var day_start = new Date(dateString.replace(/-/g, "/"));
    var dateString2 = ano2 + "-" + mess2 + "-1"  //"2015-12-31";
    var day_end = new Date(dateString2.replace(/-/g, "/"));


    //let day_start = new Date(mess + " 1 " + ano);
    //let day_end = new Date(mess2 + " 1 " + ano2);
    let total_mes = day_start.getMonth()+1;
    let total_days = Number((day_end - day_start) / (1000 * 60 * 60 * 24)+1);

    let calender_items = [];

    let dayj = ['0','1','1','1','1','1','0']; 
    //var dayn = ['НЕДІЛЯ','ПОНЕДІЛОК','ВІВТОРОК','СЕРЕДА','ЧЕТВЕР','ПЯТНИЦЯ','СУБОТА'];   
    let dayes = ['d','l','m','x','j','v','s'];   

    let fie = ['mm dd yyyy','1 6 2022','12 8 2021','12 25 2021']; 
    let puent = ['mm dd yyyy','1 7 2022','12 23 2021','12 24 2021','12 31 2021']; 

    let laboral = 0;
    let nolaboral = 0;
    let festivos = 0;
    let puentes = 0;

    let fiday = 0;
    let puday = 0;

    //console.log("total_mes " + total_mes);

    let week =1;
      for (let i = 1; i < total_days; i++) {
        var dateString3 = ano + "-" + mess + "-" + i;  //"2015-12-31";
        let today = new Date(dateString3.replace(/-/g, "/"));
        let dd   = today.getDate();
        let day  = dayj[today.getDay()];
        let dayjn  = dayes[today.getDay()];

        if (day === "1") { laboral ++;} else {nolaboral ++}

        for (let f = 0; f < fie.length ; f++) {
          //let day_fiestas = new Date(fie[f]);
          let day_fiestas = new Date(fie[f].replace(/-/g, "/"));

          if (today.getTime() === day_fiestas.getTime()) { 
            festivos ++; 
            fiday = 1;
          }
        } 

        for (let f = 0; f < puent.length; f++) {
          let day_puente = new Date(puent[f]);
          if (today.getTime() === day_puente.getTime()) { 
            puentes ++;
            puday = 1;
          }
        } 

        calender_items.push({'day': dd, 'week': dayjn, 'mes':total_mes, 'l':day, 'f':fiday, 'p':puday ,'semana' : week,'t' : 0}) 
        
        if (dayjn === "d") { week ++;} // розмічаємо тижні
        puday = 0;
        fiday = 0;

    } 

    let clc = festivos + puentes
    DatosInfo.Mes.laboral = laboral - clc;
    DatosInfo.Mes.nolaboral = nolaboral;
    DatosInfo.Mes.festivos = festivos;
    DatosInfo.Mes.puentes = puentes;

    //calender_items.push({'day': d.toString(), 'week': 'd', 'mes':1, 'l':0, 'f':0, 'p':0 ,'semana' : 2,'t' : 0}) 

    //calender_items.push({'laboral': laboral, 'nolaboral': nolaboral, 'festivos':festivos, 'puentes':puentes}) 
    //let FnDayFree = ({'laboral': laboral, 'nolaboral': nolaboral, 'festivos':festivos, 'puentes':puentes});
    //FnUpdateState ({'laboral': laboral, 'nolaboral': nolaboral, 'festivos':festivos, 'puentes':puentes});

    const sssem = this.SemDay(calender_items, this.state.cday)

    this.setState({
      csem: sssem
    });


    //console.log("calender_items " , calender_items, " csem = " , this.state.csem, sssem);

    return calender_items;
  }

    GetAno(mes, ano) {
      let cldata = this.calcday(Number(mes),Number(ano));

      this.setState({ citems: cldata });
    }

    Alltotalhoras = (ht,he,hf) => {
      this.setState({ totalhoras: ht ,extra: he ,falta: hf })
      //console.log('Alltotalhoras', ht,he,hf)
    }

    selectDay = (selDay) => {
      this.setState({ cday: selDay })
    }

    selectSem = (selSem) => {
      this.setState({ csem: selSem });
    }

    selectMes = (selMes) => {
      this.setState({cmes : selMes}, () => 
      this.LoadHorasMes());
      this.GetAno (selMes, this.state.cano);
    }

    SelectAno = (selYer) => {
      this.setState({cano : selYer}, () => 
      this.LoadHorasMes());
      this.GetAno (this.state.cmes, selYer);
    }

    GetHoras = (h) => {
      //let cday = this.state.cday
      var SHR = document.getElementById("SHR" + this.state.cday);
      var MHR = document.getElementById("MHR" + this.state.cday);

    
        // id={"SHR" + wday.day}
        //SHR.textContent = h
        //MHR.textContent = h
        //this.setState({cdayhoras : h}, () => 
        //console.log('PaternHoras ' + h));
  
    }

    GetUpdateAll = () => {
      //const acday = this.state.updatepage
      this.setState({
        updatepage: new Date().getTime(),
      });

      this.LoadHorasMes();
    }

    //horas
    SemDay = (item, cday) =>{
      var semid = 1

      item.map((itmlugar => {
        if (Number(itmlugar.day) === cday) {
          semid = itmlugar.semana
        }
      }));

      return semid;
    }

    render() {
      const redCorp = this.props.corp;
      //let text = this.props.text.value;

      return (
        <div className = "saback-box">
          <WdgBox title='EMPRESA' psh = 's1x' psw = 'h2x'>
            <SelectData company = {redCorp} totalhoras = {this.state.totalhoras} extra = {this.state.extra} falta = {this.state.falta} work = {redCorp} mes = {this.state.cmes} year = {this.state.cano} SelMeses={this.selectMes} SelAno={this.SelectAno}/>
          
            <Link as={Link} to={{pathname: "/preview", search: "?f=JL" + "&m=" + this.state.cmes + "&s=" + 0 + "&a=" + this.state.cano + "&u=" + this.props.user.userid + "&c=" + this.props.user.work + "&cl=" + redCorp.id}}> 
                <button className="btnboxmenu sulitew">{'Jornada Laboral mes'}</button>
            </Link>

            <Link as={Link} to={{pathname: "/preview", search: "?f=PS" + "&m=" + this.state.cmes + "&a=" + this.state.cano + "&s=" + this.state.csem + "&u=" + this.props.user.userid + "&c=" + this.props.user.work + "&cl=" + redCorp.id}}> 
                <button className="btnboxmenu sulitew">{'Parte Semana'}</button>
            </Link>

          </WdgBox>

          <WdgBox title={'MES ' + this.state.cmes} psh = 's1x' psw = 'h2x'>
            <WdgCalendar cday = {this.state.cday} citems = {this.state.citems} horas = {this.state.horasall} mes = {this.state.cmes} year = {this.state.cano} Allhoras = {this.Alltotalhoras} selectD = {this.selectDay} selectS = {this.selectSem} info= {DatosInfo.Mes}/> 
          </WdgBox>

          <WdgBox title={'SEMANA ' + this.state.csem} psh = 's1x' psw = 'h2x'>
            <WdgSemana  sem = {this.state.csem} horas = {this.state.horasall} cclient = {this.state.cclient} clugar = {this.state.clugar} citems = {this.state.citems}  mes = {this.state.cmes} year = {this.state.cano} week = {this.state.csem} wday = {this.state.cday} selectD = {this.selectDay}/>
          </WdgBox>

          <WdgBox title={'DIA ' + this.state.cday} psh = 's1x' psw = 'h2x' infolabel={this.state.cday}>
            <WdgDaybox GetUpdateAll = {this.GetUpdateAll.bind(this)}  cclient = {this.state.cclient} clugar = {this.state.clugar} cday = {this.state.cday} cmes = {this.state.cmes} cano = {this.state.cano} userid = {this.props.user.userid} GetHoras = {this.GetHoras.bind(this)} comp_id = {this.props.user.work} /> 
          </WdgBox>

        </div>
        
      );
    }
  }    

  function mapStateToProps (state) {
    //console.log('STATE REDUX ' , state )
  return { 
    //lng: state.lnguiReducer.lng, 
    //palabras: state.palabrasReducer.palabras[0], 
    user: state.userReducer.user[0],
    corp: state.companyReducer.corp[0]
  }
}

export default connect(mapStateToProps)(JornadaPage)

  class SelectData extends React.Component{ //extending Grid
    constructor(props) {
      super(props);
      this.state = { cmes : '', cano : '' };
    }

    selmes = (ids) => {
      this.props.SelMeses(Number(ids)); // передаємо в батьківський контрол атрибут
    }

    selano = (ids) => {
      this.props.SelAno(Number(ids)); // передаємо в батьківський контрол атрибут
    }

    componentDidMount() {  
      this.setState({ cmes: this.props.mes }) 
      this.setState({ cano: this.props.year }) 
      //this.componentDidUpdate();
      
      
    }

    componentDidUpdate(prevProps) {
      // Типове використання (не забудьте порівняти пропси):
      if (this.props.mes !== prevProps.mes) {
        this.setState({cmes : this.props.mes})
      }

      if (this.props.year !== prevProps.year) {
        this.setState({cano : this.props.year})
      }

      //console.log('this.props.company.cname ' + this.props.company.cname);

    }

    loaddata(data) {
      data2 = {
        //labels: ['Red', 'Blue', 'Yellow'],
        datasets: [
          {
            label: '# of Votes',
            data: data,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1,
          },
        ],
      }

      //console.log(data);
    }

    render() {
      if (DatosInfo.Mes.totalhoras !== this.props.totalhoras || DatosInfo.Mes.extra !== this.props.extra || DatosInfo.Mes.falta !== this.props.falta) {
        DatosInfo.Mes.totalhoras = this.props.totalhoras
        DatosInfo.Mes.extra = this.props.extra
        DatosInfo.Mes.falta = this.props.falta

        let maxmes = this.props.totalhoras;
        let extra = this.props.extra
        let falta = this.props.falta

        let timline = [falta, maxmes, extra];
        this.loaddata(timline);
      }

      return (
        <div>
            <div className = "JorCalendar">

                {/*this.props.company ? this.props.company.map((itemwork => (
                  <div key={itemwork.id}>
                    <p className="txtEmpresa">{itemwork.cname}</p>
                    {LogoCompany(itemwork, "callogoimg", null ,'m')}
                  </div>
                ))): <div key={1}>
                <p className="txtEmpresa">{'test name'}</p>
                </div>*/}

              {this.props.company ? 
                  <div>
                    <p className="txtEmpresa">{this.props.company.cname}</p>
                    {LogoCompany(this.props.company, "callogoimg", null ,'m')}
                  </div>
                : <p className="txtEmpresa">{'test corp name'}</p>
              }
                
              <div className="bolok">

                <CntrSelect cid = "cldmes" caption = "Mes:" value={this.state.cmes} onChange={(e) => this.selmes(e.target.value)}>
                    <option value="1">enero</option>
                    <option value="2">febrero</option>
                    <option value="3">marzo</option>
                    <option value="4">abril</option>
                    <option value="5">mayo</option>
                    <option value="6">junio</option>
                    <option value="7">julio</option>
                    <option value="8">agosto</option>
                    <option value="9">septiembre</option>
                    <option value="10">octubre</option>
                    <option value="11">noviembre</option>
                    <option value="12">diciembre</option>
                </CntrSelect>

                <CntrSelect cid = "cldano" caption = "Ano:" value={this.state.cano} onChange={(e) => this.selano(e.target.value)}>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                </CntrSelect>

                <div className = "chartmeshoras">
                  <Doughnut data={data2} />
                </div>
                
                <div style={{left:"200px",bottom: "50px", position: 'absolute'}}>
                  <div style={{display: 'inline-block', height:'20px', width:'20px',border: 'solid 1px rgba(255, 99, 132, 1)', backgroundColor:'rgba(255, 99, 132, 0.2)', marginRight:'10px'}}></div><div style={{position: 'relative', bottom: "5px",display: 'inline-block'}}> Falta {DatosInfo.Mes.falta}</div><br />
                  <div style={{display: 'inline-block', height:'20px', width:'20px',border: 'solid 1px rgba(54, 162, 235, 1)', backgroundColor:'rgba(54, 162, 235, 0.2)', marginRight:'10px'}}></div><div style={{position: 'relative', bottom: "5px",display: 'inline-block'}}> Laboral {DatosInfo.Mes.totalhoras}</div><br />
                  <div style={{display: 'inline-block', height:'20px', width:'20px',border: 'solid 1px rgba(255, 206, 86, 1)', backgroundColor:'rgba(255, 206, 86, 0.2)', marginRight:'10px'}}></div><div style={{position: 'relative', bottom: "5px",display: 'inline-block'}}> Extra {DatosInfo.Mes.extra}</div><br />
                </div>

              </div>

            </div> 
        </div>
      );    
    }
  }


  