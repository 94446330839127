import './../style.css';
import '../../../css/widgsystem.css';

import React, {Component} from "react";
//import {UIlengID} from '../../../App'; // Потрібно файл де буде завантажуватися всі переміні
import { LogoCompany, AppCaption ,MW1close, MW1info, ModalInfo} from "../../../redux/state";

//import GostAva from '../../../img/guest_nano.png';  //sadmin.png

import EmpleoAjustes from "./lugares_edit"
import EmpleoInfo from "./empleo_info"
import EmpleoDel from "./empleo_del"
import EmpleoChat from "./empleo_chat"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, connect } from 'react-redux'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


class IndexUserHome extends React.Component{
    constructor() {
      super()
      this.state = {
          st_eqipo : [],
          st_eqipo_id: 0,
        };
        this.updateIds = this.updateIds.bind(this);

        this.addnew = React.createRef();
      }
      
    updateIds = (ids) => {
      //MW1info(ids); // show modal
    }
  
    componentDidMount() {
      AppCaption('lugares','MI EMPRESA','micompany','lugares');
      //myUsers.callUser();
    }


    onClick = () => {      //this.refs.child.ShowSetings('','new');
      this.addnew.current.ShowSetings('','new');
  }
  
    
    FindText = () => {
      var ftext = document.getElementById('findtext').value;
      this.setState({ftext: ftext})
    }

    render() {
      return (
        <div >
            <div className = "btntools">
            <button className="btnboxmenu sulite" id="com0" type="button" onClick={this.onClick} > <i className="fa fa-plus btni" aria-hidden="true"></i></button>
            <input className="findsuser" id = "findtext" type="text" name="userName" placeholder="User name..." onKeyDown={this.handleKeyDown}/>
            <button className="btnboxmenu sulite" id="bfind"> <i className="fa fa-search" aria-hidden="true"></i></button>
          </div>
            <div id = "listusr">
              <SuserList miUser = {this.props.user} search = {this.state.ftext} ref={this.addnew}/>
            </div>
        </div>
      );
    }
  }    
  

  function mapStateToProps (state) {
    console.log('CORP ' , state.companyReducer.corp[0]   )
  return { 
    //lng: state.lnguiReducer.lng, 
    //palabras: state.palabrasReducer.palabras[0], 
    user: state.userReducer.user[0],
    corp: state.companyReducer.corp[0]
  }
}

export default connect(mapStateToProps)(IndexUserHome)


  class SuserList extends React.Component{ //extending Grid
    constructor(props) {
      super(props);
      this.state = {
        tabIndex: 0,
        items: [],
      };
      this.ShowSetings = this.ShowSetings.bind(this);
    }

    notifyok = () => toast.success(' DATOS ACTUALIZADO !!!', {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });

    GetBase(tbl,colum,row, mstate, data2){
      let datar = [];
      datar = { 
        "cmd": 'FindText',
        "colum": colum,
        "tbl": tbl,
        'find': row, //this.props.search, 
      };

      if (data2) {datar.finds = data2}

      //console.log("UPDATE  data " , datar)

      fetch('https://inupline.com/post.php',
        {
          method: 'POST',
          body: JSON.stringify(datar)
        }
      )
      
      .then (response => response.json())
        .then(response => {
          this.setState({
            [mstate]: response
          });
          
          console.log('response', response )

          return response;
        });
    }

    componentDidUpdate(prevProps, prevState) {
      // Check if the suplied props is changed
      let OldIntro = []; 

      if(prevState.items !== this.state.items) {
        for (var i = 0, len = this.state.items.length; i < len; i++) {
          OldIntro.push (this.state.items[i].user_id)
        } 
        if(this.state.items.length !== 0) { 
          //this.GetBase("users","id",0, "guser", OldIntro)
        }
      }

      if(prevProps.cmd !== this.props.cmd) {
        this.ShowSetings("","new")
      }
      
    }
  
    componentWillMount () {
      this.GetBase("db_lugar","corp_id", this.props.miUser.work, "items")
      //this.GetBase("users","work",miUser.work, "intramite")
    }
  
    seluser = (ids) => {      
      this.props.updateIds(ids);
    }
  
    selcompany = (itm) => {
      this.props.GetCompany(itm); // передаємо в батьківський контрол атрибут
    }

    RedrawAll = () => {
      this.GetBase("db_lugar","corp_id", this.props.miUser.work, "items")
        MW1close('WM001');
        this.notifyok();
    }


    componentDidMount() {
      //this.props.cmd(this.ShowSetings("","new"));
   }
    
    ShowSetings(selid,onwe) {
      MW1info("WM001");
      this.setState({tabIndex: 0 }) //tabIndex
      this.setState({userid: selid })
      this.setState({oldnew: onwe })
      //this.setState({usersel: usersel })
    }

    DeleteTab(selid,onwe) {
      MW1info("WM001");
      this.setState({tabIndex: 1 }) //tabIndex
      this.setState({userid: selid })
      this.setState({oldnew: onwe })
      //console.log('DeleteInfo', onwe )

    }

    setTabIndex(index){
      this.setState({tabIndex: index }) //tabIndex
    }

    listbox() {
      const { items } = this.state;
      var i = 1;

      if (items) {
      return (
        items.map((item , index) => (
          <li className="listmaket" key={item.id} id = {item.id} >
              <div className="listempleado" id={item.id}>
                  <figure className="userlist" id={item.id} data-idc="20421">
                      <span className="list_empleo_numer">{this.showindex(index)}</span>
                      
                        {LogoCompany('', "list_empleo_pimg", null ,'', '')}                      <figcaption>
                          <span className="list_empleo_het">{item.c_lugar}</span>
                          <p className="list_empleo_hez"><i className="fa fa-info-circle" aria-hidden="true">
                              </i>&nbsp;&nbsp;&nbsp;{item.email}&nbsp;{item.note}</p>
                      </figcaption>
                  </figure>
              </div>
              <div className="editbtn">
                <div className="btn_setings" onClick={() => {this.ShowSetings(item, item.id)}} ><div className="fa wa fa-pencil">&nbsp;</div></div>
                <div className="btn_setings"><div className="fa wa fa-calendar"  aria-hidden="true">&nbsp;</div></div>
                <div className="btn_setings"><div className="fa wa fa-comments-o"  aria-hidden="true">&nbsp;</div></div>
                <div className="btn_setings"><div className="fa wa fa-users"  aria-hidden="true">&nbsp;</div></div>
                <div className="btn_setings"><div className="fa wa fa-street-view">&nbsp;</div></div>
                <div className="btn_setings" onClick={() => {this.DeleteTab(item, item.id)}}><div className="fa wa fa-trash-o">&nbsp;</div></div>

              </div>
          </li>
           ))
      )
      } else {
        return "WAIT..."
      }
    }

    showindex(index) {
      return (Number(index) +1 );
    }
  
    render() {
      const { tabIndex } = this.state;

      const { items } = this.state;
      const { guser } = this.state;
      
        return (
          <div id="gridhistory" className="gridhistory">
              <ul id="grid1" className="">
                  {this.listbox()}
              </ul>
              
              <span className = "txtinfo"><i className="fa fa-info-circle" aria-hidden="true"></i> {0}</span>
              
              <ModalInfo id = "WM001" titles = "modal 1">
                <div className = "conteiner">
                  <Tabs selectedIndex={tabIndex} onSelect={index => this.setTabIndex(index)}>
                    <TabPanel>
                      <EmpleoAjustes eqipo = {this.state.userid} redrawall = {this.RedrawAll} newold = {this.state.oldnew}/>
                    </TabPanel>
                    
                    <TabPanel>
                      <EmpleoDel userid = {this.state.userid} redrawall = {this.RedrawAll}  newold = {this.state.oldnew} table = 'db_lugar'/>
                    </TabPanel>
                  </Tabs>
                </div>
              </ModalInfo>


              <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              />

          </div>
        );
    }
  }
  
  