import React , { useState, useEffect }  from 'react';
//import { useDispatch, connect } from 'react-redux'
import { myUsers, miUser, AppCaption, LoadAvatar ,MW1close, MW1info, ModalInfo} from "../../../redux/state";
import WdgCalendar from '../widgets/calendar_info';

import { Link, useNavigate } from "react-router-dom";


import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  
  import { Doughnut} from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    ArcElement,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
   
  let data2 = {
    //labels: ['Red', 'Blue', 'Yellow'],
    datasets: [
      {
        label: '# of Votes',
        data: [2, 168, 8],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }

  let data3 = [];

  let data = {
    //labels: ['test 1', 'test 2', 'test 3'],
    datasets: [
      {
        label: 'Dataset 1',
        data: [0, 10, 30],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
        ],
      },
    ],
  };

  export default function JornadaEmpleo(props) {
    const [infoMes,setMes] = useState(0);
    const [infoAno,setAno] = useState(0);
    const [infoTotalhoras,setTotalhoras] = useState(0);
    const [infoHfalta,setHfalta] = useState(0);
    const [infoHwork,setHwork] = useState(0);
    const [infotHextra,setHextra] = useState(0);

    const [infotDialab,setDialab] = useState(0);
    const [infotDianlab,setDianlab] = useState(0);
    const [infotDiafes,setDiafes] = useState(0);

    const [infotCH2,setCH2] = useState();

    function setDatas (infoch) {
      let timmesa = [infoch.falta, infoch.workhoras, infoch.extra ]
      data2 = {
        //labels: ['Red', 'Blue', 'Yellow'],
        datasets: [
          {
            label: '# of Votes',
            data: timmesa,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1,
          },
        ],
      }
    }

    function setDatas2 (infoch) {
      let labels = [];//['Barajas', 'Madrid334', 'Valdebebas', 'Alcobendas', 'SSDRRey', 'Ardoz', 'AlcalaDH'];
      let timmesa2 = []; //[2, 168, 8, 8, 44, 2, 54, 0]
      infoch.map((itms => {
        labels.push (itms.c_lugar)
        timmesa2.push (itms.c_horas)
      }));

      data3 = infoch;
      
      data = {
        //labels,
        datasets: [
          {
            label: '',
            data: timmesa2,
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
            ],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
            ],
          },
        ],
      };
    }

    const handleCheng = (infoch,infoch2)  => {
      // 👇️ take parameter passed from Child component
      //console.log('handleClick:', infoch2);
      setDatas(infoch);
      setDatas2(infoch2);
      //setCH2([infoch2]);
      infoch2.map((itms => {
        //setCH2([itms.c_lugar, itms.c_horas]);
      }));
      setMes(infoch.mes);
      setAno(infoch.ano);
      setTotalhoras(infoch.totalhoras);
      setHfalta(infoch.falta);
      setHwork(infoch.workhoras)
      setHextra(infoch.extra)
      setDialab(infoch.laboral);
      setDiafes(infoch.festivos);
      setDianlab(infoch.nolaboral);
    };

    return (
        <div className="empleado_info">
            <p className='modl_caption'><i className="fa fa-id-badge" aria-hidden="true"></i> Info: {props.cid}</p> 

        <div className='ch1'>
            {LoadAvatar(props.uid, "ava_empleado", null ,'m', '')}
            <div className='usrbox' >
              <p className="txt">{props.uid.fname}  {props.uid.lname}</p>
              <p className="txt2"> Mes y Ano: {infoMes} / {infoAno}</p>
              <p className="txt2"> Dias laborales: {infotDialab}</p>
              <p className="txt2"> Dias nolaborales: {infotDianlab}</p>
              <p className="txt2"> Dias festivos: {infotDiafes}</p>
              <p className="txt2"> Horras laborales: {infoTotalhoras}</p>
            </div>
        </div>

        <div className='ch2'>
          <div className='chr_horas_empleado'>
              <Doughnut data={data2} />
          </div>

          <div className='leg_horas_empleado'>
            <div style={{display: 'inline-block', height:'20px', width:'20px',border: 'solid 1px rgba(255, 99, 132, 1)', backgroundColor:'rgba(255, 99, 132, 0.2)', marginRight:'10px'}}></div><div style={{position: 'relative', bottom: "5px",display: 'inline-block'}}> Falta: {infoHfalta}</div><br />
            <div style={{display: 'inline-block', height:'20px', width:'20px',border: 'solid 1px rgba(54, 162, 235, 1)', backgroundColor:'rgba(54, 162, 235, 0.2)', marginRight:'10px'}}></div><div style={{position: 'relative', bottom: "5px",display: 'inline-block'}}> Laboral: {infoHwork} </div><br />
            <div style={{display: 'inline-block', height:'20px', width:'20px',border: 'solid 1px rgba(255, 206, 86, 1)', backgroundColor:'rgba(255, 206, 86, 0.2)', marginRight:'10px'}}></div><div style={{position: 'relative', bottom: "5px",display: 'inline-block'}}> Extra: {infotHextra} </div><br />
          </div>
        </div>

        <div className='ch3'>
          <WdgCalendar user = {props.uid} mes = {props.mes}  ano = {props.ano} cid = {props.cid} handleCheng={handleCheng}/>
        </div>


        <div className='ch4'>
          <div className='chr_horas_empleado'>
          <Doughnut data={data} />
          </div>
          <LegHoras infoch = {data3}/>
        </div>

        <div className='ch5'>
            <Link as={Link} to={{pathname: "/preview", search: "?f=JL" + "&m=" + props.mes + "&u=" + props.uid.id + "&s=" + '0' + "&a=" + props.ano + "&c=" + props.uid.work + "&cl=" + props.cid}}> 
                <button className="btnboxmenu sulitew">{'Jornada Laboral mes'}</button>
            </Link>

            <Link as={Link} to={{pathname: "/preview", search: "?f=PS" + "&m=" + props.mes + "&a=" + props.ano + "&s=" + 'x' + "&u=" + props.uid.id + "&c=" + props.uid.work + "&cl=" + props.cid}}> 
                <button className="btnboxmenu sulitew">{'Parte S1'}</button>
            </Link>

        </div>

        {console.log(props.uid.id, props.cid)}


        </div>
    );
  }
  

  function LegHoras (props) {
    let InDAta = [];
    
    if(props.infoch !== undefined) {InDAta = props.infoch}
    
    //console.log('props:', props.infoch);
    
    const listItems = InDAta.map((itms,index) =>
      <div key = {index}>
        <div style={{display: 'inline-block', height:'20px', width:'20px',border: 'solid 1px rgba(255, 99, 132, 1)', backgroundColor:'rgba(255, 99, 132, 0.2)', marginRight:'10px'}}></div>
        <div style={{position: 'relative', bottom: "5px",display: 'inline-block'}}>En {itms.c_lugar}: {itms.c_horas} horas</div><br />
      </div>
    );
      return (
          <div className='leg_horas_empleado'>{listItems}</div>
      );
  }