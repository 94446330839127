import '../style.css';
//import Cookies from 'universal-cookie';
//import {UIlengID} from './IndexGost';
import {UIlengID} from '../../../App';

function ContactPg(props) {
    //Contact PAGE
    //const cookies = new Cookies();

    function sd(){
        const url = 'whatsapp://send?text=Hello&phone=+34604191200';
        //window.open(url, '_blank');
        window.open(url, '_top');
    }

    function sd2(){
        const url = 'https://www.linkedin.com/in/serhii-bilous-77137511a/';
        //window.open(url, '_blank');
        window.open(url, '_blank');
    }

    function sd3(){
        const url = 'mailto:admin@inupline.com?subject=Hello&body=hello';
        //window.open(url, '_blank');
        window.open(url, '_top');
    }

    return (
        <div>
            <div className="polygonlogo"></div>
            <div className="glform">
                <h2>{props.pal.id5}</h2>
                    <div className="paragraf01">admin@inupline.com</div>
                    <div className="paragraf01">Serhii Bilous</div>

                <div>
                    <button className="redahatsapp" onClick={sd}><span className="fa fa-whatsapp"></span></button>
                    <button className="redlinkedin" onClick={sd2}><span className="fa fa-linkedin"></span></button>
                    <button className="redmail" onClick={sd3}><span className="fa fa-envelope-o"></span></button>

                </div>

            </div>
        </div>
    );
  }
  
export default ContactPg;