import './../style.css';
import '../../../css/widgsystem.css';

import React from "react";
//import {UIlengID} from '../../../App'; // Потрібно файл де буде завантажуватися всі переміні
import { miUser, myUsers, AppCaption, LoadAvatar} from "../../../redux/state";
//import GostAva from '../../../img/guest_nano.png';  //sadmin.png

//import { myUsers, miUser, LoadAvatarHis, LoadAvatar ,MW1close, MW1info, ModalInfo} from "../../../redux/state";

export default class IndexUserHome extends React.Component{

    constructor() {
      super()
      this.state = {
          st_chats : [] // sel user id  
        };
        this.updateIds = this.updateIds.bind(this)
      }
      
      GetBase(tbl,colum,row, mstate, data2){
        let datar = [];
        datar = { 
          "cmd": 'FindText',
          "colum": colum,
          "tbl": tbl,
          'find': row, //this.props.search, 
        };
  
        if (data2) {datar.finds = data2}
  
        //console.log("UPDATE  data " , datar)
  
        fetch('https://inupline.com/post.php',
          {
            method: 'POST',
            body: JSON.stringify(datar)
          }
        )
        
        .then (response => response.json())
          .then(response => {
            //console.log(data2 + ' GetBase ' + tbl ,response);
  
            this.setState({
              [mstate]: response
            });
            return response;
          });
      }

    updatecaption(){
      AppCaption('','Mi CHAT','mychat','');
      myUsers.callUser();
    }
  
    useropenid(){
      //console.log('work');
    }
  
    updateIds = (ids) => {
      //MW1info(ids); // show modal
    }
  
    componentDidMount() {
      this.updatecaption();
    }

    componentWillMount () {
      //this.GetBase("db_trabajador","corp_id", miUser.work, "items")
      this.GetBase("db_msg","euser_id",miUser.id, "st_chats")
    }

    showindex(index) {
      return (Number(index) +1 );
    }
  
    listbox() {
      const { st_chats } = this.state;

      var i = 1;
      

      if (st_chats) {
      return (
        st_chats.map((item , index) => (
            <li className="listmaket" key={item.id} id = {item.id} >
                      <div className="listempleado" id={item.id}>
                          <figure className="userlist" id={item.id} data-idc="20421">
                              <span className="list_empleo_numer">{this.showindex(index)}</span>
                                <figcaption>
                                    {LoadAvatar(item, "list_empleo_pimg", null ,'m', '')}
                                    <span className="list_empleo_het">{item.emsg}</span>
                                    <p className="list_empleo_hez"><i className="fa fa-info-circle" aria-hidden="true">
                                        </i>&nbsp;&nbsp;&nbsp;{item.edate}&nbsp;{item.etime}</p>
                                </figcaption>
                          </figure>
                      </div>
                      <div className="editbtn">
                        <div className="btn_setings"><div className="fa wa fa-calendar"  aria-hidden="true">&nbsp;</div></div>
                        <div className="btn_setings"><div className="fa wa fa-comments-o"  aria-hidden="true">&nbsp;</div></div>
                        <div className="btn_setings"><div className="fa wa fa-users"  aria-hidden="true">&nbsp;</div></div>
                        <div className="btn_setings"><div className="fa wa fa-street-view">&nbsp;</div></div>
                        <div className="btn_setings" onClick={() => {this.ShowSetings(item,"edit")}} ><div className="fa wa fa-pencil">&nbsp;</div></div>
                      </div>
                  </li>
        ))
      )
      } else {
        return "WAIT..."
      }
    }

    render() {
      return (
        <div className = "saback-box">
            <ul id="grid1" className="">
                  {this.listbox()}
              </ul>
          </div>
      );
    }
  }    