import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";

import React, { useEffect } from 'react';

import XMenu from '../WebMenu/webmenu.jsx';

import WelcomPg from "./GostMain/Welcom";
import AboutPg from "./GostMain/About";
import ContactPg from "./GostMain/Contact";
import LoginPg from "./GostMain/Login";
import Page404 from "./GostMain/Page404";


function IndexGost (props) {

    return (
      <BrowserRouter>
        <XMenu lng = {props.lng} pal = {props.palabras}/>

        <Routes>
          <Route index element={<WelcomPg pal = {props.palabras} />} />
          <Route path="/" element={<WelcomPg pal = {props.palabras}/>} />
          <Route path="/login" element={<LoginPg pal = {props.palabras}/>} />
          <Route path="/about" element={<AboutPg pal = {props.palabras}/>} />
          <Route path="/contact" element={<ContactPg pal = {props.palabras}/>} />

          <Route path="*" element={<Page404 />} />
          
        </Routes>

        
        
      </BrowserRouter>
    
  )
}

export default IndexGost
