import './style.css';
import './../style.css';
import '../../../css/widgsystem.css';

import React from "react";

import CntrSelect from '../widgets/select';
import CntrTextbox from '../widgets/textbox';
import CntrCheckbox from '../widgets/checkbox';

import SliderDual from '../widgets/sliderdual';
import { notifyok, notifyerror } from "../../../redux/state";

export default class ActivDay extends React.Component{
    constructor(props) {
      super(props);
      this.state = {
        d_id: '',
        slugar: '',
        sclient: '',
        c_desde_1: '',
        c_hasta_1: '',
        c_desde_2: '',
        c_hasta_2: '',
        c_horas: '9',
        firm_date: '',
        id_user_firm: '',
        nota: '',
        sday: 'new',
        c_laboral: false,
        c_comida: true,
        comp_id: ''
      };
    }


  UpdateDay(e) {
    e.preventDefault();
    
    var sclient = document.getElementById('sclient').value;
    var slugar = document.getElementById('slugar').value;
    var cnota = document.getElementById('cnota').value;
    var c_laboral = document.getElementById('c_laboral').checked;
    
    var data = { 
      "cmd": 'UpdateHoraDay',
      "tbl": "db_horario",
      'c_dia': this.props.cday, 
      'id_empresa': this.props.comp_id, 
      'id_client': sclient, 
      'id_lugar': slugar, 
      'id_user_firm': '', 
      'c_mes': this.props.cmes, 
      'c_ano': this.props.cano, 
      'c_horas': this.state.c_horas, 
      'c_desde_1': this.state.c_desde_1, 
      'c_hasta_1': this.state.c_hasta_1, 
      'c_laboral': c_laboral, 
      'nota': cnota, 
      'firm_date': '',
      'id_user':this.props.userid,
      'c_comida': this.state.c_comida, 
      'd_id':this.state.d_id
    };

    //console.log('data' + JSON.stringify(data));
    
  fetch(
  'https://inupline.com/post.php',
  {
    method: 'POST',
    body: JSON.stringify(data)

  }
  )
    .then (response => response.json())
    .then (response => {
        //console.log(response);
        this.LoadDay();
        })

    .then((result) => {
        //console.log('Success:', result);
        //alert("SAVE FILE !")
        notifyok(' DATOS ACTUALIZADO !!!');
    })
    .catch((error) => {
        //console.error('Error:', error);
        //alert("ERROR")
        notifyerror();
    });

    //finish('finish');
    this.props.GetUpdateAll();

  }

    GETJSON (data,stname){
      fetch(
        'https://inupline.com/post.php',
        {
          method: 'POST',
          header : {
            Accept: 'application/json', 'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        }
      )
        .then (response => response.json())
        .then (response => {
            //console.log(response);
            if (!response[0]) {
              this.setState({
                d_id: 'new',
                sday: 'new',
                  slugar: '',
                 sclient: '',
                 c_desde_1: '8',
                 c_hasta_1: '18',
                 c_desde_2: '0',
                 c_hasta_2: '0',
                 c_horas: '9',
                 firm_date: '',
                 id_user_firm: '',
                 c_laboral: false,
                 c_comida: true,
                 nota: ''
              });
            } else {
              this.setState({
                d_id: response[0].id,
                sday: response[0].c_dia,
                  slugar: response[0].id_lugar,
                 sclient: response[0].id_client,
                 c_desde_1: response[0].c_desde_1,
                 c_hasta_1: response[0].c_hasta_1,
                 c_desde_2: response[0].c_desde_2,
                 c_hasta_2: response[0].c_hasta_2,
                 c_horas: response[0].c_horas,
                 firm_date: response[0].firm_date,
                 id_user_firm: response[0].id_user_firm,
                 c_laboral: response[0].c_laboral,
                 c_comida: response[0].c_comida,
                 nota: response[0].nota
              });
            }
            
            //console.log('SERVER:', response[0].id);
            //console.log('d_id:', this.state.d_id);

            return response;
        })
        .then((result) => {
          if (result[0]) {
            console.log('Load day id:', result[0].id);
            //this.props.alert.show("SAVE FILE !");
          } else {console.log('Dont find day', data.c_day); }
        })
        .catch((error) => {
            console.error('Error:', error);
        });

        
    }

    LoadDay() {
      var data = { 
        "cmd": 'ReadHoraDay',
        "tbl": 'db_horario',
        "id_user": this.props.userid,
        "c_day": this.props.cday,
        "c_mes": this.props.cmes,
        "c_ano": this.props.cano,
        "nota": this.props.nota
      };
    
      this.GETJSON(data,"sday") 

      this.loadLugar(0);
  }

  componentDidMount() {  
    this.LoadDay()
  }

  someFunction = (text) => {
    console.log('Message from parent: ', text);
  }
  componentDidUpdate(prevProps, prevState) {
    // Check if the suplied props is changed
    if(prevProps.cday !== this.props.cday) {
      // run the function with the suplied new property
      this.LoadDay();
      
    }

    if(prevState.sclient !== this.state.sclient) {
      // run the function with the suplied new property
      this.loadLugar(0);
    }

    if(prevState.c_horas !== this.state.c_horas) {
      // run the function with the suplied new property
      this.loadLugar(0);
      /*var e = document.getElementById("c_comida");
      var isComida = e.target;
      let chor = this.state.c_horas;
      let ztime = this.state.c_hasta_1 - this.state.c_desde_1
      if (isComida === true) { chor = Number(ztime) - 1} else {chor = Number(ztime)}
      this.setState({ c_comida: isComida, c_horas: chor});
*/
    }

  }

  componentWillUnmount() {
    
  }

  loadLugar = (index) => {
    var e = document.getElementById("sclient");
    e.options.selectedIndex = index;
    console.log ('loadLugar', index)
    this.selclient(e.value);
    this.updateLugar(this.props.clugar);
  }
  
    selclient = (value) => {
      this.setState({ sclient: value }, () => 
      console.log(this.state.sclient));
    }

    sellugar = (value) => {
      this.setState({ slugar: value }, () => 
      console.log(this.state.slugar));
    }

    selhoras = (v1,v2,h) => {

      if (this.state.c_comida === true) { h = Number(h) - 1} else {h = Number(h)}

      this.setState({ c_horas: h , c_desde_1: v1, c_hasta_1: v2});
      
      this.props.GetHoras(h);
    }

    updateClients(Clients){
      //<option key={itmlugar.id} value={itmlugar.c_lugar}>{itmlugar.c_lugar}</option>
      //return `<option key=`+itmlugar.id +` value=`+ itmlugar.c_lugar + `>`+ itmlugar.c_lugar + `</option>`;
      let allClients = [];

      Clients.map((itmclients => {
        allClients.push (<option key={itmclients.id} value={itmclients.id}>{itmclients.c_name}</option>)
      }));
 
      return allClients
    }

    updateLugar(clugar){
      //<option key={itmlugar.id} value={itmlugar.c_lugar}>{itmlugar.c_lugar}</option>
      //return `<option key=`+itmlugar.id +` value=`+ itmlugar.c_lugar + `>`+ itmlugar.c_lugar + `</option>`;
      let alllugares = [];

      clugar.map((item => {
        if (this.state.sclient === item.id_client) { alllugares.push ( <option key={item.id} value={item.id} >{item.c_lugar}</option>
        ) }
      }));
 
      return alllugares
    }

    handleChange(e) {
      let isChecked = e.target.checked;
      this.setState({ c_laboral: isChecked, c_horas: 0,c_desde_1 : 0,c_hasta_1: 0}, () => 
      console.log(this.state.c_laboral));
    }

    handleComida(e) {
      let isComida = e.target.checked;
      let chor = this.state.c_horas;
      let ztime = this.state.c_hasta_1 - this.state.c_desde_1
    
      if (isComida === true) { chor = Number(ztime) - 1} else {chor = Number(ztime)}
      this.setState({ c_comida: isComida, c_horas: chor}, () => 
      console.log("comida -1hora", this.state.c_comida, this.state.c_horas,ztime));
    }

    notwork() {
      let retrn = "";
      if (this.state.c_laboral === "1" || this.state.c_laboral === true) { 
        retrn = " notwork" 
      }

      return retrn;
    }

    render() {
      const { cclient } = this.props;
      const { clugar } = this.props;

      const { sday } = this.state;

      //this.loadLugar(0);
      return (
        <div className="JorCalendar">
              <div className={this.notwork()}>

              <CntrSelect cid = "sclient" caption = "Cliente:"  onClick={(e) => this.selclient(e.target.value)}>
                {this.updateClients(cclient)}
              </CntrSelect>

              <CntrSelect cid = "slugar" caption = "Lugar:" value = {this.state.slugar} onChange={(e) => this.sellugar(e.target.value)}>
                {this.updateLugar(clugar)}
              </CntrSelect>

              <SliderDual horas = {this.state.c_horas} v1 = {this.state.c_desde_1} v2 = {this.state.c_hasta_1} max = "24" onChange={this.selhoras.bind(this)} />
              
              <div className="GrP3">
                <CntrTextbox cid = "cnota" caption = "" value = {this.state.nota} placeholder="nota" maxLength="150" cols="40" rows="2"/>
              </div>

              <div className="GrP4">
                <CntrCheckbox clabel = "Comida" className="btnboxmenu sulitew" checked={this.state.c_comida} type="checkbox" id="c_comida" onChange={e => this.handleComida(e)}/><br></br>
              </div>

              </div>

                    <div className="GrP3line">
                      <button className="btnboxmenu sulitew" id="send" onClick={this.UpdateDay.bind(this)}> <i className="fa fa-check" aria-hidden="true"></i>&nbsp;&nbsp;Guardar </button>
                      <CntrCheckbox clabel = "no trabajé" className="btnboxmenu sulitew" checked={this.state.c_laboral} type="checkbox" id="c_laboral" onChange={e => this.handleChange(e)}/><br></br>
                    </div>
                    
        </div>
      )
    }
  }
