import React , { useState, useEffect }  from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import JsPDF from 'jspdf';
import JornadaPage from "../PrintDoc/jornadalaboral";
import ParteSemana from "../PrintDoc/partesemana";

import { AppCaption } from "../../redux/state";

export default function Preview(props) {
  let [searchParams, setSearchParams] = useSearchParams();

  const [mesClient,setMesClient] = useState([{c_name: "Company Name", id: "0"}]);
  const [infoCorp,setCorp] = useState('test');

  const [actClient,setactClient] = useState([]);
  const [userInfo,setUserinfo] = useState([]);


  const [userId,setUserid] = useState([]);
  const [userWork,setUserwork] = useState([]);
  const [userFname,setUserFname] = useState([]);
  const [userLname,setUserLname] = useState([]);


  // Los datos de la link
  const formato = searchParams.get('f');   // Orientation landscape & portrait
  const gMes = searchParams.get('m');     // Mes
  const gAno = searchParams.get('a');     // Ano
  const gCompID = searchParams.get('c');    // ID de cliente
  //const gCompName = searchParams.get('cn'); // Nombre de la empresa
  //const gCClient = searchParams.get('cl');    // ID de la empresa

  const gSemana = searchParams.get('s'); //SEMANA
  const gUserID = searchParams.get('u'); // USER ID

  const GetBaseJSON = async (datar) => {
       
    const response = await fetch('https://inupline.com/post.php',
      {
        method: 'POST',
        header : {
          Accept: 'application/json', 'Content-Type': 'application/json',
        },
        body: JSON.stringify(datar)
      }
    );
    const data = await response.json();
    console.log("calender GetBaseJSON " , data,datar);
    return data;
  }

  //const response = await ServGetBase("users","work",miUser.work);
  useEffect(() => {
    let data = { 
      "cmd": 'FindText',
      "colum": 'id',
      "tbl": 'users',
      'find': gUserID, //this.props.search, 
    };

    (async () => {
      const response = await GetBaseJSON(data);
      setUserinfo(response);
    })()
  }, []); 

  useEffect(() => {
    var data = { 
      "cmd": 'ReadTableClient',
      "id_work": gCompID, //props.user.work, //miUser.work,
      "tbl": 'db_client'
    };
  
    (async () => {
      const response = await GetBaseJSON(data);
      setMesClient(response);
    })()
  }, []); 

  useEffect(() => {
    
    let data = { 
      "cmd": 'FindText',
      "colum": 'id',
      "tbl": 'db_company',
      'find': 3, //this.props.search, 
    };

    (async () => {
      const response = await GetBaseJSON(data);
      setCorp(response);
    })()
  }, []); 


  useEffect(() => {
    loadLugar(0);
    loadUserTXT(userInfo);
  }, [mesClient]); 

  const loadLugar = (index) => {
    var e = document.getElementById("sclient");
    e.options.selectedIndex = index;
    console.log ('loadLugar', index)
    selclient(e.value);
  }

  const loadUserTXT = (index) => {
    userInfo.map((itmclients => {
      setUserid(itmclients.id);
      setUserwork(itmclients.work);
    }));
  }
  
  const updateClients = (Clients) =>{
    let allClients = [];

    Clients.map((itmclients => {
      allClients.push (<option key={itmclients.id} value={itmclients.id}>{itmclients.c_name}</option>)
    }));

    return allClients
  }

  const selclient = (e) => {
    mesClient.map((itms => {
      if ( itms.id === e ) { setactClient(itms.c_name);
      }
    }));
    console.log('gSemana', gSemana)
  }

  const generatePDF = () => {
    if (formato === 'JL') { var Fpapel = 'landscape' }
    if (formato === 'PS') { var Fpapel = 'portrait' }

    const report = new JsPDF(Fpapel,'pt','a4'); // orientation landscape & portrait
    report.html(document.querySelector('#printdoc')).then(() => {
        report.save('JornadaLaboral.pdf');
    });
  }

  AppCaption('PREVIEW'); //gCompName
  
  const allsemanas = () => {
    let sem = [];
    for (let index = 1; index < 6; index++) {
      let ssls = '';
      if (userInfo[0]) { ssls = <ParteSemana key = {index} user = {userInfo[0]} sem = {index} mes = {gMes}  ano = {gAno} cid = {gCompID} cname = {infoCorp[0].cname} client = {actClient}/>}
      sem.push (ssls);
    }
    console.log('sem', sem)
    return sem;
  }

  return (
    <div className = "saback-box">
      <div className = "btntools">
        <button className="btnboxmenu sulite" id="com0" type="button"> <i className="fa fa-home btni" aria-hidden="true"></i></button>

        <select className = 'findsuser' name="sclient" id="sclient" onClick={(e) => selclient(e.target.value)}>
          {updateClients(mesClient)}
        </select>

        <button className="btnboxmenu sulite" id="bfind"  > <i className="fa fa-search" aria-hidden="true"></i></button>
        <button className="btnboxmenu sulite" id="com0" type="button" onClick={generatePDF}> <i class="fa fa-print" aria-hidden="true"></i></button>
      </div>

      <div className = 'prevpages' >
        <div className = 'printdoc' id = 'printdoc'>
          {gSemana === '0' && formato === 'JL' && userInfo[0] ? <JornadaPage user = {userInfo[0]} mes = {gMes}  ano = {gAno} cid = {gCompID} cname = {infoCorp[0].cname}  client = {actClient}/> :''}
          {gSemana !== 'x' && formato === 'PS' && userInfo[0] ? <ParteSemana user = {userInfo[0]} sem = {gSemana} mes = {gMes}  ano = {gAno} cid = {gCompID} cname = {infoCorp[0].cname} client = {actClient}/> : ''}
          {gSemana === 'x' && formato === 'PS' && userInfo[0] ? allsemanas() : ''}
        </div>
      </div>
    </div>
  );
}
