import './style.css';

import {
  BrowserRouter,
  Routes,
  Route,
  //Link,
} from "react-router-dom";

// PUSH Firebase
//import {GetDeviceID} from '../firebase'

import React , { useState, useEffect }  from 'react';
//import Cookies from 'universal-cookie';
import AppBar from '../AppBar/Bar';
//import UserMenu from '../AppBar/UserMenu';
import Firstrun from './firstrun/index';
import LoadingUI from './loadingui';
import IndexUserHome from './IndexUserHome'; // USER HOME PAGE 

import Page404 from "./GostMain/Page404";

// MENU PERFIL
import UserPerfil from './perfil/index';
import MAjustes from './perfil/m_ajustes';
import MHistory from './perfil/m_history';
import MChat from './perfil/m_chat';

// MENU APP
import JobIndex from './job/index';
import JobJornada from './job/jornada';
import MiPartes from './micompany/partes';
import JobCompanys from './companys/companys';
import MiCompany from './micompany/index';

import PgPreview from './../Pages/preview';
import TodoList from './../Pages/todolist';


// ADMINS TOOLS 
import SadminUers from './sadmin/users';
import SadminErrores from './sadmin/errores';
import SadminHistorys from './sadmin/historys';
import SadminChats from './sadmin/chats';
import SadminWebGhost from './sadmin/webghost';
import SadminCompanys from './sadmin/companys';

// MI EMPRESA
import MiEmTrabajadores from './micompany/trabajadores';
import MiEmEgipos from './micompany/eqipo';
import MiEmLugares from './micompany/lugares';
import MiEmSubcontratos from './micompany/subcontrato';
import MiEmClientes from './micompany/clientes';
import MiEmJornada from './micompany/jornada';
import MiEmDocuments from './micompany/documents';
import MiEmMensajes from './micompany/mesenger';

import { incrementCompany, incrementUser } from './../../redux/action'
import { useDispatch, connect } from 'react-redux'

function IndexUser(props) {

  const [routeResult,openUrl] = useState(<LoadingUI tcapt = {props.palabras.id21} />);
  //const [showDid,setDid] = useState('');

  //const dispatch = useDispatch();

  function authauto (index) {
      if (index.frun==="0"){ 
        openUrl (< Firstrun />);
      } else if (index.frun==="1"){ 
        //openUrl (< IndexUserHome did = {GetDeviceID()}/>);
        openUrl (< IndexUserHome />);

      }
  }

  useEffect(() => {
      //const usrIdWork = props.user.work;
      authauto(props.user);
      //dispatch(incrementCompany(usrIdWork));
      //dispatch(incrementUser(props.user));
      //console.log("UI users.email", props.user.email);

      //setDid(GetDeviceID());
      //console.log('getToken',GetDeviceID())

      //FireBase.

      
  }, [props.user]); 

  useEffect(() => {
    //setDid(GetDeviceID());
  }); 


  function redraw (){
    //console.log('exit'); 
    props.logout('2');
  }

  return (
    <div className="fondo">
      <BrowserRouter>
        <Routes>
          <Route index element={ routeResult } />
          <Route path="/" element={ routeResult } />
          <Route path="/myperfil" element={<UserPerfil pal = {props.palabras}/>} />
          <Route path="/myoptions" element={<MAjustes pal = {props.palabras}/>} />
          <Route path="/myhistory" element={<MHistory pal = {props.palabras}/>} />
          <Route path="/mychat" element={<MChat pal = {props.palabras}/>} />
          <Route path="/job" element={<JobIndex pal = {props.palabras}/>} />
          <Route path="/jornada" element={<JobJornada pal = {props.palabras} user = {props.user}/>} />
          <Route path="/partes" element={<MiPartes pal = {props.palabras}/>} />
          <Route path="/companys" element={<JobCompanys pal = {props.palabras}/>} />
          <Route path="/micompany" element={<MiCompany pal = {props.palabras} user = {props.user} corp= {props.corp}/>} />

          <Route path="/preview" element={<PgPreview pal = {props.palabras}/>} />


          {/* ADMINS */} 
          <Route path="/auser" element={<SadminUers pal = {props.palabras}/>} />
          <Route path="/aerror" element={<SadminErrores pal = {props.palabras}/>} />
          <Route path="/ahistory" element={<SadminHistorys pal = {props.palabras}/>} />
          <Route path="/achart" element={<SadminChats pal = {props.palabras}/>} />
          <Route path="/awebhost" element={<SadminWebGhost pal = {props.palabras}/>} />
          <Route path="/acompany" element={<SadminCompanys pal = {props.palabras}/>} />

          {/* USER MENU */} 
          <Route path="/firstrun" element={<Firstrun pal = {props.palabras}/>} />
          <Route path="/todolist" element={<TodoList pal = {props.palabras} user = {props.user}/>} />

          {/* MI COMPANIA */} 
          <Route path="/micompany/empleados" element={<MiEmTrabajadores pal = {props.palabras}/>} />
          <Route path="/micompany/eqipos" element={<MiEmEgipos pal = {props.palabras}/>} />
          <Route path="/micompany/lugares" element={<MiEmLugares pal = {props.palabras}/>} />
          <Route path="/micompany/suncontract" element={<MiEmSubcontratos pal = {props.palabras}/>} />
          <Route path="/micompany/clients" element={<MiEmClientes pal = {props.palabras}/>} />
          <Route path="/micompany/jornada" element={<MiEmJornada pal = {props.palabras}/>} />
          <Route path="/micompany/documentos" element={<MiEmDocuments pal = {props.palabras}/>} />
          <Route path="/micompany/mensaje" element={<MiEmMensajes pal = {props.palabras}/>} />

          <Route path="*" element={<Page404 />} />

        </Routes>

        <AppBar userout = {redraw} caption = {'HOME'}  pal = {props.palabras} user = {props.user} work= {props.corp}/>

      </BrowserRouter>

    </div>
  );
}


export default IndexUser

