import './../style.css';
import '../../../css/widgsystem.css';

import React from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class CntrTextbox extends React.Component {
    constructor(props){
      super(props);
      this.state = { value : this.props.value };
      this.updateInput = this.updateInput.bind(this);
    }
    
    updateInput(event){
      this.setState({value : event.target.value})
    }
  
    componentDidUpdate(prevProps) {
      // Типове використання (не забудьте порівняти пропси):
      if (this.props.value !== prevProps.value) {
        this.setState({value : this.props.value})
      }
    }
  
    render(){
    return (
          <label className="lbllist">
            {this.props.caption !== ""
            ? <span className="textlabel">{this.props.caption}</span>
            : this.props.caption
            }
            {this.props.caption !== ""
            ? <input className="frmtextbox" type="text" id = {this.props.cid} name={'tb_' + this.props.cid} value={this.state.value} onChange={this.updateInput} placeholder={this.props.placeholder}/>
            : <textarea className="frmtextboxfull" {...this.props} type="text" id = {this.props.cid} name={'tb_' + this.props.cid} value={this.state.value} onChange={this.updateInput} placeholder={this.props.placeholder}></textarea>
            } 
          </label>
      );
    }
  } 

  export class CntrDataBox extends React.Component {
    constructor(props){
      super(props);
      this.state = { value : this.props.value };
      this.updateInput = this.updateInput.bind(this);
    }
    
    updateInput(date){
      this.setState({value : date})
    }
  
    componentDidUpdate(prevProps) {
      // Типове використання (не забудьте порівняти пропси):
      if (this.props.value !== prevProps.value) {
        this.setState({value : this.props.value})
      }
    }
  
    // <input className="frmtextbox" type="text" id = {this.props.cid} name={'tb_' + this.props.cid} value={this.state.value} onChange={this.updateInput} placeholder={this.props.placeholder}/>

    render(){
      
     
    return (
          <label className="lbllist">
            {this.props.caption !== ""
            ? <span className="textlabel">{this.props.caption}</span>
            : this.props.caption
            }
            <div className="react-datepicker-wrapper">
              <DatePicker 
                className="frmtextbox"
                dateFormat="dd/MM/yyyy" 
                selected={this.state.value} 
                onChange={this.updateInput}
                name={'tb_' + this.props.cid}
                locale="es"
                id = {this.props.cid}
                withPortal
                portalId="root-portal"
              />
            </div>
            
            
          </label>
      );
    }
  } 