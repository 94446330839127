import '../style.css';
import LogoIn from "../../logo_in";
import React from 'react'

function WelcomPg(props) {
    return (
        <div>
            <div className="polygonlogo"></div>
            <div className = "sltienda">
                <LogoIn pal= {props.pal} />
            </div>
        </div>
    );
  }
  
export default WelcomPg;