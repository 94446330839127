import './style.css';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types'

//import {GetDeviceID} from '../firebase'


function LoginKey({entrada,red,inuser}) {
    //Frame Login Key
    const cookies = new Cookies();
    //const deviceid = GetDeviceID();
    
    // Перевірка користувача і його реєстрація в порталі
    function rewuser(red, rid, fname,lname,email,foto) {
        cookies.set('token', rid, { path: '/' , maxAge: 2592000, sameSite: 'Strict', secure: true});
        cookies.set('fname', fname, { path: '/' , maxAge: 2592000, sameSite: 'Strict', secure: true});
        cookies.set('lname', lname, { path: '/' , maxAge: 2592000, sameSite: 'Strict', secure: true});

        //console.log("login in" , deviceid);

        const token = "234234fe23423ewfedf244132@3aws23";


      fetch("https://inupline.com/post.php", {
        method: 'POST',
        headers: {"Authorization": `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body : JSON.stringify({
          "userlogin": rid, 
          "f": fname, 
          "l": lname, 
          "e": email, 
          "a": foto, 
          "r": red
          // "did": deviceid
      })
      })
      .then (response => response.json())
      .then (response => {
        //console.log(response.userid);
        cookies.set('userid', response.userid, { path: '/' , maxAge: 2592000, sameSite: 'Strict', secure: true});
        cookies.set('userava', response.urlfoto, { path: '/' , maxAge: 2592000, sameSite: 'Strict', secure: true});
        window.location.reload(false);
      })
    }

    function responseinup() {
      rewuser("i","9999999999999999","Tania","Bilous","catleon","https://inupline.com/ssd/user/8.png");
      inuser('1');
    }

    function responseinup0() {
      rewuser("i","94","Felipe","Cañeque","Cañeque","https://inupline.com/ssd/user/130.png");
      inuser('1');
    }

    function responseinup1() {
      rewuser("i","130","Li","Chan","chan","https://inupline.com/ssd/user/130.png");
      inuser('1');
    }

    function responseFacebook(response) {
      if (!response.status) {
          entrada = response.id;
          red = "Facebook"
          rewuser('f',response.id , response.first_name,response.last_name,response.email,response.picture.data.url);
          inuser('1');
        }
    }

    function responseGoogle(response) {
        if (!response.status) {
            entrada = response.profileObj.googleId;
            red = "Google";
            rewuser('g',response.profileObj.googleId, response.profileObj.givenName,response.profileObj.familyName,response.profileObj.email,response.profileObj.imageUrl)
            inuser('1');
        }
    }

    return (
        <div className="logincontainer">
            <FacebookLogin
                appId="789103421867853"
                fields="name,email,picture.width(130).height(130),first_name,last_name"
                callback={responseFacebook}
                render={renderProps => (
                <button className="btnboxfb" onClick={renderProps.onClick} disabled={renderProps.disabled}> <i className="fa fa-facebook-square btn_icon" aria-hidden="true"></i><samp className="btn_label">FACEBOOK</samp></button>
                )}
            />
            <GoogleLogin 
            clientId="384669644369-9mno4fl5kk54gjfs6iskf2msbipff3ra.apps.googleusercontent.com"
            render={renderProps => (
                <button className="btnboxgoogle" onClick={renderProps.onClick} disabled={renderProps.disabled}> <i className="fa fa-google btn_icon" aria-hidden="true"></i><samp className="btn_label">GOOGLE</samp></button>
                )}
            buttonText="Login"
            onSuccess={responseGoogle}
            //onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            />        

            <button className="btnboxgoogle" onClick={responseinup0}> <i className="fa fa-codepen btn_icon" aria-hidden="true"></i><samp className="btn_label">CLIENT</samp></button>
            <button className="btnboxgoogle" onClick={responseinup}> <i className="fa fa-codepen btn_icon" aria-hidden="true"></i><samp className="btn_label">EMPLEADO</samp></button>
            <button className="btnboxgoogle" onClick={responseinup1}> <i className="fa fa-codepen btn_icon" aria-hidden="true"></i><samp className="btn_label">OFFICE</samp></button>


        </div>
    );
  }
  
  LoginKey.prototype = {
    entrada: PropTypes.string.isRequired,
    red: PropTypes.string.isRequired,
    inuser: PropTypes.func.isRequired,
  }

export default LoginKey;

