import './../style.css';
import '../../../css/widgsystem.css';

import React, { useState, useEffect } from "react";
import { myUsers, miUser, MW1close, LoadAvatarHis, LoadAvatar ,SetHistory,SendForm ,SndMSG} from "../../../redux/state";
import Resizer from "react-image-file-resizer";

// WIDGET SYSTEMS
import WdgCalendar from '../widgets/calendar';
import WdgSemana from '../widgets/semana';
import WdgBox from '../widgets/wgbox';
import CntrSelect from '../widgets/select';
import CntrTextbox, {CntrDataBox} from '../widgets/textbox';

import GostAva from '../../../img/guest_nano.png';  //sadmin.png
import PropTypes from 'prop-types';
import { event } from 'jquery';

export default class IndexUserHome extends React.Component{
    
    constructor() {
      super()
      this.state = {
          st_eqipo: [],
          st_load: 0
        };
      }

    componentDidMount() {
        //this.setState({ st_eqipo: this.props.eqipo })
    }

    GETJSON (data,stname){
      fetch(
        'https://inupline.com/post.php',
        {
          method: 'POST',
          header : {
            Accept: 'application/json', 'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        }
      )
        .then (response => response.json())
        .then (response => {
            this.setState({
              [stname]: response
            });
            console.log('Response EMPLEO:', response);
        })
      
        .then((result) => {
            console.log('Success:', result);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    LoadDataClients() {
      var data = { 
        "cmd": 'FindText',
        "tbl": 'db_eqipo',
        'colum': 'corp_id', 
        'find': miUser.work
      };

      this.GETJSON(data,"st_eqipo")
    }  

    loading (val) {
        this.setState({st_load: val }, () => 
        console.log(this.state.st_load));
    }

    componentDidUpdate(prevProps, prevState) {
      if(prevProps.eqipo !== this.props.eqipo) {
        this.setState({ st_eqipo: this.props.eqipo })
      }
    }

    //this.props.redrawall()
    SaveCheng (id) {
      const { newold } = this.props;
      //this.SendForm('formElem','UpdateTable','id',st_eqipo.id)}}

      //console.log('SaveCheng id ', id);


      SendForm(newold, 'db_eqipo','formElem','UpdateTable','id',id)
      this.props.redrawall();

  }

    render() {
      const { st_eqipo } = this.state;
      
      return (
        <div className = "selcompany-info" >
            <LoadingIndicator show = {this.state.st_load}/>

            {this.props.newold}

            <form id="formElem">
                <div className = "cont_ava">
                {/*<AvatarCtrl usrs = {userids.id}/>*/}
                </div>
            <CntrTextbox cid = "name" caption = "Name" placeholder="Name" maxLength="100" value = {st_eqipo.name}/>
            <CntrTextbox cid = "note" caption = "Note" placeholder="Note" maxLength="256" value = {st_eqipo.note}/>
            <CntrTextbox cid = "corp_id" caption = "corp_id" placeholder="corp_id" maxLength="100" value = {miUser.work}/>

          </form>     
          <div className="downmodal">
            <button className="btnboxmenu" onClick={() => {this.SaveCheng(st_eqipo.id)}}>Confirmar</button>
            <button className="btnboxmenu" onClick={() => {MW1close('WM002')}} >CLOSE</button>
          </div>
        </div>
      );
    }
  }    

// Задає значення пропсів за замовчуванням:
IndexUserHome.defaultProps = {
    bold: true
  };

  IndexUserHome.propTypes = {
    bold: PropTypes.bool
  };


  function LoadingIndicator(props) {
      if (props.show === 1) {
        return <div className="load-3">
                    <p>Loading ...  </p>

                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
      } else {
        return ''
      }
      
  }