import './style_loading.css';
import PropTypes from 'prop-types';

function LoadingUI({tcapt}) {

  return (
    <div>
        <div id="page-preloader">
            <div className="ring"> {tcapt}
                <span className = "ld"></span>
            </div>
        </div>
    </div>
);

  }
  
  LoadingUI.prototype = {
    tcapt: PropTypes.func.isRequired,
  }


export default LoadingUI;