import Cookies from 'universal-cookie';
import {UIlengID} from '../App'; // Потрібно файл де буде завантажуватися всі переміні
import React from "react";

import GostAva from '../img/guest_nano.png';  //
import BotAva from '../img/bot.png';  //
import GostCompany from '../img/logo_company.png';  //

import { toast } from 'react-toastify';

export const notifyok = (txt) => toast.success(txt, {
  position: "bottom-center",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

export const notifyerror = () => toast.error(' UN ERROR!!!', {
  position: "bottom-center",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});


const cookies = new Cookies();
const time = new Date().toLocaleTimeString();

const rndnumerimg = Math.random();

export function MW1info (id) {
  //console.log('MW1info = ' + id);

  if (id !== 'undefined' && id !== 'null'){ 
    const oldClassName = document.getElementById(id).className;
    const newClassName = oldClassName === 'WM001open' ? 'WM001hide' : 'WM001open'
    document.getElementById(id).className=newClassName;
    //console.log('MW1info = ' + id);
  }
}

export function MW1close (id) {

  if (id !== 'undefined' && id !== 'null'){ 
    //const oldClassName = document.getElementById(id).className;
    document.getElementById(id).className='WM001hide';
    //console.log('MW1info = ' + id);
  }
}

export function ModalInfo(props) {
  return (
    <div className="WM001hide" id={props.id}>
      <div className="ModalWidg">
        {/*<InfoLabel clabel = {props.titles} userid = {props.userid} id =  {props.id}/>*/}
        
        {props.children}
      </div>

      <div className="downmodal">
        <button className="btnboxmenu" onClick={() => {MW1info(props.id)}} >CLOSE</button>
      </div>
      
    </div>
  )
}

function InfoLabel(props) {
  return (
    <div className="info">
      <h4 className="infotext"><i className="fa fa-user" aria-hidden="true"></i>&nbsp; {props.clabel}</h4>

      <div className="CtrlModalWidg">
        <button className="set_key"><i className="fa fa-trash" aria-hidden="true"></i></button>
        <button className="set_key"><i className="fa fa-floppy-o" aria-hidden="true"></i></button>
        <button className="set_key" onClick={() => {MW1info(props.id)}}><i className="fa fa fa-window-close" aria-hidden="true"></i></button>
    </div>
    
    </div>
  )
}

export function AppCaption (caption, hcap, hlink, clink ) {
  //hcap = 'HONE'
  // let urlElements = window.location.href.split('/')
  // //console.log('urlElements',urlElements);

  // window.location.href // Returns full path, with domain name
  // window.location.origin // returns window domain url Ex : "https://stackoverflow.com"
  // window.location.pathname // returns relative path, without domain name
  
  // document.getElementById("cpt_app").addEventListener("click", function(event){
  //   event.preventDefault()
  // });

  //document.getElementById("cpt_app").preventDefault();

  const cpt_app_a = document.getElementById("cpt_app_a");
  cpt_app_a.setAttribute("data-url", '/' + hlink);

  const cpt_app_b = document.getElementById("cpt_app_b");

  const cpt_app = document.getElementById("cpt_app");
  cpt_app.textContent = hcap;
  
  const cpt_app_c = document.getElementById("cpt_app_1");

  if (caption !== '') { 
    cpt_app_b.setAttribute("class", 'active'); 

    cpt_app_b.setAttribute("data-url", '/' + hlink + '/' + clink);
    cpt_app_c.textContent = caption;

    // var buttonTextEl = document.createElement("a");
    // buttonTextEl.id = "cpt_app_1";
    // buttonTextEl.href = "";
    // buttonTextEl.textContent ='XXX';
    // buttonTextEl.onclick  = 'GoBack1';
    // cpt_app_b.appendChild(buttonTextEl);
    //cpt_app_a.setAttribute("class", 'active'); 

    cpt_app_a.removeAttribute("class")
    console.log('cpt_app_b' , 'active'); // userid
  }
    if (caption === '') {
    cpt_app_a.setAttribute("class", 'active'); 
    //cpt_app_b.setAttribute("class", 'hide'); 

    cpt_app_b.removeAttribute("class") 
    console.log('cpt_app_a' , 'active'); // userid
  }
  //if (cpt_app) {cpt_app.innerHTML = '<a href="https://inupline.com/' + hlink +'">' + hcap + '</a> / ' + caption; }

  // if (cpt_app) { cpt_app.textContent = caption; }
}

export function LoadAvatar (ids, classN, onClk, mini) {
  //console.log('LoadAvatar' , ids); // userid
  var imgid = 0;
  if (ids.foto === "1") {
    if (!ids.id) { imgid = ids.userid}
    if (!ids.userid) { imgid = ids.id }
    return <img className={classN} onClick={onClk} src={"https://inupline.com/ssd/" + imgid + mini + ".jpg?" + rndnumerimg + "LoadAvatar"} onError={(e)=>{e.target.onError = null; e.target.src = GostAva}} alt="LoadAvatar"/>
  } else {
    return <img className={classN} onClick={onClk} src={GostAva} alt="LoadAvatar"/>;
  }
}

export function LogoCompany (ids, classN, onClk, mini,type) {
  let idimg
  if (!ids) { 
    idimg = ids
    return <img className={classN} onClick={onClk} src={GostCompany} onError={(e)=>{e.target.onError = null; e.target.src = GostCompany}} alt="Sin Logo"/>
  } else { 
    idimg = ids.id  // BotAvaBotAva
    return <img className={classN} onClick={onClk} src={"https://inupline.com/ssd/c"  + idimg + mini + ".jpg?"  + rndnumerimg + 'LogoCompany'} onError={(e)=>{e.target.onError = null; e.target.src = GostCompany}} alt="Sin Logo"/>

  }
  //if (ids.id > 0) { 
  //}
}

export function LoadAvatarHis (ids, classN, onClk, mini, type) {
  //console.log(ids);
  let SinImg = GostAva //GostCompany
  // if (type === "") { 
  //   type = ''
  //   SinImg = GostAva
  // } if (type === "c") { 
  //   type = 'c'
  //   SinImg = GostCompany
  // }

  if (Number(ids) !== 0 && type === 'User') {
    return <img className={classN} onClick={onClk} src={"https://inupline.com/ssd/"+ ids + mini + ".jpg?" + rndnumerimg + 'LoadAvatarHis'} onError={(e)=>{e.target.onError = null; e.target.src = SinImg}} alt="Sin Avatar"/>
  } else if (Number(ids) === 0 && type !== 'User' ){
    return <img className={classN} onClick={onClk} src={BotAva} />;
  } else if (Number(ids) === 0 && type === 'User' ){
    return <img className={classN} onClick={onClk} src={GostAva} />;
  }
}

export function SetHistory(herr) {
  // 0 - entrada
	// 1 - salida
	// 2 - new user
	// 3 - FirstRun
	// 4 - Cheng AVAtar
	// 5 - GHOST 
	// 6 - CV view

  fetch("https://inupline.com/post.php", {
      method: 'POST',
      header : {
      Accept: 'application/json', 'Content-Type': 'application/json',
      },
      body : JSON.stringify({
        rootcmd: "rhistory",
        hid: cookies.get('userid'), 
        herror: herr,
        hbrow: "1",
        hurl: "1",
        hreq: "1",
        hip: "1",
        hnote: ""
  })
  })
}

//#################################################################################
export function getData () {
  GetLeng();
  if (cookies.get('token')) {myUsers.callUser();}
}
//#################################################################################    
export function GetLeng() { // МОВА КОРИСТУВАЧА
    
    //INSERT INTO `ui_lng`(`id`, `ui_lng`, `ui_eng`, `ui_es`, `ui_ua`) VALUES ("","","","","")
    let numrows = 28;
    fetch("https://inupline.com/post.php", {
    method: 'POST',
    header : {
        Accept: 'application/json', 'Content-Type': 'application/json',
    },
    body : JSON.stringify({
        'orden': 'lng',
        'lng': cookies.get('lng_ui'),
        'ids': [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27]
        //'ids': [2]
    })
    })
      .then (response => response.json())
      .then (response => {
        for (var i = 1; i < numrows; i++) {
          //cookies.set('lng_id' + i, response['id'+i], { path: '/' , maxAge: 2592000});
          UIlengID[i] = response['id'+ i];
          //UL[i] = UIlengID[i];
        }
        
        //loadpage (selindex); // оновлення контенту з новою мовою
        console.log("Server in lng " + UIlengID[27] + time); 
        console.log("Server lng_ui " + cookies.get('lng_ui')); 

        //console.log("State lng " + cookies.get('lng_ui')); 
    })

    //console.log(indata[0].tk);
    //console.log(UIlengID[0]);
}

//sleep(1000); 

//#################################################################################
//#################################################################################
//#################################################################################
// uiuser ніде не використовується експеремент
let uiuser = {
      ui: [
          {
              lng: cookies.get('lng_ui'), 
              tk: cookies.get('token'), 
              nm: cookies.get('fname'), 
              ln: cookies.get('lname'), 
              id: cookies.get('userid'), 
              ava: cookies.get('userava'),
          }
      ]
};

export default uiuser;

//#################################################################################
export function sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }


export let miUser = { id: "0", 
                      fname: "0", 
                      lname: "0", 
                      email: "0", 
                      work: "0", 
                      foto: "0",
                      role: "0", 
                      estatus: "0",
                      nacionalidad: "0",
                      efoto: "0"};

class getUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inuser: 'mail'
    };
    //this.showDrawer = this.showDrawer.bind(this);
    //this.onClose = this.onClose.bind(this);
}

  async componentDidMount() {
    this.tick();
    this.callUser();
  }

  tick = () => {
    this.setState({inuser: "xsd"});
    console.log(this.state.inuser);
  }

  callUser = () => { // зантаження користувача
      fetch("https://inupline.com/post.php", {
      method: 'POST',
      header : {
        Accept: 'application/json', 'Content-Type': 'application/json',
      }, 
      body : JSON.stringify({
        "userlogin": cookies.get('token')
    })
    })
    .then (response => response.json())
    .then (response => {
      //console.log(response);
      var uri = "https://inupline.com/ssd/" + response.userid + ".jpg" ;


      //this.setState({ inuser: 'John Doe' }, console.log('Hi there'));

      //console.log(this.state.inuser);

      miUser.id = response.userid;
      miUser.fname = response.fname;
      miUser.lname = response.lname;
      miUser.email = response.email;
      miUser.work = response.work;
      miUser.role = response.role;
      miUser.estatus = response.status;
      miUser.nacionalidad = response.nacionalidad;
      miUser.efoto = uri;
      miUser.foto = response.foto;

      //console.log(miUser);

    })

  }
}

export const myUsers = new getUser()

class Timer {
  secondsPassed = '0'

  constructor(props) {
    this.state = {
      inuser: 'xxxx'
    };
  }

  increaseTimer() {
      this.secondsPassed = 'sssssssssssssss'
  }
}

export const myTimer = new Timer()

// #################################################################################################################################################
// ########################## SendForm ('new' o id elementa, 'table name', id form, ) ##############################################################
// #################################################################################################################################################
//   ejempl:    SendForm(newold, st_dbname,'formElem','UpdateTable','id',id)
// #################################################################################################################################################

export const SendForm = async (newold, db, frmName, Cmd, Col, Txt) => {
  const formElem = document.getElementById(frmName);
  
  let JsonData = {};
  Array.prototype.forEach.call(formElem, (element) => {
      JsonData[element.id]  = element.value
  })

  console.log('FRM ',frmName , JsonData);


  // FindText
  // UpdateTable
  // AddInTable

  //JsonData.cmd  = Cmd

  //var newold = this.props.newold
  if (newold === 'new') { JsonData.cmd  = 'AddInTable'} else { JsonData.cmd  = Cmd }

  JsonData.tbl = db
  //JsonData.colum  = Col //'corp_id'
  //JsonData.find  = Txt //miUser.work
  //if (Txt === '') {  JsonData.user_id   = Txt } //miUser.work
  JsonData.user_id   = Txt
  JsonData.corp_id   = miUser.work
  //console.log('JsonData' , JsonData);

  //this.loading(1); // Open LOAD SCREEN

  let response = await fetch('https://inupline.com/post.php', {
  method: 'POST',
  body: JSON.stringify(JsonData)
  });

  let result = await response.json();
  console.log('SendForm ', response.status);
  console.log('SERVER ' , result);


  //this.SndMSG('La empresa ha modificada tus condiciones',  ide.id)

  //this.loading(0); // Close LOAD SCREEN
  
}

// #################################################################################################################################################
// ########################################################  SndMSG ('Повідомлення', 133) ##########################################################
// #################################################################################################################################################

export function SndMSG(msg, id_user) {
 
  var data = { 
    "cmd": 'AddInTable',
    "emsg": msg,
    "tbl": "db_msg",
    //'ecomp_id': miUser.work, 
    'euser_id': id_user, 

    'edate': GetData('d'),
    'etime': GetData('t'),
    'etype': 'JOB', 
    'efile': '',
    'enote': ''
  };

  fetch('https://inupline.com/post.php',
    {
      method: 'POST',
      body: JSON.stringify(data)
    }
  )
    .then (response => {
        console.log(response);
        })

    .then((result) => {
        console.log('Success:', result);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
}

// #################################################################################################################################################
// ########################################################  GetData ('d') or GetData ('t') ' d - date y t - time ##################################
// #################################################################################################################################################

export function GetData(show) {
  let formatTwoDigits = (digit) => ("0" + digit).slice(-2);
  const d = new Date();
  
  var date = `${d.getFullYear()}-${formatTwoDigits(d.getMonth()+1)}-${formatTwoDigits(d.getDate())}`;
  var time = `${formatTwoDigits(d.getHours())}:${formatTwoDigits(d.getMinutes())}:${formatTwoDigits(d.getSeconds())}`;

  //return date + '-' + month + '-' + year;//format: dd-mm-yyyy;
  if (show === "d" ) {return date}
  if (show === "t" ) {return time}
}