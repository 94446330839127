
import './mapstogo.css';

import React from "react";
import { myUsers, AppCaption} from "../../../redux/state";

import $ from 'jquery';
//import 'round-slider/src/roundslider.js';
//import 'round-slider/src/roundslider.css';

export default class IndexUserHome extends React.Component{

    constructor() {
      super()
      this.state = {
          userids : [] // sel user id  
        };
        this.updateIds = this.updateIds.bind(this)
      }
      
    updatecaption(){
      //tcapt ('USER EDITOR');v
      AppCaption('WEB EDITOR');
      myUsers.callUser();
    }
  
    useropenid(){
      //console.log('work');
    }
  
    updateIds = (ids) => {
      //MW1info(ids); // show modal
    }
  
    componentDidMount() {
      
      //window.$ = window.jQuery = require("round-slider/src/roundslider.js")

      var $mobileWrap = document.querySelector('.saback-box');
      //var $mainHeader = document.querySelector('.main-header');
      //var $toolbar = document.querySelector('.toolbar');
      var $minimap = document.querySelector('.minimap');
      var $miniLists = document.querySelector('.mini-lists');
      var $miniList = document.querySelectorAll('.mini-list');
      var $mainContent = document.querySelector('.main-content');
      var $listsWrap = document.querySelector('.lists-wrap');
      var $lists = document.querySelector('.lists');
      var $list = document.querySelectorAll('.list');
      var $listItems = document.querySelectorAll('.list-items');
      //var $clickCircle = document.querySelector('.click-circle');
      //var $listHeader = document.querySelector('.list-header');
    
      //var _mainHeaderTop = $mainHeader.getBoundingClientRect().top;
      var _mobileWrapWidth = $mobileWrap.getBoundingClientRect().width;
      //var _docWidth = document.documentElement.clientWidth;
      var _miniListsWidth = 60 * $miniList.length / 0.85; // 15% margin-left
      var _listGap = $list[1].getBoundingClientRect().left - $list[0].getBoundingClientRect().left; // 15% margin-left
      var _listLen = $list.length;
    
      var currentIndex = 0;
      var newIndex = 0;
    
      // minimap state
      var minimapClass = ['', 'is-mini', 'is-mini is-hidden'];
      var minimapClassLen = minimapClass.length;
      var minimapClassCount = 1;
    
      var setMiniMapState = function(dir) {
        dir = (dir > 0) ? 1 : -1;
        if (minimapClassCount === minimapClassLen - 1 && 1 === dir) { return; }
        minimapClassCount = (minimapClassCount + dir) % minimapClassLen;
        $mainContent.className = 'main-content ' + minimapClass[minimapClassCount];
      };
    
      // var getIndex = function(node) {
      //   var i = 0;
      //   while(node.previousSibling){
      //     node = node.previousSibling;
      //     if(node.nodeType === 1) {
      //       i++;
      //     }
      //   }
      //   return i;
      // };
    
      var updateMinimapScroll = function() {
    
        var x = Math.round((_miniListsWidth - _mobileWrapWidth) * newIndex / ($miniList.length - 1));
    
        var shiftX = function(dx) {
          $minimap.scrollLeft = dx;
        };
    
        var _x = $minimap.scrollLeft;
        (function animloop(){
          var dir = (_x > x) ? -1 : 1;
          var step = dir * 10;
          if ((x - _x) * dir > (step * dir - 1)) {
            shiftX(_x + step);
            window.requestAnimationFrame(animloop);
          } else {
            shiftX(x);
          }
          _x += step;
        })();
      };
    
      var switcher = function(_newIndex) {
        newIndex = _newIndex;
    
        // avoid switching to currentItem
        if (newIndex === currentIndex) { return; }
    
        [].forEach.call($miniList, function(_list, v) {
          _list.classList.remove('active');
        });
    
        $miniList[newIndex].classList.add('active');
    
        updateMinimapScroll();
    
        $lists.style.transform = 'translateX(-' + _listGap * (newIndex) + 'px)';
    
        currentIndex = _newIndex;
    
        // minify minimap
        if (minimapClassCount === 0) {
          setMiniMapState(1);
        }
      };
    
    //   $('.list-items').on('scroll', function(event) {
        
    //       console.log(111511);
    //     if (0 === this.scrollTop) {
    //       console.log(11111);
    //       setMiniMapState(-1);
    //     }
    //   });
    
    
      ///
      ///
      ///
    
      
      //$clickCircle.addEventListener('animationend', function() {
      //  $clickCircle.classList.remove('active');
      //});
    
    
    
      $miniLists.addEventListener('click', function(e) {
        var _this = e.target;
    
        // cirecle position & animation
        //$clickCircle.classList.remove('active');
        //$clickCircle.style.cssText = 'top: ' + e.pageY + 'px; left: ' + e.pageX + 'px;';
        //$clickCircle.classList.add('active');
    
        if (_this.classList.contains('mini-list')) {
          switcher($(_this).index());
        }
      });
    
    
    
      $($lists).on('click', '.list', function(event) {
        switcher($(this).index());
      });
    
    
      // $($listItems).on('scroll', function(event) {
      //   if (0 === this.scrollTop) {
      //     setMiniMapState(-1);
      //   } else {
      //     console.log(minimapClassCount);
      //     setMiniMapState(1);
      //   }
      // });
    
    
      var init = (function() {
        var _listItemHeight = (function() {
          var _maxHeight = 0;
          [].forEach.call($listItems, function(_item) {
            _maxHeight = _item.scrollHeight > _maxHeight ? _item.scrollHeight : _maxHeight;
          });
          return _maxHeight;
        })();
    
        // sync height
        [].forEach.call($listItems, function(_item) {
          $miniList[$(_item).parent().index()].style.height = (100 * _item.scrollHeight / _listItemHeight) + '%';
        });
      })();
    
      // swipe detect ref.:  http://stackoverflow.com/a/23230280
    
      $listsWrap.addEventListener('touchstart', handleTouchStart, true);
      $listsWrap.addEventListener('touchmove', handleTouchMove, true);
    
      $mainContent.addEventListener('mousedown', handleTouchStart, true);
      $mainContent.addEventListener('mousemove', handleTouchMove, true);
      $mainContent.addEventListener('mouseup', handleTouchEnd, true);
    
      var xDown = null;
      var yDown = null;
      var swipStartEle;
    
      function handleTouchStart(evt) {
        // console.log('handleTouchStart');
        var _target = $(evt.target);
    
        // if (_target.hasClass('list-header') || _target.parents('.list-header').length) { return; }
        // console.log(_target, 333);
    
        if ('touchstart' === evt.type) {
          xDown = evt.touches[0].clientX;
          yDown = evt.touches[0].clientY;
        } else {
          xDown = evt.clientX;
          yDown = evt.clientY;
        }
    
        swipStartEle = evt.target;
      }
    
      function handleTouchEnd(evt) {
        xDown = null;
        yDown = null;
      }
    
      function handleTouchMove(evt) {
        var xUp ,yUp;
    
        if ( ! xDown || ! yDown ) {
          return;
        }
    
        if ('touchmove' === evt.type) {
          xUp = evt.touches[0].clientX;
          yUp = evt.touches[0].clientY;
        } else {
          xUp = evt.clientX;
          yUp = evt.clientY;
        }
    
        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;
    
        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
          if ( xDiff > 0 ) {
            // ledt
            switcher(Math.min(currentIndex + 1, _listLen - 1));
          } else {
            // right
            switcher(Math.max(currentIndex - 1, 0));
          }
        } else {
          if ( yDiff > 0 ) {
            // up
            setMiniMapState(1);
          } else {
            // down
            if (swipStartEle.classList.contains('list-header') || $(swipStartEle).parents('.list-header').length || !$(evt.target).closest('.list-items').scrollTop()) {
              setMiniMapState(-1);
              updateMinimapScroll();
            }
          }
        }
    
        /* reset values */
        xDown = null;
        yDown = null;
      }
        

          


    }


    render() {
      return (
  
  <main className="main-content is-mini">
    <nav className="minimap">
      <ul className="mini-lists">
        <li data-title="UI" className="mini-list active"></li>
        <li data-title="RD" className="mini-list"></li>
        <li data-title="SA" className="mini-list"></li>
        <li data-title="User Test blabla" className="mini-list"></li>
        <li data-title="PM" className="mini-list"></li>
        <li data-title="M1" className="mini-list"></li>
        <li data-title="M2" className="mini-list"></li>
        <li data-title="M3" className="mini-list"></li>
      </ul>
    </nav>
    
    <div className="lists-wrap">
      <ul className="lists">
        <li className="list">
          <h3 className="list-header"><i className="fa fa-ellipsis-v"></i>UI<i className="primary-plus">+</i></h3>
          <div className="list-items">
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--4"></span><span className="list-tag list-tag--4"></span></div>
            </div>
            <div className="list-item">
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--4"></span><span className="list-tag list-tag--2"></span><span className="list-tag list-tag--3"></span></div>
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--2"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--2"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--2"></span><span className="list-tag list-tag--2"></span><span className="list-tag list-tag--4"></span></div>
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--3"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--3"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
          </div>
        </li>
        
        <li className="list">
          <h3 className="list-header"><i className="fa fa-ellipsis-v"></i>RD<i className="primary-plus">+</i></h3>
          <div className="list-items">
            <div className="list-item">
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--1"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--5"></span><span className="list-tag list-tag--4"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--1"></span><span className="list-tag list-tag--2"></span></div>
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--5"></span><span className="list-tag list-tag--2"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
          </div>
        </li>
        <li className="list">
          <h3 className="list-header"><i className="fa fa-ellipsis-v"></i>SA<i className="primary-plus">+</i></h3>
          <div className="list-items">
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--5"></span><span className="list-tag list-tag--4"></span></div>
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--4"></span></div>
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--4"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--4"></span></div>
            </div>
            <div className="list-item">
            </div>
          </div>
        </li>
        <li className="list">
          <h3 className="list-header"><i className="fa fa-ellipsis-v"></i>User Test blabla<i className="primary-plus">+</i></h3>
          <div className="list-items">
            <div className="list-item">
            </div>
            <div className="list-item">
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--3"></span><span className="list-tag list-tag--4"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--1"></span><span className="list-tag list-tag--5"></span></div>
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--4"></span><span className="list-tag list-tag--4"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--3"></span><span className="list-tag list-tag--3"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--3"></span><span className="list-tag list-tag--2"></span></div>
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--3"></span><span className="list-tag list-tag--3"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
          </div>
        </li>
        <li className="list">
          <h3 className="list-header"><i className="fa fa-ellipsis-v"></i>PM<i className="primary-plus">+</i></h3>
          <div className="list-items">
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--5"></span><span className="list-tag list-tag--4"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--2"></span></div>
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--1"></span></div>
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--3"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--5"></span><span className="list-tag list-tag--1"></span></div>
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--4"></span><span className="list-tag list-tag--5"></span><span className="list-tag list-tag--4"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--5"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--1"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
          </div>
        </li>
        <li className="list">
          <h3 className="list-header"><i className="fa fa-ellipsis-v"></i>M1<i className="primary-plus">+</i></h3>
          <div className="list-items">
            <div className="list-item">
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--5"></span><span className="list-tag list-tag--2"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--4"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--4"></span></div>
            </div>
            <div className="list-item">
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--2"></span><span className="list-tag list-tag--5"></span></div>
            </div>
          </div>
        </li>
        <li className="list">
          <h3 className="list-header"><i className="fa fa-ellipsis-v"></i>M2<i className="primary-plus">+</i></h3>
          <div className="list-items">
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--4"></span></div>
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--1"></span><span className="list-tag list-tag--4"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--2"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--3"></span><span className="list-tag list-tag--1"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--3"></span><span className="list-tag list-tag--5"></span><span className="list-tag list-tag--2"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--4"></span></div>
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--2"></span><span className="list-tag list-tag--4"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--2"></span><span className="list-tag list-tag--5"></span><span className="list-tag list-tag--1"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
          </div>
        </li>
        <li className="list">
          <h3 className="list-header"><i className="fa fa-ellipsis-v"></i>M3<i className="primary-plus">+</i></h3>
          <div className="list-items">
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--2"></span></div>
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--2"></span><span className="list-tag list-tag--4"></span><span className="list-tag list-tag--3"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--5"></span><span className="list-tag list-tag--4"></span><span className="list-tag list-tag--5"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--3"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--3"></span></div>
            </div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--3"></span><span className="list-tag list-tag--5"></span></div><img src="http://placekitten.com/g/50/50" className="list-avatar" /></div>
            <div className="list-item">
              <div className="list-tags"><span className="list-tag list-tag--2"></span><span className="list-tag list-tag--1"></span></div>
            </div>
            <div className="list-item">
            </div>
          </div>
        </li>

      </ul>
    </div>
  
    <div className="click-circle"></div>

  </main>

      );
    }
  }    