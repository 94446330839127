import './../style.css';
import '../../../css/widgsystem.css';
import './style.css';
import Resizer from "react-image-file-resizer";

import React, { useState } from "react";
//import {UIlengID} from '../../../App'; // Потрібно файл де буде завантажуватися всі переміні
import { LogoCompany, AppCaption} from "../../../redux/state";
import GostCompany from '../../../img/logo_company.png';  //sadmin.png

class SuserList extends React.Component{ //extending Grid
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    fetch("https://inupline.com/post.php", {
      method: 'POST',
      header : {
        Accept: 'application/json', 'Content-Type': 'application/json',
      },
      body : JSON.stringify({
        "rootcmd": "companylists"
    })
    })
    .then (response => response.json())
      .then(response => {
        this.setState({
          items: response
        });
        console.log(response);
      });
  }

  seluser = (ids) => {
    //console.log(ids);
    this.props.updateIds(ids);
  }


  render() {
    const { items } = this.state;
      return (
        <div>
          <section className = "listbody">
          {items.map(item => (
            <div className = "listitm" key={item.id} id = {item.id}>
              {LogoCompany(item, "saava", null ,'m')}

              <p className = "textid">{item.id}</p>
              <p className = "textcom">{item.cname} </p>
              <p className = "textcinfo">{item.csector}</p>

              <div className="butbox">
                  <button className="key_setings downicol"><i className="fa fa-comments-o">&nbsp;</i></button>
                  <button className="key_setings downicol"><i className="fa fa-ban">&nbsp;</i></button>
                  <button className="key_setings downicol" onClick={() => this.seluser(item)}><i className="fa fa-pencil-square-o">&nbsp;</i></button>
              </div>
            </div>
          ))}
        </section>
        </div>
      );
  }
}

    //#################################################################################

  const MW1info = (itm) => {
    const oldClassName = document.getElementById('WM001').className;
    const newClassName = oldClassName === 'WM001open' ? 'WM001hide' : 'WM001open'
    document.getElementById('WM001').className=newClassName;
  }

function InfoLabel(props) {
  return (
    <div className="info">
      <h4 className="infotext"><i className="fa fa-user" aria-hidden="true"></i>&nbsp; {props.clabel}</h4>

      <div className="CtrlModalWidg">
        <button className="set_key" onClick={() => { DelRecTable(props.userid, "db_company")}}><i className="fa fa-trash" aria-hidden="true"></i></button>
        <button className="set_key" onClick={() => { SendFile(props.userid)}}><i className="fa fa-floppy-o" aria-hidden="true"></i></button>
        <button className="set_key" onClick={MW1info}><i className="fa fa fa-window-close" aria-hidden="true"></i></button>
    </div>
    
    </div>
  )
}

function ModalInfo(props) {
  return (
    <div className="WM001hide" id="WM001">
      <div className="ModalWidg">
        <InfoLabel clabel = {props.titles} userid = {props.userid} />
        
        {props.children}
      </div>

      <div className="downmodal">
        <button className="btnboxmenu"  onClick={MW1info}>CLOSE</button>
      </div>
    </div>
  )
}

export default class IndexUserHome extends React.Component{

  constructor() {
    super()
    this.state = {
        compamyids : [] // sel user id  
      };
      this.updateIds = this.updateIds.bind(this)
    }
    
  updatecaption(){
    //tcapt ('USER EDITOR');
    AppCaption('COMPANY');

    //myUsers.callUser();
  }

  useropenid(){
    //console.log('work');
  }

  updateIds = (ids) => {
    this.setState({compamyids: ids})
    MW1info(ids); // show modal
  }

  componentDidMount() {
    this.updatecaption();
  }

  NewCompany = () => {

    var CreateCompany = {
      id: 'new',
      cname: 'CompanyName',
      euser_id: '',
      ccif: '',
      cantiguedad: '',
      cdomicilio: '',
      ctelefono: '',
      cregistro: '',
      cweb: '',
      csector: '',
      cndeempleados: '',
      cdueno: '',
      cmatriz: '',
      cnote: ''
    }

    this.setState({
      compamyids: CreateCompany
    })
    MW1info(); // show modal
  }

  render() {
    const { compamyids } = this.state;
    return (
      <div className = "saback-box">
          <div className = "btntools">
          <button className="btnboxmenu sulite" id="sum1"  onClick={this.NewCompany}> <i className="fa fa-plus" aria-hidden="true"></i></button>
          <input className="findsuser" type="text" name="userName" placeholder="Enter compamy name..."/>
          <button className="btnboxmenu sulite" id="sum1"> <i className="fa fa-plus" aria-hidden="true"></i></button>
          </div>
          <div id = "listusr">
            <SuserList updateIds = {this.updateIds}/>
          </div>

          <ModalInfo titles = {compamyids.cname + " (" + compamyids.id  + ")"} userid = {compamyids.id}>
            <div className = "cont_ava">
              <LogoCtrl usrs = {compamyids.id}/>
            </div>

            <div className = "conteiners">
              <div className = "conteiner1">
                <InTextBox cid = "euser_id" caption = "euser_id:" value = {compamyids.euser_id} placeholder="euser_id" />
                <InTextBox cid = "cname" caption = "Company name:" value = {compamyids.cname} placeholder="cname" />
                <InTextBox cid = "ccif" caption = "ccif:" value = {compamyids.ccif} placeholder="ccif" />
                <InTextBox cid = "cantiguedad" caption = "cantiguedad:" value = {compamyids.cantiguedad} placeholder="cantiguedad" />
                <InTextBox cid = "cdomicilio" caption = "cdomicilio:" value = {compamyids.cdomicilio} placeholder="cdomicilio" />
                <InTextBox cid = "ctelefono" caption = "ctelefono:" value = {compamyids.ctelefono} placeholder="ctelefono" />
                <InTextBox cid = "cregistro" caption = "cregistro:" value = {compamyids.cregistro} placeholder="cregistro" />
                <InTextBox cid = "cweb" caption = "cweb:" value = {compamyids.cweb} placeholder="cweb" />
                <InTextBox cid = "csector" caption = "csector:" value = {compamyids.csector} placeholder="csector" />
                <InTextBox cid = "cndeempleados" caption = "cndeempleados:" value = {compamyids.cndeempleados} placeholder="cndeempleados" />
                <InTextBox cid = "cdueno" caption = "cdueno:" value = {compamyids.cdueno} placeholder="cdueno" />
                <InTextBox cid = "cmatriz" caption = "cmatriz:" value = {compamyids.cmatriz} placeholder="cmatriz" />
                <InTextBox cid = "cnote" caption = "cnote:" value = {compamyids.cnote} placeholder="cnote" />
              </div>
            </div>
          </ModalInfo>
        </div>
    );
  }
}    

class InTextBox extends React.Component {
  constructor(props){
    super(props);
    this.state = { value : '' };
    this.updateInput = this.updateInput.bind(this);
  }
  
  updateInput(event){
    this.setState({value : event.target.value})
  }

  componentDidUpdate(prevProps) {
    // Типове використання (не забудьте порівняти пропси):
    if (this.props.value !== prevProps.value) {
      this.setState({value : this.props.value})
    }
  }

  render(){
  return (
        <label className="lbllist">
          <span className="textlabel">{this.props.caption}</span>
          <input className="frmtextbox" type="text" id = {this.props.cid} name={'tb_' + this.props.cid} value={this.state.value} onChange={this.updateInput} placeholder={this.props.placeholder}/>
        </label>
    );
  }
} 

function LogoCtrl(props) { 
  let ava2 = GostCompany;

  if (props.usrs === 'undefined' || props.usrs === 'new') {
    ava2 = GostCompany;
  } else {
    ava2 = "https://inupline.com/ssd/c" + props.usrs + ".jpg";
  }

  const [upimg, setupimg] = useState('');
  const [image, setImage] = useState('');
  const [convimage, setConvImage] = useState('');
  const [convimagem, setConvImageMini] = useState('');
  //const [urlfoto, setUrlFoto] = useState(GostAva); // foto default

  //console.log("https://inupline.com/ssd/" + props.usrs + ".jpg");
  

  function useDisplayImage() {
    const [result, setResult] = useState("");

    function uploader(e) {
        const imageFile = e.target.files[0];
        const cimage = resizeFile(imageFile);
        const cimageM = resizeFileMini(imageFile);
        setConvImage(cimage);
        setConvImageMini(cimageM);
        setupimg('1');
        console.log("add logo");
        const reader = new FileReader();
        reader.addEventListener("load", (e) => {
            setResult(e.target.result);
        });

        reader.readAsDataURL(imageFile);

    }
    return { result, uploader };
    
}

  const { result, uploader } = useDisplayImage();

  const resizeFile = (file) => new Promise(resolve => {
      Resizer.imageFileResizer(file, 300, 300, 'JPEG', 100, 0,
      uri => {
        resolve(uri);
        setConvImage(uri);
        console.log(uri);
      }, 'base64' );
  });

  const resizeFileMini = (file) => new Promise(resolve => {
      Resizer.imageFileResizer(file, 64, 64, 'JPEG', 100, 0,
      uri => {
        resolve(uri);
        setConvImageMini(uri);
        console.log(uri);
      }, 'base64' );
  });

  function reserts() {
      setConvImage(GostCompany);
  }

  function volvers() {
    ava2="https://inupline.com/ssd/c" + props.usrs + ".jpg";
    //ava2=GostAva;
    setConvImage('');
}

  return (
  <div className="imgprv">
      <div className="avatar-upload">
          <div className="avatar-edit">
              <input type='file' onChange={(e) => { setImage(e.target.files[0]); uploader(e);}} id="imageUpload"  name="image" accept=".png, .jpg, .jpeg" />
              <label htmlFor="imageUpload"></label> 
          </div>

          <div className="avatar-reset" onClick={() => { reserts()}}>
              <label></label> 
          </div>

          <div className="avatar-volver" onClick={() => { volvers()}}>
              <label></label> 
          </div>

          <div className="avatar-save" onClick={() => { UpdateFile(props.usrs,convimage,convimagem)}}>
              <label></label> 
          </div>

          <div id="imagePreview">
              <label htmlFor="ava" >LOGO </label>
              <img className="avatar-preview" id="ava" src={convimage ? convimage : ava2 }/>
          </div>
      </div>
  </div>
  )
}

function UpdateFile(comid,convimage,convimagem) {
  const formData = new FormData();
  formData.append("cmd", "savefile" );
  formData.append("user_id", comid);
  formData.append("company", "c");

      formData.append("file",  convimage );
      formData.append("filem",  convimagem );
      console.log("Con foto")
  
  //formData.append("foto", "1" );
  formData.append("tbl", "db_company" );
 
fetch(
'https://inupline.com/savefile.php',
{
  method: 'POST',
  body: formData,
}
)
  .then (response => {
      console.log(response);
      })

  .then((result) => {
      console.log('Success:', result);
      alert("save data " + result);
        MW1info();
  })
  .catch((error) => {
      console.error('Error:', error);
      alert("Error:" + error);
  });
}

function DelRecTable(comid, tablname) {
  var cmd = 'DelRecTable';

  var data = { 
    "cmd": cmd,
    "iid": comid,
    "tbl": tablname,
  };

  console.log(data);
  
  fetch('https://inupline.com/post.php',
    {
      method: 'POST',
      body: JSON.stringify(data)
    }
  )
    .then (response => {
        console.log(response);
        })

    .then((result) => {
        console.log('Success:', result);
        alert("DEL data");
        MW1info();
    })
    .catch((error) => {
        console.error('Error:', error);
    });
}

function SendFile(comid) {
  var euser_id = document.getElementById('euser_id').value;
  var cname = document.getElementById('cname').value;
  var ccif = document.getElementById('ccif').value;
  var cantiguedad = document.getElementById('cantiguedad').value;
  var cdomicilio = document.getElementById('cdomicilio').value;
  var ctelefono = document.getElementById('ctelefono').value;
  var cregistro = document.getElementById('cregistro').value;
  var cweb = document.getElementById('cweb').value;
  var csector = document.getElementById('csector').value;
  var cndeempleados = document.getElementById('cndeempleados').value;
  var cdueno = document.getElementById('cdueno').value;
  var cmatriz = document.getElementById('cmatriz').value;
  var cnote = document.getElementById('cnote').value;

  var cmd = 'UpdateTable';

  if (comid === 'new') { cmd = 'AddInTable'; } 

  var data = { 
    "cmd": cmd,
    "user_id": comid,
    "tbl": "db_company",
    'euser_id': euser_id, 
    'cname': cname, 
    'ccif': ccif, 
    'cantiguedad': cantiguedad, 
    'cdomicilio': cdomicilio, 
    'ctelefono': ctelefono, 
    'cregistro': cregistro, 
    'cweb': cweb, 
    'csector': csector,
    'cndeempleados': cndeempleados,
    'cdueno': cdueno,
    'cmatriz': cmatriz,
    'cnote': cnote,
  };

  fetch('https://inupline.com/post.php',
    {
      method: 'POST',
      body: JSON.stringify(data)
    }
  )
    .then (response => {
        console.log(response);
        })

    .then((result) => {
        console.log('Success:', result);
        alert("save data");
        MW1info();
    })
    .catch((error) => {
        console.error('Error:', error);
    });
}
