import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';

export const GetTXTUI = createAsyncThunk("TXTUI/GetTXTUI", async (lng) => {
    const cookies = new Cookies();
    console.log("lng", lng);
    lng = !lng ? "gb" : lng; 

    !cookies.get('lng_ui') && cookies.set('lng_ui', "gb", { path: '/' , maxAge: 2592000, sameSite: 'Strict', secure: true });

    console.log("NEW lng", cookies.get('lng_ui'));

    // if (cookies.get('lng_ui') === undefined ){
    //     //id = "gb" ;        
    //     cookies.set('lng_ui', "gb", { path: '/' , maxAge: 2592000, sameSite: 'Strict', secure: true }
    //   );
    // }

    return await fetch("https://inupline.com/post.php", {
        method: 'POST',
        header : {
            Accept: 'application/json', 'Content-Type': 'application/json',
        },
        credentials: "include", // This here
        body : JSON.stringify({
            'orden': 'lng',
            'lng': lng,
            'ids': [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27]
            //'ids': [2]
        })
        }).then((res)=> res.json());
  });

//   <LngMenu handleLng = {handleLng} lng = {!props.lng ? "gb" : props.lng }/>


const loadingTXT = createSlice ({
    name: 'TXTUI',
    initialState : { 
        txt: {
            id1: "WELCOM",
            id2: "web platform for business",
            id3: "login",
            id4: "about",
            id5: "contacts",
            id6: "Login with",
            id7: "What is there to do",
            id8: "1. Authorization with social networks (Google, Facebook ...) ",
            id9: "2. High SECURITY all data is protected in different Clouds",
            id10: "3. Very easy to Order and Share your service or work on social networks",
            id11: "4. Your CV thanks to QR-code more convenient to share with photo portfolio",
            id12: "5. All movements or a small change are registered!",
            id13: "6. Notices directly on mobile with and without App",
            id14: "18. Budgets with flexible adjustments",
            id15: "19. Customer and contact bases",
            id16: "21. Multilanguage",
            id17: "25. Personalized website",
            id18: "29. All documents have their digital copy with QR address",
            id19: "31. More time for life",
            id20: "34. Various tools are FREE",
            id21: "loading",
            id22: "main",
            id23: "exit",
            id24: "my profile",
            id25: "settings",
            id26: "history",
            id27: "exit"
        },
        loadingT: false
    },
    reducers: {},
    extraReducers: (builder) => { 
      builder
      .addCase (GetTXTUI.pending, (state) => { state.loadingT = false; })
      .addCase (GetTXTUI.fulfilled, (state, { payload }) => { state.loadingT = true; state.txt = payload; console.log(payload)})
      .addCase (GetTXTUI.rejected, (state) => { state.loadingT = false })
      },
  })


  export default loadingTXT;