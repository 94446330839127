import React , { useState, useEffect }  from 'react';
let hort = 0;
let hort2 = 0;
let hor = 0;
let hor2 = 0;
let hoe = 0;
let hoe2 = 0;
let hof = 0;
let hof2 = 0;
let hol = 0;
let hol2 = 0;

// 
let DatosInfo = {
    Mes: [
        {
          laboral: '', 
          nolaboral: '', 
          festivos: '', 
          puentes: '', 
          totalhoras: 0,
          workhoras: 0,
          extra: 0,
          falta: 0,
          mes: 0,
          ano: 0
        }
    ]
  };

export default function JornadaPage(props) {
    const [jsonResponse,setJsonRespons] = useState([]);
    const [mesHoras,setMesHoras] = useState([]);
    const [meslugar,setMesLugar] = useState([]);
    const [mesClient,setMesClient] = useState([]);
    const [mesCompany,setMesCompany] = useState();

    const GETJSON = (data) => {
        fetch(
          'https://inupline.com/post.php',
          {
            method: 'POST',
            header : {
              Accept: 'application/json', 'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          }
        )
          .then (response => response.json())
          .then (response => {
              setMesHoras(response);
              //console.log('setMesHoras:', response);

          })
      }
  
      
      const LoadHorasMes = () => {
          var data = { 
            "cmd": 'ReadHorasMes',
            "tbl": 'db_horario',
            "id_user": props.user.id,
            "c_mes": props.mes,
            "c_ano": props.ano
          };
          
          GETJSON(data);
      }

      const LoadDataClients = () => {
        var data = { 
          "cmd": 'ReadTableClient',
          "id_work": props.user.work, //miUser.work,
          "tbl": 'db_client'
        };
  
  
        fetch(
            'https://inupline.com/post.php',
            {
              method: 'POST',
              header : {
                Accept: 'application/json', 'Content-Type': 'application/json',
              },
              body: JSON.stringify(data)
            }
          )
            .then (response => response.json())
            .then (response => {
                //console.log('ReadTableClient:', response);

                setMesClient(response);
            })


        var data = { 
          "cmd": 'ReadTableLugar',
          "tbl": 'db_lugar'
        };
  
        fetch(
            'https://inupline.com/post.php',
            {
              method: 'POST',
              header : {
                Accept: 'application/json', 'Content-Type': 'application/json',
              },
              body: JSON.stringify(data)
            }
          )
            .then (response => response.json())
            .then (response => {
                //console.log('ReadTableLugar:', response);

                setMesLugar(response);
            })
      }

        useEffect(() => {
        LoadHorasMes();
        LoadDataClients();
        //console.log('this.props', props)
      }, [props.user.id, props.mes]); 
   
      const ShowDay = (item, cday, key) => {
      
        let print_m =  [];
  
        let print_w_1 = <TWeek key = {key + 'ws1'} week="S1"/>;
  
        let a_day = '';
        let a_client = '';
        let a_lugar = '';
        let a_horas = '';
        let id_lugar = '';
        let a_firm = '';
        let a_semana = '';
        let data = [];
  
        let s_l = <DeyJC data = {data} id = {key + 'sl'} key = {key + 'sl'} week = '' day = '' lugar='' horas = '' colors = '0'/>;
        let s_m = <DeyJC data = {data} id = {key + 'sm'} key = {key + 'sm'} week = '' day = '' lugar='' horas = '' colors = '0'/>;
        let s_x = <DeyJC data = {data} id = {key + 'sx'} key = {key + 'sx'} week = '' day = '' lugar='' horas = '' colors = '0'/>;
        let s_j = <DeyJC data = {data} id = {key + 'sj'} key = {key + 'sj'} week = '' day = '' lugar='' horas = '' colors = '0'/>;
        let s_v = <DeyJC data = {data} id = {key + 'sv'} key = {key + 'sv'} week = '' day = '' lugar='' horas = '' colors = '0'/>;
        let s_s = <DeyJC data = {data} id = {key + 'ss'} key = {key + 'ss'} week = '' day = '' lugar='' horas = '' colors = '0'/>;
        
        let colL = '0'; // колір заливки по замовчуваню
        let s = 1; // Перше число 
          
            mesHoras.map((itmlugar => {
            if (Number(item.day) === Number(itmlugar.c_dia) )  {
              a_day = itmlugar.c_dia;
              a_client = itmlugar.id_client;
              //a_lugar = itmlugar.id_lugar;
              id_lugar = itmlugar.id_lugar;
              a_horas = itmlugar.c_horas;
              a_firm= itmlugar.id_user_firm;
              data = itmlugar;
            }
          }));

          //let def = a_lugar;

          meslugar.map((itms => {
            if (Number(itms.id) === Number(id_lugar) )  {
                a_lugar = itms.c_lugar;
                //console.log(a_day, a_lugar )
            }
          }));



          if (Number(item.t) === 1) { colL = '0' }
          if (Number(item.l) === 1) { colL = '0' }
          if (Number(item.f) === 1) { colL = '2' }
          if (Number(item.p) === 1) { colL = '3' }
          if (Number(item.v) === 2) { colL = '4' }
          if (item.week === "s" || item.week === "d") { colL = '1' }
  
          // переміщення першого числа у відповідний день тижня
          if (item.week === "l" && Number(item.day) === s) { 
            print_m = [print_w_1, <DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar={a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/>]
          }
  
          if (item.week === "m" && Number(item.day) === s) { 
            print_m = [print_w_1,s_l,<DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar={a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/> ] 
          }
  
          if (item.week === "x" && Number(item.day) === s) { 
            print_m = [print_w_1,s_l,s_m,<DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar={a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/> ] 
          }
  
          if (item.week === "j" && Number(item.day) === s) { 
            print_m = [print_w_1,s_l,s_m,s_x,<DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar={a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/> ] 
          }
  
          if (item.week === "v" && Number(item.day) === s) { 
            print_m = [print_w_1,s_l,s_m,s_x,s_j,<DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar={a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/> ]
          }
  
          if (item.week === "s" && Number(item.day) === s) { 
            print_m = [print_w_1,s_l,s_m,s_x,s_j,s_v,<DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar={a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/> ] 
          }
  
          if (item.week === "d" && Number(item.day) === s) { 
            print_m = [print_w_1,s_l,s_m,s_x,s_j,s_v,s_s,<DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar={a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/> ]  
          }
          // розміщення інших чисел зразу після першого
          
          if (Number(item.day) > s ) { 
            if (item.week === "l" && Number(item.day) < 10  ) {  
              print_m = [<TWeek key = {key + 'ws2'} week={"S2"}/>, <DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar= {a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/>]
              //iw++;
              //this.setState({ iw: this.state.iw++ });
            } else if (item.week === "l" && Number(item.day) < 16 ) {  
              print_m = [<TWeek key = {key + 'ws3'} week={"S3"}/>, <DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar= {a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/>]
              //iw++;
              //this.setState({ iw: this.state.iw++ });
            } else if (item.week === "l" && Number(item.day) < 23 ) {  
              print_m = [<TWeek key = {key + 'ws4'} week={"S4"}/>, <DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar= {a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/>]
            } else if (item.week === "l" && Number(item.day) < 30 ) {  
              print_m = [<TWeek key = {key + 'ws5'} week={"S5"}/>, <DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar= {a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/>]
            } else if (item.week === "l" && Number(item.day) < 32 ) {  
              print_m = [<TWeek key = {key + 'ws6'} week={"S6"}/>, <DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar= {a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/>]
            } else {
              print_m = [<DeyJC data = {data} key = {key}  cday = {cday} semana = {item.semana} week = {item.week} day = {item.day} lugar= {a_lugar} horas = {a_horas} colors = {colL} status= {a_firm}/>]
            }
          }  
  
        return print_m
        // моніторин даних
        // передача даних
        
      }

      const calcday = (moon,year) => {
        let mess = moon;
        let mess2 = mess;
        
        let ano =  year;
        let ano2 = ano;
    
        if (mess === 12) { 
          mess2 = 1
          ano2 ++
        } else {
          mess2 ++
        }
    
        //const {Temporal} = temporal;
        var dateString = ano + "-" + mess + "-1"  //"2015-12-31";
        var day_start = new Date(dateString.replace(/-/g, "/"));
        var dateString2 = ano2 + "-" + mess2 + "-1"  //"2015-12-31";
        var day_end = new Date(dateString2.replace(/-/g, "/"));
    
    
        //let day_start = new Date(mess + " 1 " + ano);
        //let day_end = new Date(mess2 + " 1 " + ano2);
        let total_mes = day_start.getMonth()+1;
        let total_days = Number((day_end - day_start) / (1000 * 60 * 60 * 24)+1);
    
        let calender_items = [];
    
        let dayj = ['0','1','1','1','1','1','0']; 
        //var dayn = ['НЕДІЛЯ','ПОНЕДІЛОК','ВІВТОРОК','СЕРЕДА','ЧЕТВЕР','ПЯТНИЦЯ','СУБОТА'];   
        let dayes = ['d','l','m','x','j','v','s'];   
    
        let fie = ['mm dd yyyy','1 1 2022','1 6 2022','4 14 2022','4 15 2022','5 2 2022','7 25 2022','8 15 2022','10 12 2022','11 1 2022','12 6 2022','12 8 2022','12 26 2022']; 
        let puent = ['mm dd yyyy','10 31 2022','12 5 2022','12 7 2022','12 9 2022']; 
    
        let laboral = 0;
        let nolaboral = 0;
        let festivos = 0;
        let puentes = 0;
    
        let fiday = 0;
        let puday = 0;
    
        //console.log("total_mes " + total_mes);
    
        let week =1;
          for (let i = 1; i < total_days; i++) {
            var dateString3 = ano + "-" + mess + "-" + i;  //"2015-12-31";
            let today = new Date(dateString3.replace(/-/g, "/"));
            let dd   = today.getDate();
            let day  = dayj[today.getDay()];
            let dayjn  = dayes[today.getDay()];
    
            if (day === "1") { laboral ++;} else {nolaboral ++}
    
            for (let f = 0; f < fie.length ; f++) {
              //let day_fiestas = new Date(fie[f]);
              let day_fiestas = new Date(fie[f].replace(/-/g, "/"));
    
              if (today.getTime() === day_fiestas.getTime()) { 
                festivos ++; 
                fiday = 1;
              }
            } 
    
            for (let f = 0; f < puent.length; f++) {
              let day_puente = new Date(puent[f]);
              if (today.getTime() === day_puente.getTime()) { 
                puentes ++;
                puday = 1;
              }
            } 
    
            calender_items.push({'day': dd, 'week': dayjn, 'mes':total_mes, 'l':day, 'f':fiday, 'p':puday ,'semana' : week,'t' : 0}) 
            
            if (dayjn === "d") { week ++;} // розмічаємо тижні
            puday = 0;
            fiday = 0;
    
        }

        let clc = festivos + puentes
        DatosInfo.Mes.laboral = laboral - clc;
        DatosInfo.Mes.nolaboral = nolaboral;
        DatosInfo.Mes.festivos = festivos;
        DatosInfo.Mes.puentes = puentes;
        DatosInfo.Mes.mes = moon;
        DatosInfo.Mes.ano = year;
        //console.log('TOTAl HORAS ', DatosInfo.Mes)


        return calender_items;
      }

      const funcalhoras = (wday) => {
        mesHoras.map((itmlugar => {
          if (Number(wday.day) === Number(itmlugar.c_dia) )  {
            hor = (Number(hor) + Number(itmlugar.c_horas)); //semana
          }
        }));
    }

    const fastrrender = () => {
        const cday = new Date().getDate();

        let cmes = props.mes //new Date().getMonth() ; 
        let cano = props.ano //new Date().getFullYear();

        let cldata = calcday(Number(cmes),Number(cano));

        let drawweek = [];

            hort = 0;
            hor = 0;
            hol = 0;
            hoe = 0;
            hof = 0;

        for (var i = 0, len = cldata.length; i < len; i++) {
            if(cldata[i]){
                drawweek.push ( ShowDay(cldata[i], cday, "CAL" + cldata[i].day + i)); 
                funcalhoras(cldata[i]);
            }
          }

          if (DatosInfo !== undefined ){
            hort = Number(DatosInfo.Mes.laboral) * 8

            if (hor !== 0){
              if (hor > (Number(DatosInfo.Mes.laboral)* 8)){ 
                hol = Number(DatosInfo.Mes.laboral) * 8
              } else {
                hol = hor
              }
      
              if (hor > (Number(DatosInfo.Mes.laboral)* 8)){ hoe = hor - Number(DatosInfo.Mes.laboral) * 8 } else {hoe = 0}
              hof = (Number(DatosInfo.Mes.laboral) + Number(DatosInfo.Mes.puentes)) * 8 - hor
              
              //console.log(hor , hol , hoe, hof)
            } else {
              hol = 0
              hoe = 0
              hof = (Number(DatosInfo.Mes.laboral) + Number(DatosInfo.Mes.puentes)) * 8 - hor
              
              //console.log(hor , hol , hoe, hof)
            }
          }

          DatosInfo.Mes.totalhoras = hort;
          DatosInfo.Mes.workhoras = hor;
          DatosInfo.Mes.extra = hoe;
          DatosInfo.Mes.falta = hof;
          

          let c_horas = [];
          let c_all = [];

          mesHoras.map((itms => {
            if (Number(itms.id_client) === Number(props.cid) && 
            Number(itms.c_mes) === Number(props.mes) && 
            Number(itms.c_ano) === Number(props.ano) && 
            Number(itms.id_user) === Number(props.user.id))  {
              meslugar.map((luaga => {
                if (Number(luaga.id) === Number(itms.id_lugar) )  {
                  //c_lugar.push(luaga.c_lugar);
                  if(c_horas[luaga.id] === undefined) {c_horas[luaga.id] = 0}
                    c_horas[luaga.id] = Number(c_horas[luaga.id]) + Number(itms.c_horas);
                    let horall = Number(c_horas[luaga.id]);
                  if (Number(itms.c_horas) !== 0) {c_all[luaga.id] = ({'c_lugar': luaga.c_lugar, 'c_horas': horall});}
                }
              }));
            }
          }));
  
          props.handleCheng(DatosInfo.Mes, c_all);

        return drawweek;
      }

  return (
    <div className='CalendarInfo'>
        <div className="CalendarBox">
            <div className="SpaceCap"></div>
            <div className="DayCap"><p className='DayC'>Lunes</p> <span className='DayCM'>L</span> </div> 
            <div className="DayCap"><p className='DayC'>Martes</p> <span className='DayCM'>M</span></div>
            <div className="DayCap"><p className='DayC'>Miércoles</p> <span className='DayCM'>X</span></div>
            <div className="DayCap"><p className='DayC'>Jueves</p> <span className='DayCM'>J</span></div>
            <div className="DayCap"><p className='DayC'>Viernes</p> <span className='DayCM'>V</span></div>
            <div className="DayCap"><p className='DayC'>Sabado</p> <span className='DayCM'>S</span></div>
            <div className="DayCap"><p className='DayC'>Doningo</p> <span className='DayCM'>D</span></div>
                {
                fastrrender()
                }
        </div>
    </div>
  );
}

class TWeek extends React.Component{
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <div className="WeekCap">{this.props.week}</div>
      )
    }
  }

class DeyJC extends React.Component{ //extending Grid
    constructor(props) {
        super(props);
    }

    //          if (wday.day === wday.cday) { retrn =" DaySelect"; } else { retrn =this.StyleColor(this.props) + " DayLJC"; }

    StyleColor = (props) => {
      let srtclass = 'DayBox';
      if (props.day) { srtclass = "DayBox" } else { srtclass = "DayBoxFREE" }

      if (props.day && props.colors === "0") { srtclass = srtclass + " DB_laboral"; }
      if (props.day && props.colors === "1") { srtclass = srtclass + " DB_extra"; } //DB_festivo DB_active DB_puente DB_falta 
      if (props.day && props.colors === "2") { srtclass = srtclass + " DB_extra"; }
      if (props.day && props.colors === "3") { srtclass = srtclass + " DB_puente"; }

      if (props.day && props.horas === '0') { srtclass = srtclass + " DB_falta"; }
      if (props.day && props.horas === '') { srtclass = srtclass + " DB_falta"; }

      // if (props.colors === "4") { srtclass ="colVa"; }
      // if (props.colors === "5") { srtclass ="colfirmado"; }

      //console.log('srtclass',srtclass, props)

      return srtclass;

    }

    
    //pointer-events: none; //disable clicks
    render() {
        return (
        <div className={this.StyleColor(this.props)}  key = {"MHD" + this.props.day + this.props.semana} id = {this.props.day} data-sem = {this.props.semana} >
            <p className={this.props.day ? "DateDayBox" : ""}>{ this.props.day}</p>
            
            <p className={this.props.day ? "cattimeall" : ""}>{this.props.horas ? this.props.data.c_desde_1 + '-' + this.props.data.c_hasta_1 : "" }</p>

            <p className={this.props.horas ? "CapHoras" : ""}>{this.props.horas ? this.props.horas + 'h': ""}</p>
            <p className="CapLugar">{this.props.horas ? this.props.lugar : this.props.lugar}  {/*this.props.lugar*/}</p>
        </div>
        )
    }
    }