import './style.css';
import './flag-icon.css';
import Modal from 'react-modal';
import LngList from './LngList';
import React , { useState }  from 'react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

if (cookies.get('lng_ui') == undefined ){
  //id = "gb" ;        
  cookies.set('lng_ui', "gb", { path: '/' , maxAge: 2592000, sameSite: 'Strict', secure: true }
);
}

function LngMenu(props) {
  //const cookies = new Cookies();
    let degLng = props.lng //cookies.get('lng_ui');

    //let degLng = cookies.get('lng_ui');

    

    const [lngindex,setLngid] = useState(degLng);

    console.log("cookies.get('lng_ui')", cookies.get('lng_ui'))


    function lngSel(id) { // зантаження новиз зміних
      if (degLng !== id) {
        cookies.set('lng_ui', id, { path: '/' , maxAge: 2592000, sameSite: 'Strict', secure: true});
        degLng = id;
        props.handleLng (id);
      }
    }

    const customStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)',
          backgroundColor       : '#191919',
          zindex: '99999'
        }
      };

    function chLng() {
      //asd = degLng
      //console.log('asd', degLng ,'|')
      //setIsOpen(false);
      return "flag-icon flag-icon-" + degLng;
      
    }

    // MODAL ------------------
    Modal.setAppElement('*');
    
    var subtitle;
    const [modalIsOpen,setIsOpen] = useState(false);
    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      subtitle.style.color = '#f00';
    }
  
    function closeModal(){
      setIsOpen(false);
    }
   // --------- modal end

   function lngClicMouse(id) {
     setLngid (id);
     lngSel (id);
     setIsOpen(false);
   }
 
    return (
        <div >
            <button onClick={openModal} className="dropbtn lmenu">
              <span className={chLng()}></span>
            </button>

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                //contentLabel="Example Modal"
                //className="modal"
                //appElement={document.getElementById('app')}
            >

          <h2 ref={_subtitle => (subtitle = _subtitle)}></h2>
          <div>
            <LngList toSel = {lngindex} onToggle = {lngClicMouse} />
          </div>
                
        </Modal>

      </div>
    );
  }

  
export default LngMenu;