import './../style.css';
import '../../../css/widgsystem.css';
import './style.css';

import React , { useRef, useState, useEffect }  from 'react';
// import Draggable from 'react-draggable';

//import { myUsers, AppCaption} from "../../../redux/state";

const quickAndDirtyStyleOn = {
  left: "80px",
  top: "80px",
  width: "auto",
  height: "auto",
  // background: "#141414e1",
  color: "",
  display: "flex",
  position: "fixed",
  visibility: "visible",
  opacity:"1",
  tabIndex: "0"
}

const quickAndDirtyStyleOff = {
  visibility: "hidden",
  opacity:"1",
  height: "0px",
  width: "0px"
}

const jsonfilebase = {
  Name : '50%',
  coment : '50%',
  icobd : 'mdb', // MariaDB
  colortxt : '50%',
  colorfon: '50%',
  fontsizename : '20px',
  fontsizecoment : '12px',
  table: [{ 
      id: 0,
      top: 0,
      left: 0,
      name: "ACTRACCEPTTERMS",
      campos: [
        { name: `ACTRID`, type: "int(11)", NULL: "NOT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTREMPLOYEEID`, type: "int(11)", NULL: "NOT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRDATEACCEPTTERMS`, type: "datetime", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRVERSION`, type: "int(11)", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRDEVICEID`, type: "int(11)", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRPROCESSID`, type: "int(11)", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRDATECREATED`, type: "datetime", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRPROCESSID`, type: "varchar(256)", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRPROCESSID`, type: "datetime", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRPROCESSID`, type: "varchar(256)", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
      ]
    },{ 
      id: 1,
      top: 200,
      left: 700,
      name: "EMTKEMPLOYEETOKEN",
      campos: [
        { name: `ACTRID`, type: "int(11)", NULL: "NOT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTREMPLOYEEID`, type: "int(11)", NULL: "NOT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRDATEACCEPTTERMS`, type: "datetime", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRVERSION`, type: "int(11)", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRDEVICEID`, type: "int(11)", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRPROCESSID`, type: "int(11)", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRDATECREATED`, type: "datetime", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRPROCESSID`, type: "varchar(256)", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRPROCESSID`, type: "datetime", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRPROCESSID`, type: "varchar(256)", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
      ],
    },{ 
      id: 2,
      top: 400,
      left: 100,
      name: "ALUSALERTUSERSSETTINGS",
      campos: [
        { name: `ACTRID`, type: "int(11)", NULL: "NOT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTREMPLOYEEID`, type: "int(11)", NULL: "NOT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRDATEACCEPTTERMS`, type: "datetime", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRVERSION`, type: "int(11)", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRDEVICEID`, type: "int(11)", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRPROCESSID`, type: "int(11)", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRDATECREATED`, type: "datetime", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRPROCESSID`, type: "varchar(256)", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRPROCESSID`, type: "datetime", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
        { name: `ACTRPROCESSID`, type: "varchar(256)", NULL: "DEFAULT", AUTO_INCREMENT: true, COMMENT: '' },
      ],
    }]
}

function IndexUserHome(props) {  

    const [zoom, setZoom] = useState(100)
    const [position, setPosition] = useState({x: 100, y: 100})
    const [moveScreen, setMoveScreen] = useState(false)

    const [filebase, setFilebase] = useState([])


    const handleScroll = (event) => {
      // e.persist();
      //let tecla = event.which || event.key;

      
      window.addEventListener('touchstart', function(event) {
        // some logic
        event.preventDefault()
        event.stopPropagation();
        //if ( tecla === 85 ) { console.log("Ha presionado las teclas Ctrl + U"); }   
                // some other magic
      });

      if ( event.altKey ) {
             
          //console.log(event.deltaY); //event.ctrlKey && 
          if (event.altKey && event.deltaY > 0 && zoom > 25) {
            var zmx = zoom - 25
            setZoom( zmx );
            console.log("+");
          } else if (event.altKey &&  event.deltaY < 0 && zoom < 200 ) {
            var zmx = zoom + 25
            setZoom( zmx );
            console.log("-");
          }
        }
    };
 
    const mouseDownHandler = ( event ) => {
      if( event.button == 1 ) {
        setMoveScreen(true)
        // do something on middle mouse button click
      } 
    }

    const mouseUpHandler = ( event ) => {
      if( event.button == 1 ) {
        setMoveScreen(false)
        // do something on middle mouse button click
      }
    }

    const mouseMoveScreen = ( event ) => {
      if( moveScreen ) {
        //console.log(event.button, event.movementX, event.movementY); //event.ctrlKey && 
        // do something on middle mouse button click
        var zmstep = zoom / 1450

          if ( event.movementX ) { 
            var newtop = position.y 
            var newleft = position.x + event.movementX / zmstep
            setPosition({ x: newleft , y: newtop  })
          }

          if ( event.movementY ) { 
            var newtop = position.y + event.movementY / zmstep
            var newleft = position.x 
            setPosition({ x: newleft , y: newtop  })
          }
          
        
      }
    }


    const pull_data = (data) => {
      //console.log(data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)

      filebase.table[data.id].left = data.x - position.x
      filebase.table[data.id].top = data.y - position.y


      //console.log(filebase.table[data.id].top)
    }


    const handleKeyPress = (event) => {    
      if ( event.keyCode == 65 ) { 
        var newtop = position.y 
        var newleft = position.x - 180
        setPosition({ x: newleft , y: newtop  })
      }
      if ( event.keyCode == 68 ) { 
        var newtop = position.y 
        var newleft = position.x + 180
        setPosition({ x: newleft , y: newtop  })
      }
  
      if ( event.keyCode ==83 ) { 
        var newtop = position.y + 180
        var newleft = position.x 
        setPosition({ x: newleft , y: newtop  })
      }
  
      if ( event.keyCode == 87 ) { 
        var newtop = position.y - 180
        var newleft = position.x 
        setPosition({ x: newleft , y: newtop  })
      }

    }

    const loadjson = (data) => {
      //console.log(data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
      setFilebase ([])

      var vray = {"table":[]};
      var stepleft = 0
      data.map((lin,index) =>{ 
        //filebase.push(data[index])
        vray.table.push(data[index])
        vray.table[index].left = stepleft
        //setFilebase (filebase => [...filebase, data[index]])
        //setFilebase(filebase => [ ...filebase, {table: data[index]}]);
        stepleft = stepleft + 500
      })

      setFilebase(vray)

      console.log(vray); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)

      // filebase.table[0]  = data[10]
      // filebase.table[1]  = data[11]
      // filebase.table[2]  = data[12]

    }



    return (
      <div> 

        <div className="informe" id="informe">Zoom:{zoom}</div>
        <div className="informe" id="informe">X:{position.x}</div>
        <div className="informe" id="informe">Y:{position.y}</div>


        <div style={{top:"", zoom: zoom + "%"} } className="basedraw" onKeyDown={handleKeyPress} tabIndex={0} onWheel={handleScroll} onMouseDown={mouseDownHandler} onMouseUp={mouseUpHandler}  onMouseMove={mouseMoveScreen}>
            <div className="grid" id="myDraw" ></div>

            {filebase.table?.map((txt,index)=> 
              <TableDB key = {index} pos = {position} SendFunc={pull_data} ids = {index} show = {true} note = {index + " - " +txt.name} file={filebase.table} Fzoom = {zoom}> 
            </TableDB>
            )}


            {/* <TableDB pos = {position} SendFunc={pull_data} ids = {1} show = {true} caption = "EMUSUSERSSETTINGS 2" note = "Ajustes general del USUARIO para APP" file={filebase.table} Fzoom = {zoom}> 
            </TableDB>

            <TableDB pos = {position} SendFunc={pull_data} ids = {2} show = {true} caption = "EMUSUSERSSETTINGS 3" note = "Ajustes general del USUARIO para APP" file={filebase.table} Fzoom = {zoom}> 
            </TableDB> */}

          {/* <DraggableComponent ids = {2} show = {true} caption = "sd" >

            <TableDB show = {true}  caption = "EMUSUSERSSETTINGS" note = "Ajustes general del USUARIO para APP"> 
              <CampoTb PRIMARY_KEY = {true} type="INT" tmax= "11" campo = "EMUSID" note = "NOT_NULL, AUTO_INCREMENT, Autonumérico"/>
              <CampoTb type="123" tmax= "11" campo = "EMUSREGISTEREDUSERGUUDJOB" note = "true o false"/>
              <CampoTb type="ABC" tmax= "25" campo = "EMUSEMPLOYEEDOMAIN" note = "dominio de usuario"/>
              <CampoTb type="123" tmax= "11" campo = "EMUSEMPLOYEEID" note = "id de usuario"/>
            </TableDB>

          </DraggableComponent> */}



        </div>
        <TestSQL loadjson={loadjson}/>

    </div>
    );
  }

  
export default IndexUserHome;

const CampoTb = (props) => {
  return (
    <div className="campodb">
      <div className="boxin_db"></div>
      { props.PRIMARY_KEY ? "": <div className="triain_db"></div> }
      { props.PRIMARY_KEY ? <svg className="ico_key_db"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_iconCarrier"> <path d="M18.8646 7.3739C19.6046 10.3419 17.7022 13.3717 14.6156 14.1413C14.2291 14.2377 13.8425 14.294 13.4602 14.3131L10.025 19.7842C9.7502 20.222 9.31658 20.5363 8.81507 20.6613L6.84105 21.1535C6.30517 21.2871 5.76244 20.961 5.62883 20.4251L5.1835 18.639C5.05189 18.1111 5.14099 17.5524 5.43028 17.0917L8.5872 12.0637C8.1742 11.5092 7.86331 10.8688 7.68681 10.1608C6.94681 7.1929 8.84917 4.16302 11.9358 3.39343C15.0225 2.62384 18.1246 4.40596 18.8646 7.3739ZM14.2375 8.69804C14.9234 8.52702 15.3462 7.85371 15.1817 7.19417C15.0173 6.53463 14.3279 6.1386 13.642 6.30962C12.9561 6.48064 12.5333 7.15395 12.6978 7.81349C12.8622 8.47304 13.5516 8.86906 14.2375 8.69804Z" fill="rgb(255, 153, 0)"></path> </g></svg> : ""} 
      <div className="outer">
        <div className="inner"></div>
        <div className="inner2"></div>
      </div>
      <div className="ico_type_db">{props.type}</div>
      <div className="maxchr_type_db">{props.tmax}</div>
      <div className="title_campo_db">{props.campo}</div>
      <div className="coment_campo_db">{props.note}</div>
    </div>
  );
}

function TableDB (props) {
  const [step, setStep] = useState(18)
  const [pressed, setPressed] = useState(false)
  const [caption, setCaption] = useState("TABLE")
  const [visibl, setVisibl] = useState(props.show)
  //const [position, setPosition] = useState({x: props.file.top, y: props.file.left})
  const [posWorld, setPosWorld] = useState({x: props.posx, y: props.posy})
  const [position, setPosition] = useState({x: 0, y: 0, id: null})
  const [sposition, setSposition] = useState({x: 0, y: 0})


  
  useEffect(() => {
    const element = document.getElementById("sqlTable_" + props.ids);
    element.style.left = position.x + 'px';
    element.style.top  = position.y + 'px';
    //props.setUpdateValue("@@@");

    //props.SendFunc('My name is Dean Winchester & this is my brother Sammie');

  }, [position])

   useEffect(() => {
    // setPosWorld({ 
    //   x: props.pos.x,  
    //   y: props.pos.y
    // })

    setPosition({ 
      id: props.ids,
      x: props.file[props.ids].left + props.pos.x,  
      y: props.file[props.ids].top + props.pos.y
    })

    //console.log("POsit", props.ids,props.file[props.ids].left, props.pos.x)

    setCaption( props.file[props.ids].name )

    // console.log("useEffect",props.ids, position)
  }, [props])

  // useEffect(() => {
  //   setPosition({ 
  //     x: props.file[props.ids].left + posWorld.x,  
  //     y: props.file[props.ids].top + posWorld.y
  //   })

  //   //console.log("useEffect",props.ids, position)
  // }, [props])


  useEffect( () => {
    setVisibl(props.show);
  })

  const onMouseDown = (event) => {
    
      setPressed(true)
      //var zmstep = props.Fzoom / 100
      const element = document.getElementById("sqlTable_" + props.ids);
      let pos1 =  (element.offsetLeft - event.clientX) ;   // X
      let pos2 =  (element.offsetTop - event.clientY) ;    // Y
      setSposition({x: pos1, y: pos2})

    //props.setUpdate(pos1);
    //console.log("useEffect",props.ids, position)

      // do something on middle mouse button click
    

  }  

  // Update the current position if mouse is down
  const onMouseMove = (event) => {
    if (pressed ) { 
      //console.log(props.Fzoom /100)
      //var zmstep = props.Fzoom / 100
      if (props.Fzoom == 25) {setStep(4)}  

      var mousePosition = {
        x :  (event.clientX + sposition.x), //event.clientX,
        y :  (event.clientY + sposition.y)//event.clientY
      };

      let sx = parseInt( mousePosition.x / step) * step;
      let sy = parseInt( mousePosition.y / step) * step;

      setPosition({ id: props.ids, x: sx , y: sy  })

      // Conteiner.style.left = Mx + 'px';
      // Conteiner.style.top  = My + 'px';
    }
  }

  const handleKeyPress = (event) => {    
    if ( event.keyCode === 38 ) { 
      var newtop = position.y - 18
      var newleft = position.x
      setPosition({  id: props.ids, x: newleft , y: newtop  })
    }
    if ( event.keyCode === 40 ) { 
      var newtop = position.y + 18
      var newleft = position.x
      setPosition({  id: props.ids, x: newleft , y: newtop  })
    }

    if ( event.keyCode === 39 ) { 
      var newtop = position.y
      var newleft = position.x + 18
      setPosition({  id: props.ids, x: newleft , y: newtop  })
    }

    if ( event.keyCode === 37 ) { 
      var newtop = position.y
      var newleft = position.x - 18
      setPosition({  id: props.ids, x: newleft , y: newtop  })
    }
    //console.log("key pr",event.key )
    props.SendFunc(position)
  };

  return ( 
    
    <div style={ !visibl ? quickAndDirtyStyleOff : quickAndDirtyStyleOn} id={"sqlTable_" + props.ids} onKeyDown={handleKeyPress} tabIndex={0}>
        <div className="tabledb">
          <div className="campotitledb prevent-select" onMouseMove={ (e) => { onMouseMove(e); props.SendFunc(position)}  }
                                                onMouseDown={(e) => { onMouseDown(e); props.SendFunc(position)}}
                                                onMouseUp={ () => {setPressed(false); props.SendFunc(position) }} >
              <svg className="icodb" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 118.96">
                <title>frame-grid</title>
                <path d="M3.27,0H119.61a3.26,3.26,0,0,1,3.27,3.27V115.69a3.26,3.26,0,0,1-3.27,3.27H3.27A3.26,3.26,0,0,1,0,115.69V3.27A3.26,3.26,0,0,1,3.27,0ZM116.34,81.49H84.1v30.93h32.24V81.49Zm0-37.48H84.1V75h32.24V44ZM84.1,6.54V37.47h32.24V6.54ZM77.56,81.49H45.32v30.93H77.56V81.49Zm0-37.48H45.32V75H77.56V44ZM45.32,6.54V37.47H77.56V6.54ZM38.78,81.49H6.54v30.93H38.78V81.49Zm0-37.48H6.54V75H38.78V44Zm0-37.47H6.54V37.47H38.78V6.54Z"/>
            </svg>
            <div className="titledb">{props.file[props.ids].name}</div>
          </div>
          <div onMouseMove={ () => setPressed(false) }>
              {props.file[props.ids].campos.map((txt,index)=> 
                <CampoTb key = {index} type={txt.type} tmax= "11" campo = {txt.name} note = {txt.type + txt.comment }/>
              )}
            {/* {props.children} */}
          </div>
        <div className="tablenotedb">{props.note}</div>
     </div>
  </div>
  );
}


// const DraggableComponent = (props) => {
//   const [step, setStep] = useState(18)
//   const [pressed, setPressed] = useState(false)value
//   const [visibl, setVisibl] = useState(props.show)
//   const [position, setPosition] = useState({x: 200, y: 200})
//   const [sposition, setSposition] = useState({x: 200, y: 200})

//   //const ref = useRef()

//   useEffect(() => {
//     const element = document.getElementById("myModal_" + props.ids);

//     element.style.left = position.x + 'px';
//     element.style.top  = position.y + 'px';

//     // if (ref.current) {
//     //   //ref.current.style.transform = `translate(${position.x}px, ${position.y}px)`
//     //   // ref.current.style.left = `${position.x}px`
//     //   // ref.current.style.top = `${position.y}px`
//     // }
//   }, [position])

//   useEffect( () => {
//     setVisibl(props.show);
//   })

//   const onMouseDown = (event) => {

//     setPressed(true)

//     const element = document.getElementById("myModal_" + props.ids);
//     let pos1 =  element.offsetLeft - event.clientX ;   // X
//     let pos2 =  element.offsetTop - event.clientY ;    // Y
//     setSposition({x: pos1, y: pos2})
//   }  

//   // Update the current position if mouse is down
//   const onMouseMove = (event) => {
//     if (pressed) { 

//       var mousePosition = {
//         x :  event.clientX + sposition.x, //event.clientX,
//         y :  event.clientY + sposition.y //event.clientY
//       };

//       let sx = parseInt( mousePosition.x / step) * step;
//       let sy = parseInt( mousePosition.y / step) * step;

//       setPosition({ x: sx, y: sy })

//       // Conteiner.style.left = Mx + 'px';
//       // Conteiner.style.top  = My + 'px';
//     }
//   }

  
//   return (
//     <div
//       // ref={ ref }
//       style={ !visibl ? quickAndDirtyStyleOff : quickAndDirtyStyleOn} id={"myModal_" + props.ids}
//       >

//         <div id="myModal" className="modal fade">
//             <div className="modal-dialog">
//                 <div className="modal-content">
//                     <div className="modal-header" onMouseMove={ onMouseMove }
//                                                   onMouseDown={ onMouseDown }
//                                                   onMouseUp={ () => setPressed(false) }>
//                       <h4 className="modal-title">{props.caption}</h4>  
//                     </div>
//                     <div className="modal-body" onMouseMove={ () => setPressed(false) }>
//                         {props.children}
//                     </div>
                    
//                 </div>
//             </div>
//         </div>
//     </div>
//   )
// }

const sqltable = (myarray , index) => {
        //if (value) {
            const testtable = myarray[index].toString().split(',')
            const NEWtesttable = testtable.filter((item) => item !== '')
            const sinentr = NEWtesttable.toString().replaceAll('\r\n', ' ')
            const sinentr1 = sinentr.toString().replaceAll("`", '')
            const sinentr2 = sinentr1.toString().replaceAll(' ,', ',')
            const sinentr3 = sinentr2.toString().replaceAll(', ', ',')

            //console.log(sinentr3); // START

            const NEWTT = sinentr3.split(' ')
            const linesAll  = NEWTT.filter((item) => item[0])
        
            // linesAll.filter(el => el != null) // 99% - 100%
            // linesAll.filter(el => el != undefined) // 98% - 98%
            // linesAll.filter(el => el !== '') // 86% - 86%
            // linesAll.filter(el => el) // 86% - 86%
            var TableName = ""
            var startBody = "", endBody = ""
            var PrimaryIDS = "", startPrimary = "", endPrimary = "-1" // Primary keys

            linesAll.map((lin,index) =>{ 
                if (linesAll[index] === "CREATE" && linesAll[index+1] === "TABLE" && linesAll[index+3] === "(" ) { // delete comentarios
                    //console.log("Table:", linesAll[index+2]); 
                    TableName = linesAll[index+2]
                }
                if (linesAll[index] === "CREATE" && linesAll[index+1] === "TABLE" && linesAll[index+6] === "(" ) { // delete comentarios
                    //console.log("Table:", linesAll[index +5]); 
                    TableName = linesAll[index+5]
                }

                if (linesAll[index] === "(" ) { // delete comentarios
                    //console.log("START BODY", index ); 
                    startBody = index
                    //linesAll.splice(0, linesAll[index].length );
                }

                if (linesAll[index] === ")" ) { // delete comentarios
                    //console.log("END BODY", index ); 
                    endBody = index
                    //linesAll.splice(0, linesAll[index].length );
                }

                if (linesAll[index] === "PRIMARY" && linesAll[index +1] === "KEY" ) { // PRIMARY KEY
                    var startProFind = '(';
                    var idx = linesAll[index+2].indexOf(startProFind);
                    if (idx !== "-1") {
                        startPrimary = index+2
                    }
                }

            })

            //console.log(linesAll)
            var campos = "" , camposScan = true // CAMPOS
            var parametros = "" // PARAMETROS DE LA TABLA

            linesAll.map((lin,index) =>{ 
                if ( startBody < index && endBody > index ) {  // || && endBody > index
                    //console.log("CAMPO TABLE",  linesAll[index]); 
                    if (linesAll[index] === "PRIMARY" && linesAll[index + 1] === "KEY") { 
                        camposScan = false
                    }

                    if (camposScan ){ campos = campos + " " + linesAll[index] }
                }

                if ( endBody +1 <= index && linesAll.length  > index ) {  // || && endBody > index
                    //console.log("CAMPO TABLE",  linesAll[index]); 
                    linesAll[index] = linesAll[index].toString().replaceAll(' ', '')
                    parametros = parametros + linesAll[index] + " "
                    //endBody = index
                    //linesAll.splice(0, linesAll[index].length );
                }
                

                if ( startPrimary <= index && endPrimary === "-1") { // delete comentarios
                    PrimaryIDS = PrimaryIDS + linesAll[index]
                    var endProFind = ')';
                    var ida = linesAll[index].indexOf(endProFind);
                    if (ida !== "-1") {
                        endPrimary = index
                    }

                }
            })

            // FILTROS
            PrimaryIDS = PrimaryIDS.toString().replaceAll(')', '')
            PrimaryIDS = PrimaryIDS.toString().replaceAll('(', '')
            //PrimaryIDS = PrimaryIDS.toString().replaceAll(' ', '')

            //console.log("PRIMARI KEYs",  PrimaryIDS)
            campos = campos.split(',')
            campos = campos.filter((item) => item[0])

            campos.map((lin,index) =>{ 
                const capparam = campos[index].split(' ').filter((item) => item[0])
                //console.log("CAMPO ",  capparam[0]); 
                var StepLine = "", comments = "", nulls = "" , pkey = ""

                capparam.map((lin,idx) =>{ 
                    if (lin === "COMMENT") { StepLine = index} 
                    if (StepLine === index) {
                        comments = comments + " " + lin
                    } 

                    if (capparam[idx] === "NULL" || capparam[idx] === "NULL,") { 
                        //console.log("z", capparam[idx -1] , index);
                        nulls = capparam[idx -1];
                    }

                    if (capparam[idx] === "PRIMARY") { 
                        
                        //console.log("z", capparam[idx + 1] , index);

                        if (capparam[idx + 1] === "KEY," || capparam[idx + 1] === "KEY") { 
                            //console.log("z", capparam[idx -1] , index);
                            pkey = capparam[idx] + " " + capparam[idx +1];
                        }
                    }

                })

                campos[index] = {"NAME" : capparam[0] ,'TYPE':  capparam[1] , 'NULL': nulls, 'COMMENT' : comments , "PK" : pkey}
            })
        
            // console.log("campos", campos ); 

            const updatedJSON = {
                "tabla": TableName,
                'param' : parametros,
                'campos' :campos
            }

            //setTablejson(JSON.stringify(updatedJSON));

            console.log(updatedJSON);

        //}
    }


    

    
export const  TestSQL = props => {
  //const counter = useSelector((state)=> state.counter.counter);
  const [value, setValue] = useState([]);
  const [table, setTable] = useState([]);
  const [tableALL, setTableALL] = useState([]);

  const [tablejson, setTablejson] = useState([]);

  useEffect(() => {
      fetch('./img/estructura.sql')
      .then(row => row.text())
      .then(text => {
          setValue(text);
      });
      console.log("Loading file ! "); 
    }, []);

    useEffect(() => {
      if (value[0]) { 
          setTable(value.split(';'))  
          console.log("Loading table ! "); 
      }
    }, [value]);

    const loadallsql = () => {
      setTablejson ([])
      var jsonarray = [];
      table.map((lin,index) =>{ 
          table[index] = delline(lin);
      })
      const NEWtesttable = table.filter((item) => item[0])

      NEWtesttable.map((lin,index) =>{ 
        setTablejson (tablejson => [...tablejson, sqltable(NEWtesttable, index)])
        //jsonarray.push (sqltable(NEWtesttable, index))
      })

      //setTableALL(jsonarray)
      props.loadjson(tablejson);
      //console.log(tablejson);
    }
    
  const loadsql = (index) => {
      if (value) {
      const testtable = table[index].toString().split(',')
      const NEWtesttable = testtable.filter((item) => item !== '')
      const sinentr = NEWtesttable.toString().replaceAll('\r\n', ' ')
      const sinentr1 = sinentr.toString().replaceAll("`", '')
      const sinentr2 = sinentr1.toString().replaceAll(' ,', ',')
      const sinentr3 = sinentr2.toString().replaceAll(', ', ',')

      const NEWTT = sinentr3.split(' ')

      const linesAll  = NEWTT.filter((item) => item[0])
      
      var TableName = ""
      var startBody = "", endBody = ""
      var PrimaryIDS = "", startPrimary = "", endPrimary = "-1" // Primary keys

      linesAll.map((lin,index) =>{ 
          if (linesAll[index] === "CREATE" && linesAll[index+1] === "TABLE" && linesAll[index+3] === "(" ) { // delete comentarios
              console.log("Table:", linesAll[index+2]); 
              TableName = linesAll[index+2]
          }
          if (linesAll[index] === "CREATE" && linesAll[index+1] === "TABLE" && linesAll[index+6] === "(" ) { // delete comentarios
              console.log("Table:", linesAll[index +5]); 
              TableName = linesAll[index+5]
          }

          if (linesAll[index] === "(" ) { // delete comentarios
              console.log("START BODY", index ); 
              startBody = index
              //linesAll.splice(0, linesAll[index].length );
          }

          if (linesAll[index] === ")" ) { // delete comentarios
              console.log("END BODY", index ); 
              endBody = index
              //linesAll.splice(0, linesAll[index].length );
          }

          if (linesAll[index] === "PRIMARY" && linesAll[index +1] === "KEY" ) { // PRIMARY KEY
              var startProFind = '(';
              var idx = linesAll[index+2].indexOf(startProFind);
              if (idx !== "-1") {
                  startPrimary = index+2
              }
          }
      })

      //console.log(linesAll)
      var campos = "" , camposScan = true // CAMPOS
      var parametros = "" // PARAMETROS DE LA TABLA

      linesAll.map((lin,index) =>{ 
          if ( startBody < index && endBody > index ) {  // || && endBody > index
              //console.log("CAMPO TABLE",  linesAll[index]); 
              if (linesAll[index] === "PRIMARY" && linesAll[index + 1] === "KEY") { 
                  camposScan = false
              }

              if (camposScan ){ campos = campos + " " + linesAll[index] }
          }

          if ( endBody +1 <= index && linesAll.length  > index ) {  // || && endBody > index
              //console.log("CAMPO TABLE",  linesAll[index]); 
              linesAll[index] = linesAll[index].toString().replaceAll(' ', '')
              parametros = parametros + linesAll[index] + " "
              //endBody = index
              //linesAll.splice(0, linesAll[index].length );
          }
          

          if ( startPrimary <= index && endPrimary === "-1") { // delete comentarios
              PrimaryIDS = PrimaryIDS + linesAll[index]
              var endProFind = ')';
              var ida = linesAll[index].indexOf(endProFind);
              if (ida !== "-1") {
                  endPrimary = index
              }

          }
      })

      // FILTROS
      PrimaryIDS = PrimaryIDS.toString().replaceAll(')', '')
      PrimaryIDS = PrimaryIDS.toString().replaceAll('(', '')
      //PrimaryIDS = PrimaryIDS.toString().replaceAll(' ', '')

      campos = campos.split(',')
      campos = campos.filter((item) => item[0])

      campos.map((lin,index) =>{ 
          const capparam = campos[index].split(' ').filter((item) => item[0])
          //console.log("CAMPO ",  capparam[0]); 
          var StepLine = "", comments = "", nulls = "" , pkey = ""

          capparam.map((lin,idx) =>{ 
              if (lin === "COMMENT") { StepLine = index} 
              if (StepLine === index) {
                  comments = comments + " " + lin
              } 

              if (capparam[idx] === "NULL" || capparam[idx] === "NULL,") { 
                  //console.log("z", capparam[idx -1] , index);
                  nulls = capparam[idx -1];
              }

              if (capparam[idx] === "PRIMARY") { 
                  
                  console.log("z", capparam[idx + 1] , index);

                  if (capparam[idx + 1] === "KEY," || capparam[idx + 1] === "KEY") { 
                      //console.log("z", capparam[idx -1] , index);
                      pkey = capparam[idx] + " " + capparam[idx +1];
                  }
              }

          })

          campos[index] = {"NAME" : capparam[0] ,'TYPE':  capparam[1] , 'NULL': nulls, 'COMMENT' : comments , "PK" : pkey}
      })
      
      // console.log("campos", campos ); 

      const updatedJSON = {
          "tabla": TableName,
          'param' : parametros,
          'campos' :campos
      }

      //setTablejson(JSON.stringify(updatedJSON));

      console.log(updatedJSON);

      return updatedJSON;
      }
  }

  const sqltable = (myarray , index) => {
      if (value) {
          const testtable = myarray[index].toString().split(',')
          const NEWtesttable = testtable.filter((item) => item !== '')
          const sinentr = NEWtesttable.toString().replaceAll('\r\n', ' ')
          const sinentr1 = sinentr.toString().replaceAll("`", '')
          const sinentr2 = sinentr1.toString().replaceAll(' ,', ',')
          const sinentr3 = sinentr2.toString().replaceAll(', ', ',')

          //console.log(sinentr3); // START

          const NEWTT = sinentr3.split(' ')
          const linesAll = NEWTT.filter((item) => item[0])
      
          // linesAll.filter(el => el != null) // 99% - 100%
          // linesAll.filter(el => el != undefined) // 98% - 98%
          // linesAll.filter(el => el !== '') // 86% - 86%
          // linesAll.filter(el => el) // 86% - 86%
          var TableName = ""
          var startBody = "", endBody = ""
          var PrimaryIDS = "", startPrimary = "", endPrimary = "-1" // Primary keys

          linesAll.map((lin,index) =>{ 
              if (linesAll[index] === "CREATE" && linesAll[index+1] === "TABLE" && linesAll[index+3] === "(" ) { // delete comentarios
                  //console.log("Table:", linesAll[index+2]); 
                  TableName = linesAll[index+2]
              }
              if (linesAll[index] === "CREATE" && linesAll[index+1] === "TABLE" && linesAll[index+6] === "(" ) { // delete comentarios
                  //console.log("Table:", linesAll[index +5]); 
                  TableName = linesAll[index+5]
              }

              if (linesAll[index] === "(" ) { // delete comentarios
                  //console.log("START BODY", index ); 
                  startBody = index
                  //linesAll.splice(0, linesAll[index].length );
              }

              if (linesAll[index] === ")" ) { // delete comentarios
                  //console.log("END BODY", index ); 
                  endBody = index
                  //linesAll.splice(0, linesAll[index].length );
              }

              if (linesAll[index] === "PRIMARY" && linesAll[index +1] === "KEY" ) { // PRIMARY KEY
                  var startProFind = '(';
                  var idx = linesAll[index+2].indexOf(startProFind);
                  if (idx !== "-1") {
                      startPrimary = index+2
                  }
              }

          })

          //console.log(linesAll)
          var campos = "" , camposScan = true // CAMPOS
          var parametros = "" // PARAMETROS DE LA TABLA

          linesAll.map((lin,index) =>{ 
              if ( startBody < index && endBody > index ) {  // || && endBody > index
                  //console.log("CAMPO TABLE",  linesAll[index]); 
                  if (linesAll[index] === "PRIMARY" && linesAll[index + 1] === "KEY") { 
                      camposScan = false
                  }

                  if (camposScan ){ campos = campos + " " + linesAll[index] }
              }

              if ( endBody +1 <= index && linesAll.length  > index ) {  // || && endBody > index
                  //console.log("CAMPO TABLE",  linesAll[index]); 
                  linesAll[index] = linesAll[index].toString().replaceAll(' ', '')
                  parametros = parametros + linesAll[index] + " "
                  //endBody = index
                  //linesAll.splice(0, linesAll[index].length );
              }
              

              if ( startPrimary <= index && endPrimary === "-1") { // delete comentarios
                  PrimaryIDS = PrimaryIDS + linesAll[index]
                  var endProFind = ')';
                  var ida = linesAll[index].indexOf(endProFind);
                  if (ida !== "-1") {
                      endPrimary = index
                  }

              }
          })

          // FILTROS
          PrimaryIDS = PrimaryIDS.toString().replaceAll(')', '')
          PrimaryIDS = PrimaryIDS.toString().replaceAll('(', '')
          //PrimaryIDS = PrimaryIDS.toString().replaceAll(' ', '')

          //console.log("PRIMARI KEYs",  PrimaryIDS)
          campos = campos.split(',')
          campos = campos.filter((item) => item[0])

          campos.map((lin,index) =>{ 
              const capparam = campos[index].split(' ').filter((item) => item[0])
              //console.log("CAMPO ",  capparam[0]); 
              var StepLine = "", comments = "", nulls = "" , pkey = ""

              capparam.map((lin,idx) =>{ 
                  if (lin === "COMMENT") { StepLine = index} 
                  if (StepLine === index) {
                      comments = comments + " " + lin
                  } 

                  if (capparam[idx] === "NULL" || capparam[idx] === "NULL,") { 
                      //console.log("z", capparam[idx -1] , index);
                      nulls = capparam[idx -1];
                  }

                  if (capparam[idx] === "PRIMARY") { 
                      
                      //console.log("z", capparam[idx + 1] , index);

                      if (capparam[idx + 1] === "KEY," || capparam[idx + 1] === "KEY") { 
                          //console.log("z", capparam[idx -1] , index);
                          pkey = capparam[idx] + " " + capparam[idx +1];
                      }
                  }

              })

              campos[index] = {"name" : capparam[0] ,'type':  capparam[1] , 'null': nulls, 'comment' : comments , "pk" : pkey}
          })
      
          // console.log("campos", campos ); 

          const updatedJSON = {
              "name": TableName,
              'param' : parametros,
              'campos' :campos,
              "comment": "",
              "id": index,
              "top": 0,
              "left": 0,
          }

          return updatedJSON;
          //setTablejson(JSON.stringify(updatedJSON));

          //console.log(updatedJSON);

      }
  }

  
  const elimComent2 = (txt) => {
      // Encontrar lineas
      const myArray = txt.split("\r\n");
      // Seperar las lineas
      //var posstart = myArray.indexOf("--");
      //console.log("x", posicionCaracter);

      const palabras = myArray.map((txt, index) => {

      } );

      var posend = myArray.indexOf("\r\n");

      console.log(myArray)

      //console.log("elimComent2", posicionCaracter1 , txt.slice(posicionCaracter1))
      //console.log("ch:" + txt.length,posstart,posend, txt.substring(posstart, posend))

      //return posicionCaracter=="-1" ? txt : txt.substring(0, 0); 
  };

  const DeleteFromArray = (array, id) => {
      if (array.includes(id)){
          array = array.filter(ids => ids!==id)
      } else {
          array.push(id);
      }
  }

  const delline = (array) => {
      var posStart = "", posEnd =""
      var  myArray = array.toString().split("\r\n");

      //ELIMINAR COMENTS 1
      myArray.map((txt, index) => {
          var posend = myArray[index].indexOf("--");
          var posend1 = myArray[index].indexOf("/*");

          if (posend === 0 || posend1 === 0 ) { 
              myArray[index] = ""; 
          }

          // if (posend === -1 ) { 
          //     myArray[index] = txt; 
          // }
      } );
      myArray = myArray.filter((item) => item[0])

      //console.log(myArray)


      return myArray.join('\r\n') //${myArray} myArray.toString()


      //console.log(myArray)

  };


  
  return (
    <>
          {/* <button onClick={() => loadsql(8)}>load one</button> */}
          <button className="btnboxgoogle" onClick={() => loadallsql()}>load all</button>
    </>
  );
}


