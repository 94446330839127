import '../style.css';

import React , { useState, useEffect }  from 'react';
import Cookies from 'universal-cookie';

import PropTypes from 'prop-types'

function FirstFinish({tcapt}) {

  const cookies = new Cookies();
 
  function updatecaption(){
    tcapt('Finish');
    }

    useEffect(() => {
        updatecaption();
        // eslint-disable-next-line
    }, []); // AUTOLOAD - завантажує користувача із кешу

    function runfin(){
      window.location.reload(false);
  }

  return (
    <div className="appcard">
        <h1 className="h1welcom">Gracias </h1>
            
        <div className = "bcard">
            <p>Has creado una cuenta</p> 
            <p>En la plataforma</p> 
            <p>Pulsa para empezar</p> 
            <p></p> 

            <button className="btnboxmenu" onClick={runfin}>EMPEZAR</button>

        </div>
    </div>
  );
}

FirstFinish.prototype = {
    tcapt: PropTypes.func.isRequired,
}

export default FirstFinish;
