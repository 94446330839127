
import './mapstogo.css';
import React from "react";
import $ from 'jquery';

export default class IndexUserHome extends React.Component{
    constructor(props) {
      super(props)
      this.state = {
          userids : [] // sel user id  
        };
        this.updateIds = this.updateIds.bind(this)
      }
      
    updatecaption(){
      //tcapt ('USER EDITOR');v
      //AppCaption('WEB EDITOR');
      //myUsers.callUser();
    }
  
    useropenid(){
      //console.log('work');
    }
  
    updateIds = (ids) => {
      //MW1info(ids); // show modal
    }
  
    componentDidMount() {
      
        var $mainContent = document.querySelector('.main-content');
        var $listsWrap = document.querySelector('.lists-wrap');
        var $lists = document.querySelector('.lists');
        var $list = document.querySelectorAll('.list');

        $lists.style.width = this.props.lwidth + '%';
        $lists.style.setProperty("--marg-lft", this.props.margin + '%');

        //console.log('--marg-lft ' + $lists.style.getPropertyValue("--marg-lft"));

        if ( 1 < $list.length ) {
            var _listGap = $list[1].getBoundingClientRect().left - $list[0].getBoundingClientRect().left; // 15% margin-left
          } else {
            var _listGap = $list[0].getBoundingClientRect().left; // 15% margin-left
          }

        //var _listGap = $list[1].getBoundingClientRect().left - $list[0].getBoundingClientRect().left; // 15% margin-left
        var _listLen = $list.length;
        
        var currentIndex = 0;
        var newIndex = 0;
          
        var switcher = function(_newIndex) {
        newIndex = _newIndex;
    
        // avoid switching to currentItem
        if (newIndex === currentIndex) { return; }
            $lists.style.transform = 'translateX(-' + _listGap * (newIndex) + 'px)';
            currentIndex = _newIndex;
        };
    
      $($lists).on('click', '.list', function(event) {
        switcher($(this).index());
      });
    
      // swipe detect ref.:  http://stackoverflow.com/a/23230280
    
      $listsWrap.addEventListener('touchstart', handleTouchStart, true);
      $listsWrap.addEventListener('touchmove', handleTouchMove, true);
    
      $mainContent.addEventListener('mousedown', handleTouchStart, true);
      $mainContent.addEventListener('mousemove', handleTouchMove, true);
      $mainContent.addEventListener('mouseup', handleTouchEnd, true);
    
      var xDown = null;
      var yDown = null;
    
      function handleTouchStart(evt) {
        // console.log('handleTouchStart');
    
        if ('touchstart' === evt.type) {
          xDown = evt.touches[0].clientX;
          yDown = evt.touches[0].clientY;
        } else {
          xDown = evt.clientX;
          yDown = evt.clientY;
        }
      }
    
      function handleTouchEnd(evt) {
        xDown = null;
        yDown = null;
      }
    
      function handleTouchMove(evt) {
        var xUp ,yUp;
    
        if ( ! xDown || ! yDown ) {
          return;
        }
    
        if ('touchmove' === evt.type) {
          xUp = evt.touches[0].clientX;
          yUp = evt.touches[0].clientY;
        } else {
          xUp = evt.clientX;
          yUp = evt.clientY;
        }
    
        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;
    
        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
          if ( xDiff > 0 ) {
            // ledt
            switcher(Math.min(currentIndex + 1, _listLen - 1));
          } else {
            // right
            switcher(Math.max(currentIndex - 1, 0));
          }
        } 
    
        /* reset values */
        xDown = null;
        yDown = null;
      }
    }


    render() {
      return (
  
        <main className="main-content is-mini">
            <div className="lists-wrap">
            <ul className="lists">
                {this.props.children}
            </ul>
            </div>
        </main>

      );
    }
  }    

  export class ListItm extends React.Component{
    constructor(props) {
      super(props)
      this.state = {
          userids : [] // sel user id  
        };
        this.updateIds = this.updateIds.bind(this)
      }
      
    updatecaption(){
      //tcapt ('USER EDITOR');v
      //AppCaption('WEB EDITOR');
      //myUsers.callUser();
    }
  
    useropenid(){
      //console.log('work');
    }
  
    updateIds = (ids) => {
      //MW1info(ids); // show modal
    }
  
    componentDidMount() {

    }


    render() {
      return (
        <li className="list">
          <h3 className="list-header"><i className="fa fa-ellipsis-v"></i>{this.props.name}<i className="primary-plus">+</i></h3>
          <div className="list-items">
            {this.props.children}
          </div>
        </li>
        );
    }
  }    

  export class ListElement extends React.Component{
    constructor(props) {
      super(props)
      this.state = {
          userids : [] // sel user id  
        };
        this.updateIds = this.updateIds.bind(this)
      }
      
    updatecaption(){
      //tcapt ('USER EDITOR');v
      //AppCaption('WEB EDITOR');
      //myUsers.callUser();
    }
  
    useropenid(){
      //console.log('work');
    }
  
    updateIds = (ids) => {
      //MW1info(ids); // show modal
    }
  
    componentDidMount() {

    }

    addtxt(){
        var add = '';
        if (this.props.exmpl === '1') { add = <span className="list-tag list-tag--1"></span> }
        if (this.props.exmpl === '2') { add = <span className="list-tag list-tag--2"></span> }
        if (this.props.exmpl === '3') { add = <span className="list-tag list-tag--3"></span> }
        if (this.props.exmpl === '4') { add = <span className="list-tag list-tag--4"></span> }
        return add;
    }

    render() {
      return (
        <div className="list-item">
            <div className="list-tags">
                {this.addtxt()}
            </div>
            <img src="http://placekitten.com/g/50/50" className="list-avatar" />
        </div>
        );
    }
  }    