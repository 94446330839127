import './../style.css';
import '../../../css/widgsystem.css';

import React from "react";
//import {UIlengID} from '../../../App'; // Потрібно файл де буде завантажуватися всі переміні
import { myUsers, miUser, LoadAvatarHis, LogoCompany ,SetHistory} from "../../../redux/state";
import { Link, useNavigate } from "react-router-dom";

//import GostAva from '../../../img/guest_nano.png';  //sadmin.png

export default class IndexUserHome extends React.Component{
    constructor() {
      super()
      this.state = {
          userids : [], // sel user id  7
        };
      }
      

    componentDidMount() {
      // AppCaption('EMPLEADOS','MI EMPRESA','micompany','empleados');
      myUsers.callUser();
    }
  
    render() {
      const { work } = this.props;

      return (
        <div className = "selcompany-info" >
              
              <div className="aboutempresa">
                <p className="txtEmpresa">{work.cname}</p>
                {LogoCompany(work, "logo", null ,'m')}
                <p className="txtEmpresaId"> Company ID {work.id}</p>
                <p className="txtEmpresaId"> Clientes {work.id}</p>
                <p className="txtEmpresaId"> Trabajadores {work.id}</p>
                <p className="txtEmpresaId"> Clientes {work.id}</p>

                <div className='g1'>
                    <div className="btn_modal_tool"><div className="fa fa-address-card"  aria-hidden="true">&nbsp;</div></div>
                    <div className="btn_modal_tool"><div className="fa fa-gavel"  aria-hidden="true">&nbsp;</div></div>
                    <div className="btn_modal_tool"><div className="fa fa-area-chart"  aria-hidden="true">&nbsp;</div></div>
                </div>
              </div>
  
          <div className="mycompbutcontl"> 
            <samp className="caption">Office</samp>
            <MenuBTN url = "/micompany/empleados" icon = 'fa fa-user' caption = 'Empleados'/>
            <MenuBTN url = "/micompany/eqipos" icon = 'fa fa-users' caption = 'Eqipos'/>
            <MenuBTN url = "/micompany/lugares" icon = 'fa fa-map' caption = 'Lugares'/>
            <MenuBTN url = "/micompany/suncontract" icon = 'fa fa-life-ring' caption = 'Subcontractos'/>
            <MenuBTN url = "/micompany/clients" icon = 'fa fa-user-o' caption = 'Clientes'/>
            <MenuBTN url = "/micompany/jornada" icon = 'fa fa-clock-o' caption = 'Jornada'/>
            <MenuBTN url = "/micompany/documentos" icon = 'fa fa-archive' caption = 'Documentos'/>
            <MenuBTN url = "/micompany/mensaje" icon = 'fa fa-envelope' caption = 'Mensajes'/>
          </div> 

          <div className="mycompbutcontl"> 
            <samp className="caption">Tools</samp>
            <MenuBTN url = "/micompany/" icon = 'fa fa-wrench' caption = 'HERRAMIENTAS'/>
            <MenuBTN url = "/micompany/" icon = 'fa fa-comments-o' caption = 'CHAT'/>
            <MenuBTN url = "/micompany/" icon = 'fa fa-internet-explorer' caption = 'WEB PAGE'/>
            <MenuBTN url = "/micompany/" icon = 'fa fa-braille' caption = 'CITAS'/>
            <MenuBTN url = "/micompany/" icon = 'fa fa-sitemap' caption = 'ALMACEN'/>
            <MenuBTN url = "/micompany/" icon = 'fa fa-list' caption = 'presupuestos'/>
            <MenuBTN url = "/micompany/" icon = 'fa fa-file-text-o' caption = 'To do list'/>
            <MenuBTN url = "/micompany/" icon = 'fa fa-cart-arrow-down' caption = 'Pedidos'/>
          </div> 

        </div>

        
      );
    }
  }    
  
  function MenuBTN(props) {
    const navigate = useNavigate();

    // props.URL
    // props.ICON
    // props.CAPTION

    const GoURL = (event) => {
      event.preventDefault();
      navigate(props.url);
    }

    return (
      <button className="btnboxcard" onClick={GoURL}> 
        <i className={props.icon + " btn_icon"} aria-hidden="true"></i>
        <samp className="btn_label">{props.caption}</samp>
      </button>            
    )
  }