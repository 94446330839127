import './App.css';
import React , { useState, useEffect }  from 'react';
import IndexGost from "./components/Pages/IndexGost";
import IndexUser from "./components/Pages/IndexUser";
import Cookies from 'universal-cookie';
import { SetHistory } from "./redux/state";
import { incrementPalabras , incrementUser } from './redux/action'
//import { useDispatch, connect } from 'react-redux'
import LoadingUI from './components/Pages/loadingui';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useSelector, useDispatch } from 'react-redux';
import { getUser } from './app/slice/user';
import { GetWork } from './app/slice/company';
import { GetTXTUI } from './app/slice/txtui';

export const UIlengID  = [];

function App() {
  const dispatch = useDispatch();
  const cookies = new Cookies();

  const lng = cookies.get('lng_ui');
  const {txt, loadingT} = useSelector((state)=> state.userui);
  const {work, loadingW} = useSelector((state)=> state.works);
  const {user, loadingL} = useSelector((state)=> state.users);

  console.log("lng", lng);

   console.log("txtUI",loadingT, txt);
   console.log("users",loadingL, user);
   console.log("works",loadingW, work);

  const [routeResult,openUrl] = useState(<LoadingUI tcapt = {txt.id21} />);

  let OnlineUser = null;
 
  useEffect(() => {   
    // Завантаження мовного пакету
    dispatch(GetTXTUI(cookies.get('lng_ui'))); 
  }, [dispatch]); 

  useEffect(() => {   
    // Завантаження даних користувача
    dispatch(getUser()); 
  }, [dispatch]); 

  useEffect(() => {   
    // Завантаження даних компанії
    dispatch(GetWork())
    //console.log("APP users.work", users.work);
  }, [dispatch]); 

  useEffect(() => {
      authauto(); 
      //openUrl (<IndexUser logout = {authauto} lng={lng} palabras={txt} user={users} corp= {works}/>);
  }, [user,txt,work]); 

  
  function authauto () {
    if (!cookies.get('token')){
      if (!OnlineUser) {SetHistory('5');}
      if (loadingT==true) {
        console.log("authauto___ghost",loadingT);
        openUrl (<IndexGost userexit = {authauto} lng={lng} palabras={txt}/>);
      }else {
        console.log("FAIL GHOST LOAD : ",loadingW,loadingL);
       };
    } else {
      if (!OnlineUser ) {SetHistory('0');}
      //openUrl (<IndexGost userexit = {authauto} lng={lng} palabras={txt}/>);
      if (loadingL==true & loadingW==true) {
        console.log("authauto___works",loadingW,loadingL);
        openUrl (<IndexUser logout = {authauto} lng={lng} palabras={txt} user={user} corp= {work[0]}/>)
      } else {
        console.log("FAIL USER LOAD : ",loadingW,loadingL);
       };
    }
    OnlineUser = 1;
  }

  //console.log(user)

  return (
      <div>
           {routeResult} 

          <ToastContainer
            position="bottom-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

      </div>
  );
}


export default App
