// import { async } from '@firebase/util';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';

export const getUser = createAsyncThunk("user/GetUser", async () => {
  const cookies = new Cookies();

  return await fetch("https://inupline.com/post.php", {
    method: 'POST',
    header : {
        Accept: 'application/json', 'Content-Type': 'application/json',
    },
    credentials: "include", // This here
    body : JSON.stringify({
        "userlogin": cookies.get('token')
    })
    }).then((res)=> res.json());
});


const LoginUser = createSlice ({
    name: 'user',
    initialState : { 
      user: [],
      loadingL: false
    },
    reducers: {},
    extraReducers: (builder) => { 
      builder
      .addCase (getUser.pending, (state) => { state.loadingL = false; })
      .addCase (getUser.fulfilled, (state, { payload }) => { state.loadingL = true; state.user = payload})
      .addCase (getUser.rejected, (state) => { state.loadingL = false })
      },
    }
  );

  //export const {} = loginUser.actions;

  //export const CS_actions = counterSlice.actions;
  export default LoginUser;
