import './style.css';
import React, { useEffect } from "react";
//import { GetLeng } from "../../redux/state";
import { Link, useNavigate } from "react-router-dom";
import SliderCard from './widgets/slider';
import { myUsers, miUser,AppCaption} from "../../redux/state";

function DashPage(props) {


  useEffect(() => {
    //AppCaption('','HOME','','')

    //console.log('Did', props.did)

    // const span = document.querySelector("span");

    // span.onclick = function() {
    //   document.execCommand("copy");
    // }

    // span.addEventListener("copy", function(event) {
    //   event.preventDefault();
    //   if (event.clipboardData) {
    //     event.clipboardData.setData("text/plain", span.textContent);
    //     console.log(event.clipboardData.getData("text"))
    //   }
    // });

}, [props.did]); 


  function pageclick(eva) {
    //opage(eva.target.id);
  }

  function UserBTN(props) {
  
    return (    
      <button className="btnboxcard" id={props.id} onClick={(e) => { pageclick(e);}}> 
        <i className={"fa " + props.ico +" btn_icon"} aria-hidden="true"></i>
        <samp className="btn_label">{props.capt}</samp>
      </button>
    );
}

  return (    
    <div>
      
      <div>
        <SliderCard />
      </div>

      <div className="appcard">

        {/* <Link as={Link} to="/job"> 
          <UserBTN id = "md0" ico = "fa-user-o" capt = "CV"></UserBTN>
        </Link> */}
        <samp className="UserMainCaption">Tus herramientas:</samp>

        <Link as={Link} to="/companys"> 
          <UserBTN id = "md2" ico = "fa-copyright" capt = "COMPAÑÍAS"></UserBTN>
        </Link>

        <Link as={Link} to="/jornada"> 
          <UserBTN id = "menub3" ico = "fa-clock-o" capt = "JORNADA"></UserBTN>
        </Link>

        <Link as={Link} to="/todolist"> 
          <UserBTN id = "menub3" ico = "fa-clock-o" capt = "JORNADA"></UserBTN>
        </Link>

        {/* <Link as={Link} to="/partes"> 
          <UserBTN id = "md3" ico = "fa-calendar-check-o" capt = "Partes"></UserBTN>
        </Link> */}

        {/* 
        <Link as={Link} to="/jornada"> 
          <UserBTN id = "md1" ico = "fa-sticky-note-o" capt = "NOTE"></UserBTN>
        </Link>
        */} 

        <samp className="UserMainCaption">{props.did}</samp>

        
      </div> 
    </div>
  );
}



export default DashPage;


