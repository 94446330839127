import { Component } from "react";
import TodoTST from "./todotest";
import ListTodo from "./todotest2";

// import check from './check2.png'
import { myUsers, LoadAvatarHis, AppCaption ,SetHistory} from "../../redux/state";

const DATAtodolist = [
    { id: '0' , userid: '35', title: 'Comprar Bananas' , date: 'Water', time: 'Water', oktime: 'Water', okdate: 'Water', tikimport: 'true', checked: '' },
    { id: '1' , userid: '129', title: 'Comprar Aqua' , date: 'Water', time: 'Water', oktime: 'Water', okdate: 'Water', tikimport: 'true', checked: '' },
    { id: '2' , userid: '88', title: 'Comprar Pan' , date: 'Water', time: 'Water', oktime: 'Water', okdate: 'Water', tikimport: 'true', checked: 'true' },
    { id: '3' , userid: '88', title: 'Comprar UVA' , date: 'Water', time: 'Water', oktime: 'Water', okdate: 'Water', tikimport: 'true', checked: '' },
    { id: '4' , userid: '88', title: 'Mandar email' , date: 'Water', time: 'Water', oktime: 'Water', okdate: 'Water', tikimport: 'true', checked: '' },
    { id: '5' , userid: '88', title: 'Sacar basura' , date: 'Water', time: 'Water', oktime: 'Water', okdate: 'Water', tikimport: 'true', checked: 'true' },
    { id: '6' , userid: '88', title: 'Revisar coche' , date: 'Water', time: 'Water', oktime: 'Water', okdate: 'Water', tikimport: 'true', checked: 'true' }
];

export default class TodoList extends Component {
    state = {
        userInput: '',
        glList: DATAtodolist
    }

    OnChange(e) {
        /*
        console.clear()
        console.log (e.target.value)

        const car1 = {name:"Audi", model:"A4"}
        const car2 = {name:"Volvo", model:"XC90"}
        const car3 = {name:"Ford", model:"Fusion"}

        console.table([car1, car2, car3], ["model"]);
        console.table([car1, car2, car3]);*/
        this.setState({userInput:e})
        //console.log (this.state.userInput)
    }

    additem(input) {
        if (input === '') {
            console.log ('error input null')
        } else {
            let listArray = this.state.glList;
            //console.log ('myUsers.id', this.props.user.userid)
            listArray.push({ id: '7' , userid: this.props.user.userid, title: input , date: 'Water', time: 'Water', oktime: 'Water', okdate: 'Water', tikimport: 'true', checked: 'true' })
            this.setState({glList:listArray, userInput: ''})
            console.log (this.state.glList)
        }
    }

    delall() {
        let listArray = this.state.glList;
        listArray = [];
        this.setState({glList:listArray})
        console.log ('del all')
    }

    delindex(index) {
        let listArray = this.state.glList ;
        //var index = listArray.indexOf(index);

            listArray.splice(index,1);
            this.setState({glList: listArray});
            console.log ('del index' , index)
            console.log (this.state.glList)


        //this.setState({glList:listArray})
    }
    
    croosW(e){
        const li = e.target;
        li.classList.toggle('croos');
    }

    frmSubmit(e){
        e.preventDefault();
    }

    componentDidMount() {
       
        
    }

    render() {
        var result = this.state.glList.length;
        return (

            <div className="appcard">
            
                <div className = "bcard">
                    <p>Lista de tareas:</p>  
                </div>

                <div className = "bcard">
                    <form onSubmit={this.frmSubmit}>

                        <div className="container">
                            <input className="findsuser" type="text" placeholder="What to you want to buy today" onChange={(e) => {this.OnChange(e.target.value)}} value={this.state.userInput}/>
                            <button className="btnboxmenu sulite" onClick={()=> this.additem(this.state.userInput)} > ADD </button>
                        </div>

                        <div id="gridhistory" className="gridhistory">

                        <ListTodo colors={DATAtodolist}/>


                           
                        </div>
                        <div className="container">
                            <button className="btnboxmenu" onClick={()=> this.delall()}>CLEAR ALL </button>
                        </div>

                    </form>
                    <p className="tablinfo">All: {result} </p>


                </div>

        </div>
        )

    }
}

