import { Link, useNavigate } from "react-router-dom";
import GhMenu from './item'

import React, {useState}  from 'react';
import LngMenu from "../LngList/LngBtm";
import LogoBtm from '../logo_btm';
import { incrementLngui , incrementPalabras} from '../../redux/action'
import { useDispatch } from 'react-redux'
import { GetTXTUI } from '../../app/slice/txtui';

function XMenu(props) {

    const dispatch = useDispatch();
    const [m1, setM1] = useState(true);
    const [m2, setM2] = useState(false);
    const [m3, setM3] = useState(false);
    const [m4, setM4] = useState(false);
    //const select =   [ "select", "", "", "" ];

    //const navigate = useNavigate();
   
     const handleLng = (id) => {
        dispatch(GetTXTUI(id)); 
      };

      const selpage = (id) => {
        {setM1(false)}
        {setM2(false)}
        {setM3(false)}
        {setM4(false)}

        if (id == 0 ){setM1(!m1)}
        if (id == 1 ){setM2(!m2)}
        if (id == 2 ){setM3(!m3)}
        if (id == 3 ){setM4(!m4)}
      };

    return (

        <nav>
       
       <div className="kmenu">
          <Link as={Link} to="/" className="link"><span className='mxicon'><i className='fa fa-home' aria-hidden="true"></i></span></Link> 
          <Link as={Link} to="/login" className="link"><span className='mxicon'><i className='fa fa-sign-in' aria-hidden="true"></i></span></Link> 
          <Link as={Link} to="/about" className="link"><span className='mxicon'><i className='fa fa-info-circle' aria-hidden="true"></i></span></Link> 
          <Link as={Link} to="/contact" className="link"><span className='mxicon'><i className='fa fa-map-marker' aria-hidden="true"></i></span></Link> 
      </div>

       <div className="menu">
            <div className="website_name"> <LogoBtm /> </div>
            <div className="menu_links">
                <Link as={Link} to="/" className="link"><span className={`mxtext ${m1 ? 'mxtext select' :'mxtext'}`} onClick={() => {selpage(0)}}>{props.pal.id1}</span></Link> 
                <Link as={Link} to="/login" className="link"><span className={`mxtext ${m2 ? 'mxtext select' :'mxtext'}`} onClick={() => {selpage(1)}}>{props.pal.id3}</span></Link> 
                <Link as={Link} to="/about" className="link"><span className={`mxtext ${m3 ? 'mxtext select' :'mxtext'}`} onClick={() => {selpage(2)}}>{props.pal.id4}</span></Link> 
                <Link as={Link} to="/contact" className="link"><span className={`mxtext ${m4 ? 'mxtext select' :'mxtext'}`} onClick={() => {selpage(3)}}>{props.pal.id5}</span></Link> 
            </div>

            {/* <LngMenu handleLng = {handleLng} lng = {props.lng }/> */}
            <LngMenu handleLng = {handleLng} lng = {!props.lng ? "gb" : props.lng }/>

        </div>
    </nav>
    );
  }

export default XMenu;