import './../style.css';
import '../../../css/widgsystem.css';

import React from "react";
import { myUsers, miUser, LoadAvatarHis, LoadAvatar ,SetHistory} from "../../../redux/state";

// WIDGET SYSTEMS
import WdgCalendar from '../widgets/calendar';
import WdgSemana from '../widgets/semana';
import WdgBox from '../widgets/wgbox';
import CntrSelect from '../widgets/select';
import CntrTextbox from '../widgets/textbox';

export default class IndexUserHome extends React.Component{
    constructor() {
      super()
      this.state = {
          user : [], // sel user id  7
        };
      }
      
    componentDidMount() {
        this.verifitycard()
    }

    verifitycard() {
        const { guser } = this.props;
        var i = 1;
        if (guser) {
            this.setState({user: guser })
        }
    }

    render() {
      const { user } = this.state;
      const { userid } = this.props;

      return (
        <div className = "selcompany-info" >
            CHAT_box
        </div>
      );
    }
  }    
