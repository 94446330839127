import { Component } from "react";
import { myUsers, LoadAvatarHis, AppCaption ,SetHistory} from "../../redux/state";

var placeholder = document.createElement("li");
placeholder.className = "placeholder";

export default  class ListTodo extends Component {
  constructor(props) {
    super(props);
    this.state = {...props};
  }

  dragStart(e) {
    this.dragged = e.currentTarget;
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', this.dragged);
  }

  dragEnd(e) {
    this.dragged.style.display = 'block';
    this.dragged.parentNode.removeChild(placeholder);

    // update state
    var data = this.state.colors;
    var from = Number(this.dragged.dataset.id);
    var to = Number(this.over.dataset.id);
    if(from < to) to--;
    data.splice(to, 0, data.splice(from, 1)[0]);
    this.setState({colors: data});
  }

  dragOver(e) {
    e.preventDefault();
    this.dragged.style.display = "none";
    if(e.target.className === 'placeholder') return;
        this.over = e.target;
        console.log(this.over);
        console.log(this.dragged);
        
        this.dragged.parentNode.insertBefore(placeholder, e.target);

       // e.target.parentNode.insertBefore(placeholder, e.target);
        //console.log(e.target.parentNode.insertBefore(placeholder, e.target));
    } 
    
    // pointer-events: none;
    render() {
    var listItems = this.state.colors.map((item, i) => {
      return (
        <li 
          className="listelement"
          data-id={i}
          id={item.id}
          key={i}
          draggable='true'
          onDragEnd={this.dragEnd.bind(this)}
          onDragStart={this.dragStart.bind(this)}>
            {/* {i + " " + item.title} */}
                <figure className="userlist" id="data-idc20421" data-idc="20421">
                    <input className="checkboxs" id={"check" + item.id} defaultChecked={item.checked} name="checkbox20421" type="checkbox"/>
                    <span className="linenumero">{i+1}</span>
                    <span className="lisdate"><i className="fa fa-clock-o" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;{item.htime}&nbsp;/&nbsp;{item.hdate}</span>
                    <span className="lpais"><span></span> {item.user_city} ({item.user_pais})</span>
                    <span className="lineip"><i className="fa fa-globe" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;{item.user_ip}</span>
                    {LoadAvatarHis(item.userid, "pimg", null ,'m', 'User')}
                    <figcaption>
                        <span className="het">{item.title} </span>
                        <p className="hez">
                        </p>
                    </figcaption>
                </figure>
                <button className="btnboxmenu sulite" onClick={()=> this.delindex(i)}>X</button>

          </li>
      )
     });
        return (
            <ul id="grid1" className="setlisttodo" onDragOver={this.dragOver.bind(this)}>
                {listItems}
            </ul>
        )
    }
}