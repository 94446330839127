import React, { useRef, useState, useEffect } from "react";
import { LoadAvatar } from "../../redux/state";
import './SuperListBox.css';

const BestList = (props) => {
    const ASC = 'ascending';
    const DSC = 'descending';
    // the value of the search field 
    const [name, setName] = useState('');
    const [listview, setListView] = useState(0);

    // the search result
    const [foundUsers, setFoundUsers] = useState(props.value);
    //const [USERS, setUsers] = useState();
    const [sortFName, setSortFName] = useState(DSC);
    const [sortNumber, setSortNumber] = useState(DSC);

    function sortByNum(a, b, order = sortFName) {
        const diff = a.num - b.num;
    
        if (order === ASC) {
            return diff;
        }
    
        return -1 * diff;
    }

    function sortByText(a, b, order = sortFName) {
        const diff = a.fname.toLowerCase().localeCompare(b.fname.toLowerCase());
        //console.log(a);

        if (order === ASC) {
            return diff;
        }
    
        return -1 * diff;
    }

    const filter = (e) => {
        //const keyword = e.target.value;
        var keyword = document.getElementById('findtext').value;

    
        if (keyword !== '') {
          const results = props.value.filter((user) => {
            return user.fname.toLowerCase().startsWith(keyword.toLowerCase());
            // Use the toLowerCase() method to make it case-insensitive
          });
        
          const sorted = results.sort((a, b) => sortByText(a, b, sortFName));

          setFoundUsers(sorted);

          //console.log(arr.sort((a, b) => sortByNum(a, b, DSC)))
          //console.log(foundUsers.sort((a, b) => sortByText(a, b, DSC)))

          
        } else {
          setFoundUsers(props.value.sort((a, b) => sortByText(a, b, sortFName)));
          // If the text field is empty, show all users
        }
    
        setName(keyword);
      };

    //   const sorted = foundUsers.sort(sortByIdThenName);
    //   setFoundUsers(sorted)
    //this.props.search
    
    const filterTXT = () => {
        sortFName==ASC ? setSortFName(DSC) : setSortFName(ASC);
        filter();
    }

    const filterNUM = () => {
        sortNumber==ASC ? setSortNumber(DSC) : setSortNumber(ASC);
        //filter();
    }

    const seluser = (ids) => {
        //console.log(ids);
        props.updateIds(ids);
      }

    useEffect(() => {
        // findtext();
      }, [])

    return (
        <div>
            <div className = "btntools">
                <button className="btnboxmenu sulite" id="sum1"> <i className="fa fa-plus"></i></button>
                <input className="findsuser" value={name} id = "findtext" type="search" name="userName" placeholder="Enter your name..." onChange={filter}/>
                {/* <button className="btnboxmenu sulite" onClick={() => {this.FindText()}} id="bfind"> <i className="fa fa-search"></i></button> */}
                {/* <button className="btnboxmenu sulite" id="sum1" onClick={() => this.showmodal(true)}> <i className="fa fa-address-card"></i></button> */}
                <button className="btnboxmenu sulite" id="list" onClick={() => setListView(0)}> <i className="fa fa-th-list"></i></button>
                <button className="btnboxmenu sulite" id="block" onClick={() => setListView(1)}> <i className="fa fa-th-large"></i></button>
                <button className="btnboxmenu sulite" id="block" onClick={() => setListView(2)}> <i className="fa fa-list"></i></button>

                <button className="btnboxmenu sulite" id="block" onClick={() => filterNUM()}> { sortNumber==ASC ? <i className="fa fa-sort-numeric-desc"></i> : <i className="fa fa-sort-numeric-asc"></i>}</button>
                <button className="btnboxmenu sulite" id="block" onClick={() => filterTXT()}> { sortFName==ASC ? <i className="fa fa-sort-alpha-desc"></i> : <i className="fa fa-sort-alpha-asc"></i>}</button>
                {/* <i class="fa fa-sort-alpha-desc" aria-hidden="true"></i> */}

            </div>

            {/* <input
                type="search"
                value={name}
                onChange={filter}
                className="input"
                placeholder="Filter"
            /> */}

            <section className = "list_cards">

            {foundUsers && foundUsers.length > 0 ? (
                listview == 0 && ( 
                    <section className = "listbody">
                        {foundUsers.map(item => (
                            <div className = "listitm" key={item.id} id = {item.id}>
                                <StatusSadminIco id = {item} />
                                {LoadAvatar(item, "saava", null ,'m')}
                                <p className = "textid">{item.id}</p>
                                <p className = "textnm">{item.fname + ' ' + item.lname} </p>
                                <p className = "textml">{item.email}</p>
                                <div className="butbox">
                                <div>
                                    <button className="key_setings downicol"><i className="fa fa-comments-o">&nbsp;</i></button>
                                    <button className="key_setings downicol"><i className="fa fa-ban">&nbsp;</i></button>
                                    <button className="key_setings downicol" onClick={() => seluser(item)}><i className="fa fa-pencil-square-o">&nbsp;</i></button>
                                </div>
                                </div>
                            </div>
                        ))} 
                    </section> 
                )
            ) : (
                    <h1>No results found!</h1>
            )}

            {(listview == 1) && foundUsers.map(item => (
                  <div className="card" href="" key={item.id} id = {item.id}>
                    <div className="card__background">{LoadAvatar(item, "saava", null ,'m')}</div>
                    <div className="card__shadow"> </div>
  
                    <span className="label-id"> ID: {item.id} / ROLE: {item.role}</span>
                    <span className="label-new"> <StatusSadminFR id = {item} /></span>
                    {/* <span className="label-admin"> <StatusSadminFR id = {item} /> </span>  */}
                    <span className="label-admin2"> <StatusSadmintxt id = {item} txt_role = "root"  /> </span> 
                    <span className="label-role"> <StatusSadminIco id = {item} /> </span> 
  
                    <div className="card__info">
                      {LoadAvatar(item, "ava_user_center", null ,'m')}
                      <div className="card__name">{item.fname + ' ' + item.lname} </div>
                      <div className="card__about"><span className="age"> {item.email} </span> <span className="country">  </span></div>
                      {/* <div className="card__description">{item.email}</div> */}
                    </div>
                <div className="card__follow" onClick={() => seluser(item)}><span>INFO</span></div>
            </div>))}

            {(listview == 2) && ( 
                <div className="table-users">
                <table cellSpacing="0">
                <tbody>
                            <tr className="header">
                                <th>Picture</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th width="230">Comments</th>
                            </tr> 
                            {foundUsers.map(item => (
                            <tr key={item.id} id = {item.id}>
                                <th>{LoadAvatar(item, "saavamini", null ,'m')}</th>
                                <th>{item.fname + ' ' + item.lname}</th>
                                <th>{item.email}</th>
                                <th>{item.work}</th>
                                <th>{item.nacionalidad}</th>
                            </tr>
                ))}</tbody></table></div>
            )}
            </section>
            
        </div>
    )
}


  export default BestList;

      //#################################################################################

function StatusSadminIco({id}) {
    if (id.role === "1") {
      return <i className="fa fa-meetup"></i>;
    } else if (id.role === "3") {
      return <i className="fa fa-grav"></i>;
    } else {
      return "";
    }
  }
  
  function StatusSadmintxt(props) {
    if (props.id.role === "3") {
      return props.txt_role;
    } else {
      return "";
    }
  }
  
  function StatusSadminFR(props) {
    if (props.id.first_run === "1") {
      return "new";
    } else {
      return "";
    }
  }