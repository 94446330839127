import jquery from "jquery";
import './slider.css';
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

//import useScript from 'react-script-hook';
import imgFindJob from '../../../img/findjob.jpg';  //sadmin.png
import imgWolcomEmpleo from '../../../img/welcomempleo.jpg';  //sadmin.png
import imgWolcomLogo from '../../../img/welcomempleo2.jpg';  //sadmin.png


export default function SliderCard(props) {
    
    useEffect(() => {
        
        // var swiper = Swiper('.swiper-container', { slidesPerView: 1,
        //     spaceBetween: 20,
        //     effect: 'fade',
        //     loop: true,
        //     speed: 300,
        //     mousewheel: {
        //       invert: false,
        //     },
        //     pagination: {
        //       el: '.swiper-pagination',
        //       clickable: true,
        //       dynamicBullets: true
        //     },
        //     // Navigation arrows
        //     navigation: {
        //       nextEl: '.swiper-button-next',
        //       prevEl: '.swiper-button-prev', 
        //     }
        //   });
    }, []); 

    return (
        <div className="cardsl">
        <input type="radio" name="select" id="slide_1" defaultChecked={true}/>
        <input type="radio" name="select" id="slide_2"/>
        <input type="radio" name="select" id="slide_3"/>
        <input type="checkbox" id="slideImg"/>
      
        
      
        <div className="inner_part">
          <label htmlFor="slideImg" className="img">
            <img className="img_1" src={imgWolcomLogo}/>
          </label>
          <div className="cardslidercontent content_1">
            <div className="cstitle">WELCOM</div>
            <div className="textcs">
                Bienvenido en <b>InUpLine</b>. Tu futuro es tuyo. Comparte su tiempo con personas que te necesita...
            </div>
            
          </div>
        </div>
      
        <div className="inner_part">
          <label htmlFor="slideImg" className="img">
            <img className="img_2" src={imgFindJob}/>
          </label>
          <div className="cardslidercontent content_2">
            <div className="cstitle">Conectar a compañía</div>
            <div className="textcs">
                Empieza Ya, busca tu compania en la lista ...
              </div>
            <Link as={Link} to="/companys"> 
                {/* <UserBTN id = "md2" ico = "fa-copyright" capt = "COMPAÑÍAS"></UserBTN> */}
                <button className="btnboxmenu blite">COMPAÑÍAS</button>
            </Link>
          </div>
        </div>
      
        <div className="inner_part">
          <label htmlFor="slideImg" className="img">
            <img className="img_3" src={imgWolcomEmpleo}/>
          </label>
          <div className="cardslidercontent content_3">
            <div className="cstitle">AUTONOMO</div>
            <div className="textcs">tienes ideas, talento o algo, aquí te encuentres herramientas para multiplicar o que te faltaba 
            </div>
            <Link as={Link} to="/myperfil"> 
                {/* <UserBTN id = "md2" ico = "fa-copyright" capt = "COMPAÑÍAS"></UserBTN> */}
                <button className="btnboxmenu blite">Empezar</button>
            </Link>
          </div>
        </div>

        <div className="cardslider">
          <label htmlFor="slide_1" className="slide slide_1"></label>
          <label htmlFor="slide_2" className="slide slide_2"></label>
          <label htmlFor="slide_3" className="slide slide_3"></label>
        </div>
      </div>
   )
  }
