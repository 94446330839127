import React, { useRef, useState, useEffect } from "react";
import './modals.css';
import '../../App.css'; // GLOBAL

import { notifyok, notifyerror } from "../../redux/state";

const quickAndDirtyStyleOn = {
    left: "100px",
    top: "100px",
    width: "800px",
    height: "800px",
    background: "#141414e1",
    color: "",
    display: "flex",
    position: "fixed",
    visibility: "visible",
    opacity:"1"
  }
  
  const quickAndDirtyStyleOff = {
    visibility: "hidden",
    opacity:"1",
    height: "0px",
    width: "0px"
  }
  
  const DraggableComponent = (props) => {
    const [step, setStep] = useState(18)
    const [pressed, setPressed] = useState(false)
    const [visibl, setVisibl] = useState(props.show)
    const [position, setPosition] = useState({x: 100, y: 100})
    const [sposition, setSposition] = useState({x: 100, y: 100})

    const ref = useRef()
  
    useEffect(() => {
      if (ref.current) {
        //ref.current.style.transform = `translate(${position.x}px, ${position.y}px)`
        ref.current.style.left = `${position.x}px`
        ref.current.style.top = `${position.y}px`
      }
    }, [position])
  
    useEffect( () => {
      setVisibl(props.show);
    })
  
    const onMouseDown = (event) => {

      setPressed(true)

      const element = document.getElementById("myModal_1");
      let pos1 =  element.offsetLeft - event.clientX ;   // X
      let pos2 =  element.offsetTop - event.clientY ;    // Y
      setSposition({x: pos1, y: pos2})
    }  

    // Update the current position if mouse is down
    const onMouseMove = (event) => {
      if (pressed) { 

        var mousePosition = {
          x :  event.clientX + sposition.x, //event.clientX,
          y :  event.clientY + sposition.y //event.clientY
        };

        let sx = parseInt( mousePosition.x / step) * step;
        let sy = parseInt( mousePosition.y / step) * step;

        setPosition({ x: sx, y: sy })
      }
    }
  
    const seluser = (ids) => {
        //console.log(ids);
        props.showmodal(ids);
    }

  
    return (
      <div
        ref={ ref }
        style={ !visibl ? quickAndDirtyStyleOff : quickAndDirtyStyleOn} id="myModal_1"
        >
  
          <div id="myModal" className="modal fade">
              <div className="modal-dialog">
                  <div className="modal-content">
                      <div className="modal-header" onMouseMove={ onMouseMove }
                                                    onMouseDown={ onMouseDown }
                                                    onMouseUp={ () => setPressed(false) }>
                        <button className="btnboxmenu sulite close" type="button" onClick={() => seluser(false)}>X</button>
                        <h4 className="modal-title">{props.caption}</h4>  
                      </div>
                      <div className="modal-body" onMouseMove={ () => setPressed(false) }>
                          {props.children}
                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn" onClick={() => seluser(false)}>Cancel</button>
                          <button id="loadpage" type="button" className="btn btn-primary" onClick={() => { SendFile(props.userid)}}>Save</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    )
  }

  export default DraggableComponent;




  function ModalInfo(props) {
    return (
      <div className="WM001hide" id="WM001">
        <div className="ModalWidg">
          <InfoLabel clabel = {props.titles} userid = {props.userid} />
          
          {props.children}
        </div>
  
        <div className="downmodal">
          <button className="btnboxmenu"  onClick={MW1info}>CLOSE</button>
        </div>
      </div>
    )
  }

    //#################################################################################

    const MW1info = (itm) => {
        const oldClassName = document.getElementById('WM001').className;
        const newClassName = oldClassName === 'WM001open' ? 'WM001hide' : 'WM001open'
        document.getElementById('WM001').className=newClassName;
      }
    
    function InfoLabel(props) {
      return (
        <div className="info">
          <h4 className="infotext"><i className="fa fa-user" aria-hidden="true"></i>&nbsp; {props.clabel}</h4>
    
          <div className="CtrlModalWidg">
            <button className="set_key"><i className="fa fa-trash" aria-hidden="true"></i></button>
            <button className="set_key" onClick={() => { SendFile(props.userid)}}><i className="fa fa-floppy-o" aria-hidden="true"></i></button>
            <button className="set_key" onClick={MW1info}><i className="fa fa fa-window-close" aria-hidden="true"></i></button>
        </div>
        
        </div>
      )
    }

    function SendFile(usrid,upimg) {

        var usrrole = document.getElementById('usrrole').value;
        var ui_leng = document.getElementById('ui_leng').value;
        var ui_mode = document.getElementById('ui_mode').value;
        var ui_tema = document.getElementById('ui_tema').value;
        var first_run = document.getElementById('first_run').value;
      
        var userfname = document.getElementById('userfname').value;
        var userlname = document.getElementById('userlname').value;
       
        var usernacionalidad = document.getElementById('usernacionalidad').value;
        var userbdate = document.getElementById('userbdate').value;
        var userrdad = document.getElementById('userrdad').value;
        var usernif = document.getElementById('usernif').value;
        var userphone = document.getElementById('userphone').value;
        var usercvabout = document.getElementById('usercvabout').value;
        var useremail = document.getElementById('useremail').value;
      
        var data = { 
          "cmd": 'UpdateTable',
          "user_id": usrid,
          "tbl": "users",
          'role': usrrole, 
          'ui_leng': ui_leng, 
          'ui_mode': ui_mode, 
          'ui_tema': ui_tema, 
          'first_run': first_run, 
          'fname': userfname, 
          'lname': userlname, 
          'nacionalidad': usernacionalidad, 
          'bdate': userbdate,
          'rdad': userrdad,
          'nif': usernif,
          'phone': userphone,
          'cv_about': usercvabout,
          'email': useremail
        };
      
        //console.log(data);
      
        var strss = JSON.stringify({
          "cmd": 'UpdateTable',
          "user_id": usrid,
          "tbl": "users",
          "foto": "3"})
      
        var strss1 = JSON.stringify(
          data)
          
        //console.log(strss);
        //console.log(strss1);
      
        //var ctel = document.getElementById('frmTel').value;
        //var ctel2 = document.getElementById('frmTel2').value;
        //var crch0 = document.getElementById('styled-checkbox-0').checked;
      
        
      fetch(
      'https://inupline.com/post.php',
      {
        method: 'POST',
        body: JSON.stringify(data)
      
      }
      )
        .then (response => {
            console.log(response);
            })
      
        .then((result) => {
            notifyok(' DATOS ACTUALIZADO !!!');
            console.log('Success:', result);
        })
        .catch((error) => {
            notifyerror();
            console.error('Error:', error);
        });
      
        //finish('finish');
      
      }